.text{

    font-family: var(--fontMain);
    font-size: var(--fontSize15);
    text-align: center;
}

.textInput {
    font-size: var(--fontSize14);
    font-family: var(--fontMain);
    font-weight: bold;
}

.householdmain {
    background: white;
    height: 100%;
    border-radius: 5px;
    border: 1px solid var(--neutral-300, #eff0f6);
    background: var(--neutral-100, #fff);
    box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
    display: flex;
  }

.dateFormateTxt {
    font-family: var(--fontMain);
    position: absolute;
    opacity: 0.8;
    color: "#858585";
    font-size: var(--fontSize11);
    bottom: 3px;
}

.dobTxt{
    font-size: var(--fontSize11);
    font-family: var(--fontMain);
    margin-right: 5px;
}

.titleTxt{
    font-size: var(--fontSize14);
    font-family: var(--fontMain);
    color: black;
    font-weight: 700;
}

.relationName{
    font-size: var(--fontSize12);
    font-family: var(--fontMain);
    color: black;
}