.error404-main {
  display: flex;
  flex-direction: column;
}

.error-bg-cover {
  display: flex;
}

.error-bg {
  width: 100%;
}

footer {
  background: white;
  display: flex;
  flex-direction: column;
  padding-top: 68px;
  align-items: center;
  height: 512px;
  position: relative;
  overflow: hidden;
}
footer > * {
  position: relative;
  z-index: 1;
}
footer > img {
  width: 372px;
  height: 101px;
  margin-bottom: 54px;
}

footer .footer-navigation {
  display: flex;
  list-style: none;
  color: var(--colorBlack2);
  margin-bottom: 40px;
}

footer .footer-navigation li {
  line-height: 21.82px;
  padding: 0 23px;
  cursor: pointer;
}
footer .footer-navigation li:nth-child(2) {
  border-left: 1px solid black;
  border-right: 1px solid black;
}

footer .kamunity-text {
  font-family: var(--fontSecond);
  font-size: var(--fontSize18);
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 8px;
}

footer p {
  font-family: var(--fontSecond);
  font-size: var(--fontSize18);
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 35px;
}
footer .logos {
  list-style: none;
  display: flex;
  gap: 25px;
}
footer .logos li {
  height: 32px;
  width: 32px;
  position: relative;
  cursor: pointer;
}

footer .logos li a img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
footer .logos li img {
  height: 32px;
  width: 32px;
}

footer::before {
  content: "";
  position: absolute;
  background: linear-gradient(
    180deg,
    var(--colorWhite) 51.56%,
    var(--color19) 51.57%
  );
  width: 255px;
  height: 258px;
  top: -135px;
  left: -100px;
  border-radius: 50%;
}

footer::after {
  content: "";
  position: absolute;
  background: var(--color20);
  width: 255px;
  height: 258px;
  bottom: -100px;
  right: -80px;
  border-radius: 50%;
}

@media screen and (max-width: 750px) {
  .header {
    background: white;
  }
  .error404-main {
    position: relative;
    top: -100px;
    display: flex;
    height: 100%;
  }
  .error-bg-cover {
    display: flex;
  }
  .error-bg-cover .error-bg {
    width: 100%;
  }
  .error-header {
    background: unset;
  }
  footer {
    min-height: 197px;
    flex-grow: 1;
    padding-top: 0px;
    justify-content: center;
  }
  footer > img {
    width: 132px;
    height: 35px;
    margin-bottom: 18px;
  }

  footer .footer-navigation {
    white-space: nowrap;
    line-height: 19px;
    font-size: var(--fontSize14);
    margin-bottom: 7px;
  }
  footer .footer-navigation li {
    padding: 3px 18px;
    line-height: 19px;
  }
  footer .kamunity-text {
    margin-bottom: 7px;
    font-size: var(--fontSize14);
    line-height: 19px;
  }
  footer p {
    font-size: var(--fontSize14);
    margin-bottom: 12px;
    line-height: 19px;
    text-align: center;
  }
  footer .logos {
    gap: 9.5px;
  }
  footer .logos li {
    height: 12px;
    width: 12px;
    display: flex;
  }
  footer .logos li img {
    height: 12px;
    width: 12px;
  }
  footer::before {
    background: linear-gradient(
      180deg,
      var(--colorWhite) 51.56%,
      var(--color19) 51.57%
    );
    width: 131px;
    height: 132px;
    left: -70px;
    top: -70px;
  }
  footer::after {
    width: 83px;
    height: 84px;
    bottom: -24px;
    right: -35px;
  }
}

@media screen and (max-width: 350px) {
  footer {
    padding-left: 15px;
    padding-right: 15px;
    height: 210px;
  }
}
