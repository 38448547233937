.trendsPage {
  min-height: calc(100vh);
  display: flex;
  justify-content: center;
  /* padding-bottom: 30px; */
}
.trendsPage .trendsBox {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 25px;
  background: white;
}
.trendsPage .head {
  display: flex;
  align-items: flex-end;
  gap: 15px;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px; /* 194.444% */
}
.trendsPage .head span{
  position: relative;
  top: 8px;
  white-space: nowrap;
}
.trendsPage .head .head-inside-col{
  display: flex;
  gap: 15px;
  align-items: center;
}
.trendsPage .head .submitBtn {
  width: 129px;
  height: 43px;
  flex-shrink: 0;
  border-radius: 5px;
  background: #21cae7;
  color: #000;
  text-align: center;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
}

.trendsPage .tabs {
  display: flex;
  list-style: none;
  overflow: auto;
}

.trendsPage .tabs .tab {
  min-width: 143px;
  border: 1px solid #ccc;
  list-style: none;
  display: grid;
  place-items: center;
  height: 40px;
  border-bottom: none;
  border-radius: 5px 5px 0px 0px;
  border-right: none;
  cursor: pointer;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0 12px;
}

.trendsPage .tabs .active {
  background: var(--color1);
  border: 1px solid var(--color1);
  border-bottom: none;
  border-right: none;
}

.trendsPage .tabs .tab:last-child {
  border-right: 1px solid #ccc;
}

.trendsPage .content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.trendsPage .graph-container {
  border: 1px solid #dcdcdc;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  flex-grow: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  position: relative;
}

.trendsPage .graph-box {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  padding: 20px;
  padding-bottom: 0;
  padding-right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 350px;
  max-height: 480px;
}

.trendsBox .graph-box .text1,
.trendsBox .graph-box .text2 {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.trendsBox .graph-box .text2 {
  font-weight: 400;
}

.trendsPage .graph-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  flex-grow: 1;
  gap: 35px;
}

.trendsPage .graph-wrapper .no-data{
  grid-column: 1 / span 2;
  margin: auto;
}

.trendsPage .trends-disclaimer {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Nunito;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
}

.trendsPage .state-cover{
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-top: 10px;
}
.trendsPage .state-cover label{
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
  top: -4px;
}

@media screen and (max-width: 750px){
  .trendsPage .graph-wrapper{
    grid-template-columns: 1fr;
  }
  .trendsPage .state-cover{
    margin: 0;
  }
  .trendsPage .graph-wrapper{
    flex-direction: column;
    gap: 0;
  }
  .trendsPage .head{
    flex-direction: column;
    align-items: start;
    padding: 0 15px;
  }
  .trendsPage .fields-title{
    padding: 0 15px;
  }
  .trendsPage .head input, .trendsPage .head .MuiInputBase-root{
    font-size: 14px !important;
  }
  .trendsPage .head .submitBtn{
    height: 33px;
    width: 80px;
    margin-top: 8px;
    font-size: 14px;
  }
  .trendsPage .head .MuiTextField-root{
    width: 25vw;
  }
  .trendsPage .tabs .tab{
    font-size: 14px;
    min-width: 120px;
  }
  .trendsPage .trendsBox{
    margin-top: 15px;
    padding: 10px 0;
    padding-bottom: 20px;
    box-shadow: none;
    width: 95%;
  }
  .trendsPage .graph-box .graph-title *{
    font-size: 14px !important;
  }
  .trendsPage .graph-container{
    padding-left: 0;
    padding-right: 0;
  }
  .trendsPage .graph-box{
    min-height: 250px;
    box-shadow: none;
    padding: 0;
    margin-left: 10px;
  }
  .trendsPage .trends-disclaimer{
    padding: 0 15px;
  }
}