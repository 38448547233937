* {
  font-family: var(--fontMain);
}
.login-main-wrapper {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 100px);
  background: url("../images/login-bg.webp");
  background-position: center;
  background-size: cover;
}

.error-custom {
  font-style: normal;
  font-weight: 400;
  font-size: var(--fontSize14);
  line-height: 150%;
  color: var(--color14);
  display: flex;
  align-items: center;
  margin-top: 0px;
  width: 100%;
}
.error-custom.login-error {
  margin-top: 10px;
}
.error-custom svg {
  margin-right: 10px;
}
.login-box {
  width: 659px;
  border-radius: 0px;
  background: var(--colorWhite);
  padding: 4vh 54px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
  position: relative;
}

.login-box .initial-text h1 {
  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize30);
  line-height: 183.2%;
  color: var(--colorBlack);
  text-align: center;
}
.login-box .initial-text p {
  font-style: normal;
  font-weight: 400;
  font-size: var(--fontSize16);
  line-height: 150%;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

.login-box .field-auth {
  width: 100%;
}

.login-box .forget-password-link {
  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize14);
  line-height: 183.2%;
  text-align: right;
  color: var(--color1);
  align-self: flex-end;
  margin-top: 29px;
  margin-bottom: 29px;
  text-decoration: none;
}

.login-box .forget-password-link-wrapper {
  margin-top: 1vh;
  margin-bottom: 1vh;
  text-align: right;
}

.login-box .button-auth-main {
  height: 65px;
  width: 100%;
  background: var(--color1);
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize16);
  line-height: 22px;
  /* identical to box height */

  text-align: center;

  color: var(--colorBlack);
}

.login-box .or-login {
  display: flex;
  align-items: center;
  margin: 0rem auto;
  margin-top: 2vh;
  width: 85%;
}
.login-box .or-login span {
  background-color: var(--color15);
  height: 1px;
  flex: 1;
}
.login-box .or-login p {
  font-style: normal;
  font-weight: 500;
  font-size: var(--fontSize14);
  line-height: 183.2%;
  text-align: center;
  color: var(--color15);
  margin: 0rem 10px;
}

.login-box .social-media-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 35px;
  width: 85%;
  margin: 0rem auto;
  margin-top: 2vh;
}

.login-box .social-media-wrapper button {
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  border: none;
  outline: none;
}

.login-box .social-media-wrapper button img {
  width: 24px;
}
.login-box .social-media-wrapper button:first-child {
  background: #1877f2;
}
.login-box .social-media-wrapper button:nth-child(2) {
  background: var(--colorWhite);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
}
.login-box .social-media-wrapper button:nth-child(3) {
  background: var(--colorBlack);
}

.login-box .signup-account {
  font-style: normal;
  font-weight: 500;
  font-size: var(--fontSize16);
  line-height: 183.2%;
  color: var(--colorBlack);
  margin-top: 3vh;
  text-align: center;
}
.login-box .signup-account a {
  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize16);
  line-height: 183.2%;
  text-decoration: none;
  color: var(--color1);
  margin-left: 10px;
  display: inline-block;
}

/* sign up area */
.login-box .input-containers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1vh;
  margin-top: 2vh;
  margin-bottom: 2vh;
}
.login-box .input-containers .grid-2 {
  grid-column: span 2;
}
.login-box .agremenent-area {
  display: flex;
  align-items: center;
}
.login-box .agremenent-area > input {
  display: none;
}
.login-box .agremenent-area > label {
  height: 18px;
  width: 17px;
  border: 2px solid var(--color1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.8rem;
  cursor: pointer;
}
.login-box .agremenent-area > label img {
  display: none;
}
.login-box .agremenent-area > input:checked + label {
  border-color: transparent;
}
.login-box .agremenent-area > input:checked + label img {
  display: block;
}
.login-box .agremenent-area > p {
  font-style: normal;
  font-weight: 500;
  font-size: var(--fontSize14);
  line-height: 183.2%;
  color: var(--colorBlack);
}
.login-box .agremenent-area > p a {
  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize14);
  line-height: 183.2%;
  color: var(--color1);
  text-decoration: none;
}

/* forgot password  */
.passPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: url("../images/login-bg.webp");
  background-position: center;
  background-size: cover;
}
.passBox {
  position: relative;
  width: 659px;
  min-height: 500px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 54px;
  text-align: center;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
  max-width: 95%;
  background: white;
}

.passBox button[disabled] {
  background: #979797;
  border: 1px solid #979797;
  color: white;
}

.passBox .head {
  font-size: 30px;
  font-weight: 700;
  line-height: 55px;
}

.passBox .subHead {
  font-weight: 400;
  line-height: 24px;
  color: #00000080;
  padding: 0 30px;
}

.passBox .btns {
  display: flex;
  justify-content: center;
  gap: 26px;
  width: 100%;
}
.passBox .btn {
  height: 65px;
  border-radius: 5px;
  background: #21cae7;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  width: 100%;
  flex-grow: 1;
  cursor: pointer;
}
.passBox .cancelBtn {
  background: #979797;
  color: white;
  max-width: 202px;
}
.passBox .confirmBtn {
  max-width: 202px;
}
.passBox .dontText {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 183.2%;
  margin-top: 50px;
}

.passBox .dontText span {
  color: var(--color1);
  cursor: pointer;
}

.passPage .error-custom {
  min-height: 50px;
  align-items: flex-start;
}
.passPage .error-custom span{
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1100px) {
  .girl-image,
  .boy-image {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .login-box {
    width: 90%;
  }
  .login-box .social-media-wrapper button {
    min-width: 54px;
  }
  .login-box .social-media-wrapper {
    display: flex;
    justify-content: space-between;
    grid-gap: 0px;
  }
  .login-box {
    padding: 51px 30px;
  }
  .login-box .initial-text h1 {
    font-style: normal;
    font-weight: 700;
    font-size: var(--fontSize20);
    line-height: 183.2%;
    color: var(--colorBlack);
  }
  .login-box .initial-text p {
    font-style: normal;
    font-weight: 400;
    font-size: var(--fontSize16);
    line-height: 150%;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
  }
  .login-box .or-login {
    width: 100%;
  }
  .login-box .or-login {
    margin-top: 30px;
  }
  .login-box .social-media-wrapper {
    margin-top: 20px;
    width: 208px;
  }
  .login-box {
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
  }

  .login-box .input-containers > * {
    grid-column: span 2;
  }

  .login-main-wrapper {
    padding-bottom: 30px;
  }

  .passBox {
    padding-left: 20px;
    padding-right: 20px;
  }
  .passBox .head {
    font-size: 20px;
    line-height: 36.64px;
  }

  .passBox .subHead {
    line-height: 24px;
    padding: 0;
  }
}
