* {
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.statsPage {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 5px;
  min-height: calc(100vh - 70px);
}

.statsPage .statsbg , .globalBg {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  z-index: -1;
}
/* Chart Section */
.chart-head {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: normal;
  text-align: center;
  grid-column: 1 / span 2;
}
.chart-sub-head {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: normal;
  text-align: center;
  grid-column: 1 / span 2;
  margin-bottom: 15px;
}
.chart-expense-row {
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  max-width: max-content;
  gap: 5px;
}
.chart-expense-row .income-box {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 768px) {
  .chart-expense-row {
    flex-direction: column;
  }
  .chart-expense-row .box{
    width: 100% !important;
  }
  .chart-expense-row .income-box {
    width: 100% !important; /* Full width in mobile */
    align-items: center;
  }
}

.chart-expense-row .box {
  position: relative;
  background: white;
  border-radius: 4.711px;
  border: 1px solid rgba(189, 189, 189, 0.25);
  box-shadow: 0px 4.711px 15px 0px rgba(0, 0, 0, 0.07);
  width: calc(50% - 2.5px);
  padding: 25px 30px; 
  white-space: wrap;
  gap: 8px;
}

@media (max-width: 600px) {
  .chart-expense-row .box {
    width: 90vw;
    overflow-x: hidden;
    padding: 0;
    padding-block: 15px;
    padding-bottom: 0;
  }
}
/* welcome box  */

.welcome-box {
  border-radius: 4.711px;
  border: 1px solid rgba(189, 189, 189, 0.25);
  background: #fff;
  box-shadow: 0px 4.711px 15px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  padding: 25px 30px;
  text-align: center;
}

.welcome-box .head {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.welcome-box .head span {
  color: #0c89ef;
}

.welcome-box .subHead {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.income-expense-row {
  display: flex;
  width: 100%;
  gap: 5px;
}
.income-expense-row .box {
  position: relative;
  background: white;
  border-radius: 4.711px;
  border: 1px solid rgba(189, 189, 189, 0.25);
  box-shadow: 0px 4.711px 15px 0px rgba(0, 0, 0, 0.07);
  display: grid;
  grid-template-columns: min-content 1fr;
  height: 100%;
  width: 100%;
  padding: 20px 25px;
  white-space: nowrap;
  gap: 8px;
}
.income-expense-row .box .head {
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  grid-column: 1 / span 2;
}
.income-expense-row .box .head svg {
  margin-right: 5px;
}
.income-expense-row .box .label {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.income-expense-row .box .value {
  color: #3ea7d6;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  justify-self: end;
}
/* goals box  */

.goalsBox {
  display: flex;
  flex-direction: column;
  border-radius: 4.711px;
  position: relative;
  border: 1px solid rgba(189, 189, 189, 0.25);
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 35px 40px;
  max-height: 750px;
  width: 100%;
  /* max-width: 95vw; */
}

.goalsBox .headerText {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 18px;
}
.goalsBox .headerText img{
  margin-right: 5px;
  position: relative;
  top: -2px;
}
.goalsBox .goalsOptions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
}
.goalsBox .option {
  display: flex;
  gap: 16px;
}
.goalsBox .goalsOptions .btn {
  border-radius: 5px;
  border: 1px solid var(--color1);
  height: 40px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.goalsBox .goalsOptions .active {
  background: var(--color1);
  color: #fff;
}

.goalsBox .goalsOptions .btnProgress {
  width: 140px;
}
.goalsBox .goalsOptions .btnCashflow {
  width: 109px;
}

.goalsBox .goalsOptions .btnAddNewGoal {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 183px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background: var(--color1);
}
.goalsInfoCover {
  position: relative;
  /* overflow: auto; */
  flex-grow: 1;
}
.goalsBox .goalsInfo {
  display: grid;
  grid-template-rows: min-content 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  border: 1px solid #dcdcdc;
  flex-grow: 1;
}
.goalsBox .goalsInfoInside {
  grid-column: 1 / span 3;
  position: relative;
}
.goalsBox .goalsInfoInside .goalsInfoScroller {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.goalsBox .goalsInfo .infoHeader {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background: #eeeeee;
  height: 58px;
  display: grid;
  place-items: center;
  position: sticky;
  top: 0;
  z-index: 1;
}
.goalsBox .goalsInfo .keywords-container {
  padding-left: 15px;
  padding-right: 35px;
}
.goalsBox .goalsInfo .li {
  position: relative;
}
.goalsBox .goalsInfo .keywords-container:nth-child(1),
.goalsBox .goalsInfo .keywords-container:nth-child(2),
.goalsBox .goalsInfo .keywords-container:nth-child(3) {
  padding-top: 20px;
}

.goalsBox .goalsInfoInside .general {
  position: relative;
}
.goalsBox .goalsInfo .details ul,
.goalsBox .goalsInfo .financialInfo ul {
  padding: 16px 35px;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}
.MuiList-root .accounts-cover {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 25px;
}
.MuiList-root .name-subName .name {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.MuiList-root .name-subName .subName {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.MuiList-root .amount-date .amount {
  color: #000;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.MuiList-root .amount-date .date {
  color: #000;
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.MuiList-root .AccountBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-left: 15px;
}
.MuiList-root .addNewAccountBtn .text {
  color: var(--color1);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
}
.MuiList-root .addNewAccountBtn .addIcon {
  width: 16px;
  height: 17px;
}
/* Goals Milestones  */

.milestone {
  position: relative;
  height: min-content;
  /* overflow: auto; */
}
.goalsBox .milestone {
  height: 280px;
}
.milestoneDate {
  color: #000;
  text-align: right;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 0;
  padding-left: 0;
  max-width: 75px;
  white-space: nowrap;
}
.milestoneText {
  color: #000;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-top: 0;
  position: relative;
  top: -3px;
  padding-right: 0;
  padding-left: 5px;
}
.activitiesBox .milestoneText {
  padding-left: 15px;
}
.milestoneText .goalName,
.milestoneText .editIcons {
  cursor: pointer;
}
.milestoneText .subjects {
  font-size: 12px;
  padding-left: 15px;
}
.milestoneText img {
  margin-right: 5px;
  margin-bottom: 3px;
}
.milestoneText p {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 15px;
  white-space: nowrap;
  margin-top: 5px;
}
.milestoneText p:nth-of-type(1) {
  margin-top: 15px;
}
.milestoneText p:nth-last-of-type(1) {
  margin-bottom: 10px;
}
.milestoneText p .date {
  color: #000;
  text-align: right;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  gap: 2px;
  padding-bottom: 0.5px;
}
.milestoneText p .title {
  color: #000;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.goalsInfo .dot,
.newGoalModal .dot {
  margin: 0;
  box-shadow: none;
  height: 16px;
  width: 16px;
}
.goalsInfo .left,
.newGoalModal .left {
  height: 16px;
  width: 16px;
  border: 3.5px solid var(--color1);
  background: white;
}
.goalsInfo .connector,
.newGoalModal .connector {
  width: 4px;
  background: #eff0f6;
}
.goalsInfo .done,
.newGoalModal .done {
  background: var(--color1);
}
/* Goals Details  */
.goalsInfoCover li {
  list-style: none;
}
.goalsBox .keywordMain {
  height: 26.6px;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-bottom: 44px;
  padding-right: 30px;
}
.goalsBox .goalsInfo .expanded .keywordMain {
  padding-right: 0;
}
.goalsBox .details-container {
  border-left: 1px solid #dcdcdc;
  border-right: 1px solid #dcdcdc;
}
.goalsBox .keywordMain {
  position: relative;
  display: flex;
}
.goalsBox li {
  height: 100%;
}
.goalsBox .keywordMain .keywordRow {
  display: flex;
  /* position: absolute; */
  width: 100%;
  height: 100%;
  white-space: nowrap;
  gap: 4px;
  flex-grow: 1;
  flex-wrap: wrap;
  overflow: hidden;
}
.goalsBox .expanded .keywordMain {
  height: unset !important;
  overflow: visible;
}
.goalsBox .expanded .keywordRow {
  padding: 0;
}
.goalsBox .keywordMain .keyword {
  border-radius: 3px;
  border: 1px solid #dadada;
  background: #f9f9f9;
  flex-grow: 1;
  text-align: center;
  color: var(--light-text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 0 7px;
  width: min-content;
}
.goalsBox .keywordMain .keywordCount {
  flex-grow: unset;
  padding: 0 7px;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.goalsBox .details-container .pencil,
.goalsBox .financial-container .pencil {
  position: absolute;
  top: 5px;
  right: -22px;
  cursor: pointer;
}
.goalsBox .timeline-container .editIcons {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 5px;
  top: 2px;
  right: -25px;
}

/* Goal Modal  */

.newGoalModal {
  background: white;
  height: 100%;
  border-radius: 5px;
  border: 1px solid var(--neutral-300, #eff0f6);
  background: var(--neutral-100, #fff);
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  display: flex;
  overflow: hidden;
}
.newGoalModal .sideOptions {
  list-style: none;
}
.newGoalModal .sideOptions li {
  width: 130px;
  height: 90px;
  display: grid;
  place-items: center;
  color: #000000;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 35px;
  text-align: center;
  white-space: pre-wrap;
  padding: 0 15px;
  border-bottom: 2px solid #dcdcdc;
  cursor: pointer;
}
.newGoalModal .sideOptions .active {
  color: #fff;
  font-weight: 700;
  background: var(--color1);
}

.newGoalModal .content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 2px solid #dcdcdc;
  flex-grow: 1;
}

.newGoalModal .content .closeGoalsModalBtn {
  height: 15px;
  width: 15px;
  position: absolute;
  right: 28px;
  top: 21px;
  cursor: pointer;
}

/* Goals Modal General Section  */
.newGoalModal .generalBox {
  flex-grow: 1;
  padding: 20px 0;
  padding-bottom: 0;
  gap: 20px;
}
.newGoalModal .generalBox .generalForm {
  padding: 20px 55px 0;
}

.newGoalModal .generalBox .mainText {
  gap: 35px;
}
.newGoalModal .generalBox .mainText .text {
  display: flex;
  gap: 10px;
}
.newGoalModal .generalBox .mainText .text2 {
  gap: 35px;
  padding-left: 20px;
}
.newGoalModal .generalBox .option1 {
  flex-direction: column;
  align-items: flex-start;
}

.newGoalModal .head {
  color: #170f49;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  text-align: center;
}
.newGoalModal .subHead {
  color: #6f6c90;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 166.667% */
  text-align: center;
  padding-top: 10px;
}
.newGoalModal .mainText {
  display: flex;
  align-items: center;
  gap: 15px;
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  flex-wrap: wrap;
  /* margin: auto; */
}
.newGoalModal .generalBox .mainText {
  flex-wrap: nowrap;
}
.newGoalModal .generalForm {
  width: 100%;
  flex-grow: 1;
}
.newGoalModal .choiceForm label {
  margin-right: 10px;
}
.newGoalModal .generalForm .MuiFormGroup-root {
  flex-grow: 1;
  gap: 20px;
}
.newGoalModal .mainText-freeForm .generalForm {
  align-items: flex-end;
}
.newGoalModal .generalBox .option2 {
  display: flex;
  gap: 35px;
  padding-left: 20px;
}
.newGoalModal .generalBox #selectMonth {
  padding-right: 0;
}
.newGoalModal .generalBox .option2 .mainText {
  padding-right: 10px;
}

.newGoalModal .generalBox .addOwnGoal .head {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
  padding-left: 25px;
  margin: 35px 0;
  margin-bottom: 10px;
}
.newGoalModal .generalBox .addOwnGoal .actionInput input {
  text-align: left;
}
.newGoalModal .generalBox .addOwnGoal label {
  color: rgb(0 0 0/0.8);
}
.newGoalModal .generalBox .addOwnGoal fieldset {
  border-color: rgb(0 0 0/0.23);
  border-width: 1px;
}

.newGoalModal .generalBox input {
  text-align: center;
  font-weight: 600;
}
.newGoalModal .select .MuiInputBase-root {
  margin: 0 !important;
  padding-right: 10px !important;
}
.newGoalModal .devider {
  position: relative;
}
.newGoalModal .devider .or {
  position: relative;
  z-index: 1;
  width: min-content;
  background: white;
  padding: 0 10px;
  margin: auto;
  color: #8c97ac;
}
.newGoalModal .devider::before {
  content: "";
  position: absolute;
  background: #8c97ac;
  height: 1px;
  width: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.newGoalModal .mainText .MuiInputBase-root::before {
  border-bottom: 1px solid black;
}

.newGoalModal .buttons {
  display: flex;
  justify-content: space-between;
  padding: 35px 60px;
  padding-top: 0;
}
.newGoalModal .groupBtn {
  display: flex;
  gap: 10px;
  margin-left: auto;
}
.newGoalModal .btn {
  width: 156px;
  height: 47px;
  border-radius: 5px;
  border: 1px solid var(--color1);
  background: var(--color1);
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: grid;
  place-items: center;
  cursor: pointer;
}
.newGoalModal .saveBtn {
  background: white;
  color: var(--color1);
}
.newGoalModal .groupBtn .saveBtn[disabled] {
  color: #979797;
  border: 1px solid #979797;
}
.newGoalModal .groupBtn .nextBtn[disabled] {
  background: #979797;
  border: 1px solid #979797;
}
.newGoalModal .backBtn {
  background: #979797;
  border: none;
}

.addNewMemberBtn {
  color: var(--color1);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  text-align: center;
  cursor: pointer;
}
.addNewMemberBoxMain {
  margin: 10px;
  border: 1px solid #dcdcdc;
  padding: 10px;
}
.addNewMemberBoxMain .head {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  grid-column: 1 / span 2;
}
.addNewMemberBoxMain .icons {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-bottom: 5px;
  grid-column: 1 / span 2;
}
.addNewMemberBoxMain .dobField {
  border: 1px solid rgba(0, 0, 0, 0.23);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 0 10px;
  border-radius: 4px;
  position: relative;
}
.addNewMemberBox .dobField::before {
  content: "DOB";
  background: white;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.6);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Nunito;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.15px;
  white-space: nowrap;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  left: 16px;
}
.addNewMemberBox {
  /* display: flex;
  flex-direction: column; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.addNewMemberBox .icons img {
  height: 23.5px;
  cursor: pointer;
}

/* Goals Modal Details Section  */
.newGoalModal .detailsBox {
  padding: 20px 0;
  flex-grow: 1;
}
.newGoalModal .progress {
  height: 6px;
  width: 75%;
  border-radius: 40px;
  background: #eff0f6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0 20px;
  margin: auto;
  margin-top: 25px;
}
.newGoalModal .progress .stepCover {
  position: relative;
  top: 15px;
  display: grid;
  place-items: center;
}
.newGoalModal .progress .step {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background: #eff0f6;
  display: grid;
  place-items: center;
  color: #6f6c90;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  cursor: pointer;
}
.newGoalModal .progress .stepName {
  color: #6f6c90;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.newGoalModal .progressDone {
  position: absolute;
  top: 0;
  left: 0;
  background: var(--color1);
  height: 6px;
  border-radius: 40px;
}
.newGoalModal .progress .done .step {
  background: var(--color1);
  color: white;
  font-weight: 500;
  line-height: 18px;
}
.newGoalModal .progress .done .stepName {
  color: var(--color1);
  font-weight: 700;
}

.newGoalModal .hr {
  margin-top: 10px;
  margin-bottom: 10px;
  background: #dcdcdc;
  height: 2px;
  width: 100%;
}

/* details box  */

.newGoalModal .detailsBox .mainText {
  display: grid;
  grid-template-columns: repeat(3, min-content);
  justify-content: left;
  white-space: nowrap;
  margin-top: 70px;
  margin-left: 35px;
  gap: 30px 15px;
}
.newGoalModal .detailsBox .mainText-freeForm {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 50px;
}
.newGoalModal .detailsBox .mainText-freeForm .row1 {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.newGoalModal .detailsBox .mainText-freeForm .row1 input {
  text-overflow: ellipsis;
}

.newGoalModal .detailsBox .option {
  display: flex;
  align-items: center;
}

.newGoalModal .detailsBox .row2 {
  display: flex;
  flex-direction: column;
}
.newGoalModal .detailsBox .row2 .text {
  white-space: pre-wrap;
  text-align: start;
}

.newGoalModal .detailsBox .monthCover {
  margin-right: 15px;
}
/* financial box  */

.financialBox {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px 0;
  padding-bottom: 0px;
}
.financialBox .mainText {
  padding: 30px 30px 0 60px;
  padding-top: 30px;
  gap: 14px;
  position: relative;
}
.financialBox .addNewAccountBtn {
  display: grid;
  place-items: center;
  cursor: pointer;
  position: absolute;
  right: 30px;
  bottom: 5px;
}
.financialBox .devider {
  margin: 40px 50px 0 50px;
}
.financialBox .moreAccount {
  display: flex;
  gap: 14px;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  padding-right: 38px;
}
.financialBox #account .name-subName {
  display: flex;
  gap: 15px;
}
.checkbox label {
  transform: unset;
  position: unset;
  max-height: 16px;
  width: 16px;
  border-radius: 2px;
  border: 1px solid rgb(0 0 0/0.3);
  display: flex;
}
.checkbox input:not(:checked) + label svg {
  opacity: 0;
}
.checkbox input:checked + label {
  background-color: var(--color1);
  border: 1px solid var(--color1);
}
.financialBox .financial {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 0px;
}
.financialBox .amountInput .dollar {
  margin-right: 3px;
}
.financialBox .amountInput .dollar p {
  color: #0c89ef;
}
.financialBox table thead {
  border-radius: 4.711px;
  border: 1px solid rgba(189, 189, 189, 0.25);
  white-space: nowrap;
}
.financialBox table thead th {
  background: #eeeeee;
  padding: 8px;
  color: #000;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}
.financialBox table thead .add {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 15px;
  width: 15px;
}
.financialBox table tbody td {
  text-align: center;
  gap: 10px;
  color: #000;
  font-weight: 500;
  line-height: 35px; /* 218.75% */
  padding: 8px;
}
.financialBox table th,
.financialBox table td {
  border: 1px solid rgba(189, 189, 189, 0.25);
}
.financialBox table td:first-child {
  border-left: none;
}
.financialBox table td:last-child {
  border-right: none;
  white-space: nowrap;
}
.financialBox table thead {
  border-radius: 4.5px 0 0 4.5px;
}
.financialBox table tbody td {
  position: relative;
}
.financialBox table tbody td .pencilIcon {
  position: relative;
  top: 2px;
  height: 19px;
  margin-left: 10px;
}
.financialBox table tbody .total-row td {
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 35px;
}
.financialBox .monthField {
  margin: 0;
}
.financialBox table tbody .total-row td {
  border-bottom: none;
}
.financialBox table tbody .cell-content {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.financialBox .text {
  align-items: flex-end;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 18px;
  font-style: normal;
  text-align: start;
  font-weight: 500;
  line-height: 35px;
  margin-bottom: 20px;
}
.financialBox .text p {
  display: inline-block;
}
.financialBox .text input {
  font-weight: 500;
  font-size: 18px;
  text-overflow: ellipsis;
}
.financialBox .text input:last-child {
  color: #000;
}
.financialBox .pencilIcon {
  height: 15px !important;
  opacity: 0;
}
.financialBox input:hover + * .pencilIcon,
.financialBox table td:hover .pencilIcon {
  opacity: 1;
}
.financialBox .insideContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 15px 15px; 
}

.financialBox table .deleteIcon {
  height: 12px;
  width: 16px;
  position: relative;
  top: 3px;
  color: #fd4c5c;
  cursor: pointer;
}

.financialBox .starting-balance,
.financialBox .starting-balance *,
.financialBox .monthly-addition,
.financialBox .monthly-addition *,
.financialBox .rate-of-return,
.financialBox .rate-of-return *,
.financialBox .contribution {
  color: #0c89ef;
}

.financialBox .contributionValue{
  font-size: 16px;
}

.financialBox .starting-balance input,
.financialBox .monthly-addition input {
  text-align: center;
  position: relative;
  text-overflow: ellipsis;
}

.financialBox table .starting-balance .pencilIcon,
.financialBox table .monthly-addition .pencilIcon,
.financialBox table .rate-of-return .pencilIcon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) translateX(100%);
}

.financial .financial-inside {
  flex-grow: 1;
}

.financialBox table .totalRow td {
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 35px;
}

.financialBox table .totalRow {
  position: sticky;
  bottom: 0;
  background: #fff;
}
.financialBox .text .bullet {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--color1);
  position: absolute;
  top: calc(50% - 2px);
  left: 0;
}
.financialBox .financialTableRow {
  border: 0.5px solid #dadada;
}
.financialBox .financialTableRow .headerRow {
  height: 70px;
  background: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  gap: 5px;
  cursor: pointer;
}
.financialBox .financialTableRow .headerRow .col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 5px;
}
.financialBox .financialTableRow .headerRow .col2 {
  flex-grow: 1;
  align-items: flex-end;
}
.financialBox .financialTableRow .headerRow .arrowDownLogo {
  height: 6px;
}
.financialBox .financialTableRow .mob-text {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.financialBox .financialTableRow .rowData {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: center;
  border-top: 0.5px solid #dadada;
  padding: 15px;
  gap: 10px 0;
}
.financialBox .financialTableRow .rowData .head {
  color: #000;
  font-family: Nunito;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  justify-self: start;
  white-space: nowrap;
}
.financialBox .financialTableRow .rowData .data {
  justify-self: end;
}
.financialTableRow .mob-account {
  font-weight: 400 !important;
}

.financialTableRow .row2 {
  display: flex;
  justify-content: space-between;
}

.financialTableRow .row1 {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* Activities Box */

.scroller {
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.newGoalModal .activitiesBox {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 20px 0;
  height: calc(100% - 85px);
}

.newGoalModal .activitiesMilestones {
  overflow: auto;
  position: relative;
  margin-top: 15px;
  padding: 0 60px;
  flex-grow: unset;
}

.newGoalModal .activitiesMilestones .scroller-inside {
  width: 100%;
  height: 100%;
}
.newGoalModal .addMilestoneBtn {
  padding-left: 141px;
}
.newGoalModal .addMilestoneBtn img {
  cursor: pointer;
}

.newGoalModal .activitiesMilestones .scroller-inside li {
  min-height: 65px;
}
.newGoalModal .activitiesMilestones .scroller-inside li .editOptions {
  display: flex;
  justify-content: space-between;
  height: min-content;
  gap: 16px;
}
.newGoalModal .activitiesMilestones .scroller-inside li .editOptions img {
  cursor: pointer;
}
.newGoalModal
  .activitiesMilestones
  .scroller-inside
  li:first-child
  .editOptions {
  padding-top: 2px;
}
.newGoalModal .activitiesMilestones .milestoneDate {
  min-width: 85px;
}

.addNewActivity,
.editActivity {
  padding: 20px;
  padding-bottom: 35px;
  border: 1px solid #dcdcdc;
  display: grid;
  gap: 18px;
}
.addNewActivity .heading,
.editActivity .heading {
  display: flex;
  justify-content: space-between;
}

.addNewActivity .heading .text,
.editActivity .heading .text {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
}

.addNewActivity .date-box,
.editActivity .date-box {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 25px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  padding: 0 15px;
  padding-bottom: 5px;
  height: 56px;
}
.addNewActivity .date-box::before,
.editActivity .date-box::before {
  content: "Activity Date";
  background: white;
  padding: 0 5px;
  color: rgba(0, 0, 0, 0.6);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.15px;
  white-space: nowrap;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  left: 16px;
}

.monthCover {
  position: relative;
}
.monthCover label {
  position: absolute;
  bottom: 2px;
  opacity: 0.4;
}

.addNewActivity .date-box > div {
  position: relative;
  bottom: 5px;
  align-self: flex-end;
}

.addNewActivity .fields,
.editActivity .fields {
  display: flex;
  gap: 13px;
}
.addNewActivity .btns img,
.editActivity .btns img {
  cursor: pointer;
}

/* delete goal box  */

.deleteGoalBox {
  background: white;
  width: 466px;
  max-width: 90%;
  box-shadow: 0px 5px 16px 0px rgba(8, 15, 52, 0.06);
  border-radius: 5px;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.deleteGoalBox .head {
  position: relative;
  background: var(--color1);
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Nunito;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 35px;
  padding: 16px 0;
  border-radius: 5px;
}

.deleteGoalBox .head img {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.deleteGoalBox .content {
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Nunito;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  padding: 21px 20px;
}

.deleteGoalBox .no {
  border: 1px solid var(--color1);
  background: var(--color1);
  min-width: 85px;
  color: #fff;
  font-weight: 700;
  line-height: normal;
}

.deleteGoalBox .yes {
  border: 1px solid #fd4c5c;
  background: white;
  color: #fd4c5c;
  text-align: center;
  line-height: normal;
  min-width: 85px;
}
.deleteGoalBox .btns {
  display: flex;
  justify-content: center;
  gap: 12px;
}
.deleteGoalBox .btns button {
  border-radius: 5px;
  cursor: pointer;
  height: 37px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  margin-top: 12px;
}

/* household page */
.householdBg{
  top: 60px;
}
.householdPage .financialBox .insideContent {
  padding: 35px 40px;
  padding-bottom: 10px;
}
.householdPage .financialBox table td,
.householdPage .financialBox table thead th {
  border: none;
}
.householdPage .financialBox table thead th{
  background: #eeeeee;
}
.householdPage .financialBox table td,
.householdPage .financialBox table thead th {
  text-align: left;
}
.householdPage .financialBox table td:first-child,
.householdPage .financialBox table thead th:first-child {
  padding-left: 50px;
}
.householdPage .financialBox table thead th:last-child{
  padding-left: 8px;
}
.householdPage .household-table {
  background: white;
  border: 1px solid #dcdcdc;
}
.householdPage .financialBox table tbody .cell-content {
  justify-content: flex-start;
}
.householdPage .newGoalModal{
  background: unset;
}
.householdPage .financialBox .start-date{
  display: table-cell !important;
}
.householdPage .newGoalModal .buttons{
  display: flex;
  padding: 20px 40px 25px 40px;
}
.householdPage .newGoalModal .buttons .groupBtn{
  display: flex;
  justify-content: flex-end;
}
.householdPage .newGoalModal .buttons .btn{
  width: 156px;
}
@media screen and (max-width: 900px) {
  .householdPage{
    padding-top: 20px;
  }
  .statsPage .statsbg {
    position: fixed;
    left: -24px;
    top: 121px;
    opacity: 0.5;
  }
  .householdPage .householdBg{
    position: fixed;
    width: unset;
  }
}
@media screen and (min-width: 750px) {
  .goalsBox .mob,
  .newGoalModal .mob {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .householdPage .financialBox table td:first-child,
  .householdPage .financialBox table thead th:first-child {
    padding-left: 20px;
  }
  .newGoalModal .pc {
    display: none;
  }
  .generalBox * {
    font-size: 14px;
  }
  .goalsBox {
    padding: 21px;
  }
  .income-expense-row {
    flex-wrap: wrap;
  }
  .newGoalModal .mob-backBtn {
    background: #979797;
    color: white;
    border: 1px solid #979797;
  }
  .newGoalModal .mob-backBtn img {
    margin-bottom: 1px;
  }
  .newGoalModal .mob-nextBtn {
    background: white;
    color: var(--color1);
  }
  .newGoalModal .mob-cancelBtn {
    background: #979797;
    color: white;
    border: 1px solid #979797;
  }
  .goalsBox .headerText {
    display: flex;
    align-items: center;
    padding-top: 5px;
    margin-bottom: 30px;
  }
  .goalsBox .headerText img {
    margin-right: 5px;
  }
  .goalsBox .goalsInfo,
  .goalsBox .goalsInfoInside .goalsInfoScroller {
    display: flex;
    flex-direction: column;
    border: none;
  }
  .goalsBox .goalsInfoInside {
    flex-grow: 1;
  }
  .goalsBox .keywordMain {
    margin-bottom: 5px;
    padding-right: 30px;
  }
  .goalsBox .details-container {
    border: none;
  }
  .goalsBox .goalsInfo .keywords-container:nth-child(1),
  .goalsBox .goalsInfo .keywords-container:nth-child(2),
  .goalsBox .goalsInfo .keywords-container:nth-child(3) {
    padding-top: 0;
  }
  .goalsBox .goalsInfoInside .goalsInfoScroller {
    padding-top: 20px;
  }
  .goalsBox .details-container,
  .goalsBox .financial-container {
    padding-top: 4px !important;
  }
  .goalsBox .goalsOptions .btnAddNewGoal {
    position: absolute;
    width: 149px;
    top: 20px;
    right: 22px;
  }
  .goalsBox .goalsInfo .keywords-container {
    padding: 0;
  }
  .goalsBox .keywords-box .mobilekeyword-header {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 12px;
  }
  .goalsBox .keywords-box {
    padding-left: 15px;
    width: calc(100vw - 190px);
  }
  .goalsBox .milestoneDate {
    padding-right: 8px;
    padding-left: 0;
    min-width: 50px;
    max-width: 50px;
  }
  .goalsBox .milestoneText .goalName {
    position: relative;
    height: min-content;
    padding-right: 45px;
  }
  .goalsBox .timeline-container .editIcons {
    right: 0;
  }
  .goalsBox .details-container .pencil,
  .goalsBox .financial-container .pencil {
    right: -26px;
    top: 8px;
  }
  .goalsBox .goalsInfo .li {
    position: unset;
  }
  .goalsBox .keywords-box .keywords-container {
    position: relative;
  }

  /* goal modal */

  .newGoalModal {
    flex-direction: column;
  }
  .newGoalModal .sideOptions {
    display: flex;
  }
  .newGoalModal .sideOptions li {
    border-right: 2px solid #dcdcdc;
    font-size: 14px;
    padding: 8px 0;
    height: min-content;
    flex-grow: 1;
  }
  .newGoalModal .sideOptions li:last-child {
    border-right: none;
  }

  .financialBox .mob-total .head {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .financialBox .mob-total .value {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  /* general box  */

  .newGoalModal .generalBox {
    padding-top: 40px;
  }
  .newGoalModal .generalBox .mainText .text {
    /* padding-right: 55px; */
    flex-wrap: wrap;
  }
  .newGoalModal .generalBox .mainText .text .select {
    flex-grow: 1;
    margin-right: 5px;
  }
  .newGoalModal .generalBox .mainText .text2 {
    padding-left: 0;
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
    width: 100%;
  }
  .newGoalModal .generalBox {
    padding-left: 20px;
    padding-right: 20px;
  }
  .newGoalModal .generalBox .generalForm {
    padding: 0;
  }
  .newGoalModal .generalBox .mob-heading {
    color: #000;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Nunito;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
  }
  .newGoalModal .generalBox .addOwnGoal {
    margin-top: 20px;
  }

  /* details  */

  .newGoalModal .detailsBox .mainText {
    margin: 0 20px 0 20px;
    grid-template-columns: 1fr;
    align-items: flex-start;
    place-items: start;
    gap: 20px;
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 35px;
  }

  .newGoalModal .detailsBox .selectionDropdown {
    flex-grow: 1;
    width: 100%;
  }
  .newGoalModal .mainText-freeForm .generalForm {
    align-items: flex-start;
  }
  /* .financial  */

  .financialBox {
    padding-top: 0;
  }
  .financialBox .insideContent {
    padding: 10px;
  }
  .householdPage .financialBox .insideContent{
    padding: 10px 20px !important;
  }
  .financialBox .mob-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
  }
  .financialTableRow .row1 .deleteIcon {
    position: relative;
    bottom: 1px;
  }
  .financialBox .text {
    display: grid;
  }
  .financialBox .text > span {
    position: relative;
    padding-left: 10px;
  }

  .financialBox .financialTableRow .mob-accountSelection .MuiSelect-select {
    display: flex;
    padding: 0;
  }
  .financialBox .pencilIcon {
    opacity: 1 !important;
    height: 10px !important;
    margin-bottom: 3px;
    position: relative;
    left: 5px;
  }
  .financialBox .starting-balance,
  .financialBox .starting-balance *,
  .financialBox .monthly-addition,
  .financialBox .monthly-addition *,
  .financialBox .contribution, 
  .financialBox .rate-of-return * {
    color: var(--color1) !important;
    font-weight: 700;
  }
  .financialBox .monthLabel {
    top: 7.5px !important;
    font-size: 10px;
  }
  .financialBox .start-date,
  .financialBox .end-date {
    display: flex;
    gap: 10px;
  }
  .financialBox .MuiInputBase-input {
    font-size: 15px;
  }
  .financialBox .start-date input::placeholder,
  .financialBox .end-date input::placeholder {
    font-size: 10px;
  }
  .financialBox .start-date .MuiSelect-select,
  .financialBox .end-date .MuiSelect-select {
    padding-bottom: 4px;
    padding-right: 0;
  }
  .financialBox .start-date svg,
  .financialBox .end-date svg {
    height: 14px;
    top: 6px;
    right: -6px;
  }
  .financialBox .total-contribution {
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .financialBox .text * {
    font-size: 14px !important;
  }
  .financialBox .text .dollar {
    width: 10px;
  }
  .financialBox .amountInput .dollar {
    margin-right: 7px;
  }
  .financialBox .text input {
    color: var(--color1);
    width: calc(100% + 5px);
  }
  .financialBox .text .MuiFormControl-root {
    width: 90px;
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .financialBox .mob-addContributionBtn {
    display: flex;
    align-items: center;
    gap: 13px;
    margin-top: 24px;
    color: #b22795;
    font-feature-settings: "clig" off, "liga" off;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 35px;
    cursor: pointer;
    width: min-content;
    white-space: nowrap;
  }
  .financialBox .tableCover {
    display: grid;
    gap: 10px;
  }
  .newGoalModal .choiceForm label {
    margin-right: 0;
  }
  .financialBox .mob-accountSelection * {
    font-size: 13px;
  }
  /* activities  */
  .newGoalModal .activitiesBox {
    flex-grow: unset;
    padding: 20px 0;
  }
  .newGoalModal .activitiesMilestones {
    margin: 0;
    max-height: calc(100vh - 350px);
    padding: 0 10px;
  }
  .newGoalModal .addMilestoneBtn {
    padding-left: 82px;
  }
  .newGoalModal .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    gap: 8px;
    padding: 0 20px 20px 20px;
  }
  .newGoalModal .groupBtn {
    margin-left: 0;
    width: 100%;
  }
  .newGoalModal .buttons .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 6px;
    height: min-content;
    padding: 10px 0;
    font-size: 12px;
  }
  .newGoalModal .buttons .mob-nextBtn:disabled {
    color: #979797;
    border: 1px solid #979797;
  }
  .newGoalModal .buttons .mob-nextBtn:disabled svg path {
    fill: #979797;
    stroke: #979797;
  }
  .newGoalModal .buttons .btn img {
    width: 6px;
  }
  .newGoalModal .generalBox .option2 {
    padding-left: 0;
    gap: 0px;
  }
  .newGoalModal .option2 > label {
    position: relative;
    top: 6px;
  }
  .addNewActivity .date-box,
  .editActivity .date-box {
    gap: 5px;
  }
  .addNewActivity .date-box .MuiSelect-select,
  .editActivity .date-box .MuiSelect-select {
    padding-right: 0;
  }
  .activitiesBox .milestoneText {
    font-size: 14px;
  }
  .newGoalModal .activitiesMilestones .milestoneDate {
    padding-right: 5px;
    min-width: 60px;
  }
  .newGoalModal .addMilestoneBtn {
    padding-left: 81px;
  }
}
