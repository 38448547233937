html,
body,
#root {
  width: 100%;
  font-family: var(--fontMain);
}
.holdings-right-cover {
  flex-grow: 1;
  height: 100%;
  margin-left: 1rem;
}
.holdings-right-side {
  background: var(--colorWhite);
  border: 1px solid rgba(189, 189, 189, 0.25);
  box-shadow: 0px 4.71062px 15px rgba(0, 0, 0, 0.07);
  border-radius: 4.71062px;
  border-top-left-radius: 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 2;
  height: calc(100% - 40px);
}
.holdings-page .top-area {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
}
.holdings-page .top-area,
.holdings-page .top-area .right-top-area,
.holdings-page .top-area .right-top-area .box-round {
  display: flex;
  align-items: center;
}
.holdings-page .top-area .right-top-area .box-round .mob {
  margin-right: 5px;
}
.holdings-page .top-area .right-top-area .box-round p {
  font-style: normal;
  font-weight: 800;
  line-height: 25px;
  color: var(--colorBlack);
  display: flex;
}
.holdings-page .top-area .right-top-area > * + * {
  margin-left: 43px;
}
.holdings-page .top-area .right-top-area .box-round .box {
  height: 13.407984733581543px;
  width: 14.424521446228027px;

  display: block;
  border-radius: 0px;
  margin-right: 15px;
}
.holdings-page .top-area .right-top-area .box-round .blue-box {
  background-color: var(--color18);
}
.holdings-page .top-area .right-top-area .box-round .black-box {
  background-color: var(--colorBlack);
}
.holdings-page .top-area .right-top-area .box-round .red-box {
  background-color: var(--color14);
}
.holdings-page .top-area > h1 {
  font-family: var(--fontMain);
  font-style: normal;
  font-weight: 800;
  font-size: var(--fontSize16);
  line-height: 25px;
  color: var(--colorBlack);
}

.apexcharts-data-labels text {
  offset: 20px;
}

.holdings-page {
  width: 100vw;
}
.holdings-right-side {
  position: relative;
}

.holdings-right-inside {
  display: flex;
  flex-direction: column;
}

/* Holdings Table  */

.holdings-page .scrollable-content {
  /* height: calc(100vh - 300px) !important; */
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 0;
  flex-grow: 1;
}
.holdingsTable {
  margin-top: 32px;
  position: relative;
  border-top: 1px solid rgba(224, 224, 224, 1);
}
.holdingsTable td {
  flex-grow: 1;
  font-size: var(--fontSize14);
  padding: 20px;
  font-weight: 600;
}
.holdingsTable .id-cell {
  padding-left: 20px;
  font-weight: 600;
}
.holdingsTable th {
  padding-left: 20px;
}
.holdingsTable .MuiDataGrid-columnHeaderTitle,
.holdingsTable .custom-header {
  font-size: var(--fontSize14);
  text-transform: uppercase;
  font-weight: 700;
}
.holdingsTable .custom-header {
  display: flex;
  align-items: center;
  gap: 3px;
}
.holdingsTable th {
  font-weight: 700;
  white-space: nowrap;
}

.holdingsTable .insideTable {
  border: 0.5px solid #dadada;
  background: #f9f9f9;
}

.holdingsTable .insideTable th .custom-header,
.holdingsTable .insideTable td {
  font-size: var(--fontSize14);
}

.holdingsTable tr td:first-child {
  color: var(--color9);
  white-space: nowrap;
}
.holdingsTable tr td:first-child::before {
  content: url("../images/arrow-down.svg");
  margin-right: 7px;
  position: relative;
  top: -3px;
  transform: rotate(90deg);
}

.holdingsTable .row-expanded td:first-child::before {
  content: url("../images/arrow-up-active.svg");
}
.holdingsTable .insideTable tr td:first-child {
  color: unset;
}
.holdingsTable .insideTable tr td:first-child::before {
  content: unset;
}
.holdingsTable .holdings-right-side {
  width: 100%;
}

.holdings-page .holdings-chart {
  /* padding: 15px 70px; */
}
.holdings-page .total {
  border: 1px solid var(--color12);
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  font-family: var(--fontMain);
  font-size: var(--fontSize18);
  font-weight: 700;
  line-height: 24px;
}
.holdings-page .custom-tooltip {
  background: white;
  display: flex;
  flex-direction: column;
  gap: 0;
  border-radius: 5px;
  box-shadow: 2px 2px 6px -4px #999;
  white-space: nowrap;
}
.holdings-page .custom-tooltip .custom-marker {
  width: 12px;
  height: 12px;
  display: inline-block;
  position: relative;
  top: 0;
  margin-right: 10px;
  border-radius: 50%;
  background: var(--color1);
}
.holdings-page .custom-tooltip .cost-basis {
  background: var(--color14);
}

.holdings-page .recharts-tooltip-wrapper {
  border-radius: 4px;
  overflow: hidden;
}
.holdings-page .recharts-default-tooltip {
  padding: 0 !important;
}
.holdings-page .recharts-tooltip-label {
  background: #eceff1;
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding: 6px;
  display: flex;
  font-size: 12px;
}
.holdings-page .recharts-tooltip-item-list {
  display: flex;
  flex-direction: column-reverse;
  font-size: 12px;
}
.holdings-page .recharts-tooltip-item-list .recharts-tooltip-item {
  color: black !important;
  padding: 12px !important;
}
.holdings-page .recharts-tooltip-item-list .recharts-tooltip-item:last-child {
  padding-bottom: 0 !important;
}
.holdings-page .recharts-tooltip-item .recharts-tooltip-item-value {
  font-weight: 700;
}
.holdings-page .recharts-tooltip-item-list .recharts-tooltip-item:first-child {
  display: none !important;
}
.holdings-page .recharts-tooltip-item-list .recharts-tooltip-item::before {
  content: "";
  height: 12px;
  width: 12px;
  position: relative;
  top: 1.5px;
  display: inline-block;
  border-radius: 50%;
  background: #000;
  margin-right: 8px;
}
.holdings-page
  .recharts-tooltip-item-list
  .recharts-tooltip-item:last-child:before {
  background: var(--color1);
}

.holdings-page
  .cost-up
  .recharts-tooltip-item-list
  .recharts-tooltip-item:last-child:before {
  background: var(--color14);
}
.holdings-page .recharts-tooltip-item {
  padding: 0 10px;
  padding-top: 8px;
  color: black;
  font-weight: 500;
}
.holdings-page .custom-tooltip .tooltip-value span {
  font-weight: 700;
}
.holdings-page .custom-tooltip .value-cover {
  display: grid;
  grid-template-rows: 1fr 1fr;
  padding-bottom: 5px;
}
/* holdings mobile options  */

.holdings-mob-options {
  display: flex;
  gap: 35px;
  list-style: none;
  margin-bottom: 20px;
  padding: 0 20px;
}

.holdings-mob-options li {
  position: relative;
  font-family: var(--fontMain);
  font-size: var(--fontSize16);
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.2758620083332062px;
  text-align: center;
  height: 34px;
  cursor: pointer;
  color: var(--colorBlack5);
}
.holdings-mob-options .active {
  color: var(--color13);
}
.holdings-mob-options .active::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 6px;
  background-color: var(--color13);
  border-radius: 0 0 6px 6px;
}

/* mobile holdings table  */

/* mobile merchant table  */
.mobHoldingsTableRow {
  font-family: var(--fontMain);
  font-size: var(--fontSize16);
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 10px;
  border: 1px solid #e0e0e0;
}
.mobHoldingsTableHeaders {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  height: 64px;
  background: #f9f9f9;
  padding-left: 11px;
  padding-right: 11px;
}
.mobHoldingsTableHeaders .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobHoldingsTableHeaders svg {
  margin-right: 6px;
}
.mobHoldingsTableHeaders svg {
  transform: rotate(180deg);
}
.mobHoldingsTableHeaders .activeSvg {
  transform: unset;
}
.mobHoldingsTableHeaders .name {
  color: var(--colorBlack);
  font-size: var(--fontSize15);
  font-family: var(--fontMain);
  font-weight: 700;
  margin-left: 3px;
  flex-grow: 1;
}
.mobHoldingsTableHeaders .amount {
  color: var(--colorBlack);
  text-align: right;
  font-size: var(--fontSize15);
  font-family: var(--fontMain);
  font-weight: 700;
}
.mobHoldingsTableHeaders .row2 .cell {
  font-family: var(--fontMain);
  font-size: var(--fontSize13);
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}
.mobHoldingsTableHeaders .row2 .name {
  flex-grow: 1;
  margin-left: 17px;
}
.mobHoldingsTableHeaders .row2 .cell {
  font-weight: 500;
}

.mobHoldingsTableInside {
  position: relative;
  height: min-content;
  display: flex;
  flex-direction: column;
  padding-left: 11px;
  padding-right: 11px;
  border-top: 0.5px solid #dadada;
}
.mobHoldingsTableInside .row {
  display: flex;
  justify-content: space-between;
}
.mobHoldingsTableInside .spendings {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
}
.mobHoldingsTableInside .reviews {
  padding-bottom: 22px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
}
.mobHoldingsTableInside .commentsLogo {
  position: relative;
  top: 4px;
}
.mobHoldingsTableInside {
  padding: 16px 22px;
  gap: 16px;
}
.mobHoldingsTableInside .value {
  font-weight: 600;
}
.mobHoldingsTableInside .view-more {
  position: relative;
  color: var(--color1);
  text-align: center;
  font-size: var(--fontSize16);
  font-family: var(--fontMain);
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  gap: 6px;
  padding-bottom: 7px;
  cursor: pointer;
}
.mobHoldingsTableInside .view-more::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 108%;
  left: -4%;
  bottom: 0;
  background: var(--color1);
  border-radius: 0 0 8px 8px;
}
.mobHoldingsTableInside .view-more img {
  position: relative;
  top: 0.2px;
}

/* view more table  */

.viewMoreTable .mainRow {
  display: grid;
  background: #f9f9f9;
  border-bottom: 1px solid #e0e0e0;
  padding: 16px 22px;
  gap: 16px;
}
.viewMoreTable .row {
  display: flex;
  justify-content: space-between;
}
.viewMoreTable .mainRow:last-child {
  border: none;
}
.viewMoreTable .row .value {
  font-weight: 600;
}
.viewMoreTable .costBasis {
  width: 105px;
}

@media screen and (min-width: 900px) {
  .holdings-page .chart-container .recharts-responsive-container {
    position: relative;
    width: 100%;
    min-height: 380px;
  }
  .holdings-page .chart-container {
    position: relative;
    width: 100%;
    min-height: 400px;
  }
  .holdings-right-inside {
    position: absolute !important;
    width: 100%;
    height: 100%;
    padding: 25px 29px;
  }
}

@media screen and (max-height: 1050px) {
  .holdings-page .chart-container .recharts-responsive-container {
    min-height: 240px !important;
    height: 240px !important;
  }
  .holdings-page .chart-container {
    min-height: min-content;
    height: min-content;
    padding: 0;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 900px) {
  .holdings-page .chart-container {
    padding: 0;
    padding-right: 20px;
  }
  .holdings-bg {
    position: fixed;
    left: -24px;
    top: 121px;
    opacity: 0.5;;
  }
  .holdings-page .active-ALL {
    padding-bottom: 20px !important;
  }
  .holdings-right-side {
    padding: 20px;
    padding-bottom: 5px;
    height: 100%;
  }
  .holdings-right-side .top-area {
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
  }
  .holdings-page .filters header {
    font-family: var(--fontMain);
    font-size: var(--fontSize24);
    font-weight: 800;
    line-height: 33px;
    letter-spacing: 0px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .holdings-page .filter-cover img {
    border: 1px solid var(--color1);
    height: 25px;
    width: 25px;
    padding: 4px;
    border-radius: 2px;
  }
  .holdings-page .filter-cover img {
    border: 1px solid var(--color1);
    height: 25px;
    width: 25px;
    padding: 4px;
    border-radius: 2px;
  }
  .holdings-page .filters > * {
    margin-bottom: 23px;
  }
  .holdings-page .filters {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 311px;
    height: 581px;
    max-width: 100%;
    max-height: 100%;
    background: white;
    z-index: 12;
    padding: 25px;
    gap: 0;
  }
  .mob-filter-backdrop {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: rgb(0 0 0/0.5);
    z-index: 11;
  }
  .holdings-right-side .top-area > h1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .holdings-right-cover {
    margin-left: 0;
  }
  .holdings-right-inside {
    height: calc(100% - 50px);
  }
  .active-ALL .holdings-right-inside {
    height: 100%;
  }
  .holdings-page {
    height: calc(100vh - 70px);
    padding: 15px 0;
    padding-bottom: 0;
  }
  .holdings-page .holdingstgfr4443-body-inner-area {
    height: calc(100% - 100px);
    padding: 0 20px;
    overflow-y: auto;
  }
  .holdings-page .scrollable-content {
    padding-right: 0;
    height: calc(100% - 85px) !important;
  }
  .holdings-page .MuiAlert-root {
    margin: 0 20px 0 20px;
    width: calc(100% - 40px);
  }
  .active-ALL .holdings-right-side {
    /* margin-top: 10px; */
  }
}

@media screen and (max-width: 800px) {
  .right-top-area .box-round {
    margin-left: 0 !important;
  }
  .right-top-area {
    gap: 20px;
    white-space: nowrap;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 900px) and (max-width: 1000px) {
  .holdings-page .top-area .box-round {
    margin-left: 10px;
  }
}
@media screen and (min-width: 900px) and (max-width: 930px) {
  .holdings-page .top-area .right-top-area .box {
    margin-right: 5px !important;
  }
}
@media screen and (min-width: 900px) and (max-width: 1350px) {
  .holdings-page .top-area .right-top-area .box-round .mob {
    display: none;
  }
}
@media screen and (max-width: 750px) {
  .holdings-page .recharts-default-tooltip {
    font-size: var(--fontSize12);
  }
  .holdings-page .recharts-tooltip-item-list .recharts-tooltip-item::before {
    height: 10px;
    width: 10px;
    top: 0;
    margin-right: 6px;
  }
  .holdings-page .top-area .right-top-area .box-round .mob {
    display: none;
  }
  .recharts-cartesian-axis-tick text {
    font-size: var(--fontSize12);
  }
  .holdings-page .chart-container {
    padding-left: 0;
    padding-right: 0;
    /* padding-top: 10px; */
  }
  .holdings-page .scrollable-content {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 500px) {
  .custom-x-label {
    display: inline;
    font-size: var(--fontSize8);
    width: min-content;
  }
  .recharts-cartesian-axis-tick foreignObject {
    border: 1px solid 20px;
  }
}

@media screen and (max-width: 450px) {
  .holdings-right-side {
    padding: 15px;
  }
}
@media screen and (max-width: 415px) {
  .holdings-page .scrollable-content {
    height: calc(100% - 85px) !important;
  }
  .holdings-body-inner-area,
  .dashboard-header, .accounts-navigation {
    padding: 0 15px !important;
  }
  .holdings-body-inner-area {
    padding-bottom: 15px !important;
  }
  .holdings-mob-options {
    padding: 0 15px !important;
  }
  .header-options-mob li img {
    margin-left: 12px !important;
    position: relative;
    top: 2px;
    height: 22px;
    width: 22px;
  }
  .header-options-mob li img:first-child {
    margin-left: 0 !important;
    height: 20px;
    width: 20px;
  }
  .holdings-page .MuiAlert-root {
    margin: 0 10px 0 10px;
  }
  .holdings-page .MuiAlert-root {
    width: unset;
  }
  .holdings-mob-options {
    gap: 20px;
    margin-bottom: 15px;
  }
  .header-options-mob {
    padding-left: 10px;
  }
  .dashboard-header-logo {
    width: 40px;
    height: 35px;
    position: relative;
    top: -4px;
  }
  .dashboard-header .burger {
    height: 35px;
    width: 35px;
    margin-left: 12px;
    position: relative;
    top: 1px;
  }
  .holdings-page {
    padding: 5px 0;
  }

  .dashboard-header-logo {
    height: 40px !important;
    width: 45px !important;
  }
  .account-list {
    min-width: unset;
    padding-top: 15px;
  }
  .holdings-right-side .top-area > h1 {
    font-size: var(--fontSize18);
  }
  .right-top-area {
    gap: 10px !important;
  }
  .holdings-right-side .top-area > h1 {
    font-size: 15.5px;
  }
  .holdings-right-side .top-area .right-top-area .box-round p {
    font-size: var(--fontSize14);
  }
  .holdings-right-side .top-area {
    gap: 5px;
    margin-bottom: 20px;
  }
  .DropdownLeftSide .dropdownHead h1,
  .DropdownLeftSide .dropdownHead p,
  .calculate-header p {
    font-size: var(--fontSize15);
  }
  .header-options-mob {
    font-size: 18px !important;
    padding-left: 12px !important;
  }

  .holdings-mob-options li {
    font-size: var(--fontSize14);
  }

  .DropdownLeftSide .dropdownHead {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 335px) {
  .holdings-body-inner-area,
  .dashboard-header {
    padding: 0 10px !important;
  }
  .holdings-right-side .top-area .right-top-area .box-round span {
    height: 10px;
    width: 10px;
  }
}
