section {
  padding: 30px 60px;
  position: relative;
}
section h1 {
  font-size: var(--fontSize48);
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -1px;
  text-align: center;
  margin-bottom: 16px;
}
section .inshort {
  font-size: var(--fontSize21);
}
/* mission css  */
.mission {
  overflow: hidden;
}
.mission .aboutBg {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 775.43798828125px;
  width: 1397px;
  z-index: -1;
}
.mission header {
  margin-bottom: 23px;
}
.mission p {
  font-size: var(--fontSize21);
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: center;
}
.swiper {
  margin-top: 15px;
  width: 100%;
}
.swiper img {
  height: 30vw;
  box-shadow: 0px 4px 50px 0px var(--colorBlack) 26;
}
.swiper-wrapper {
  padding: 40px;
  left: -75px;
}
.swiper-pagination {
  position: relative;
  display: flex;
  gap: 15px;
  top: unset !important;
  bottom: 25px !important;
  justify-content: center;
}
.swiper-pagination .swiper-pagination-bullet {
  filter: unset;
  opacity: unset;
  height: 14px;
  width: 14px;
  background: #9a9a9a;
  margin: 0 !important;
}
.swiper-pagination .swiper-pagination-bullet-active {
  background: var(--colorBlack2);
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  background-image: unset !important;
}

/* Story css */
.story::after {
  content: "";
  position: absolute;
  height: 590px;
  width: 100%;
  left: 0px;
  top: -200px;
  background: linear-gradient(
    360deg,
    rgba(246, 252, 255, 0) -3.73%,
    #f1fafd 67.02%
  );
}
.story > * {
  position: relative;
  z-index: 1;
}
.story::before {
  content: "";
  position: absolute;
  height: 504px;
  width: 504px;
  top: 0;
  left: 22px;
  transform: translateY(-33%);
  border-radius: 50%;
  background: linear-gradient(to top, var(--color5) -30%, transparent 50%);
  z-index: 0;
}
.story .inshort {
  display: grid;
  gap: 25px;
  margin-bottom: 43px;
}
.story p {
  font-size: var(--fontSize21);
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: center;
}
.story p span {
  font-weight: 600;
}
.story-grid {
  display: grid;
  gap: 48px;
  grid-template-columns: 1fr 1fr;
}
.story-grid .main-title {
  font-size: var(--fontSize21);
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: left;
}

.story-grid .main-title h2 {
  font-size: var(--fontSize30);
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: center;
}

.story-grid .title {
  font-weight: 700;
}
.story-grid .box {
  margin-top: 30px;
}
.story-grid p {
  font-size: var(--fontSize16);
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--colorBlack3);
}
.story-grid .box5 .title {
  margin-bottom: 20px;
}

.story-grid .box5 .title h3 {
  margin-bottom: 20px;
  font-size: var(--fontSize21);
}

.story-grid span {
  color: black;
}
.story-grid .highlight {
  font-weight: 700;
}
/* approach */
.approach {
  position: relative;
  overflow: hidden;
  /* height: 434px; */
  background: var(--colorWhite2);
}
.approach header {
  margin-bottom: 30px;
}
.approach .inshort {
  display: grid;
  gap: 20px;
}
.approach p {
  font-size: var(--fontSize21);
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0px;
  text-align: center;
}
.approach p span {
  font-weight: 700;
  color: var(--color9);
}
.approach > * {
  position: relative;
  z-index: 1;
}
.approach .circle {
  height: 519px;
  width: 542px;
  position: absolute;
  top: 0;
  right: 0;
  background: var(--color20);
  border-radius: 50%;
  z-index: 0;
  transform: translate(40%, -65%);
}

/* explore  */
.explore {
  height: 646px;
  background-image: url("../images/empower.webp");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--colorWhite);
}
.explore h1 {
  font-size: var(--fontSize48);
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -1px;
  text-align: center;
  color: var(--colorWhite);
  margin: 0;
}
.explore h3 {
  font-size: var(--fontSize16);
  font-weight: 400;
  margin-top: 30px;
}
.explore a {
  height: 65px;
  width: 193px;
  border-radius: 5px;
  background: var(--color1);
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0px;
  color: var(--colorBlack);
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 70px;
}

@media screen and (max-width: 900px) {
  .swiper {
    overflow: unset !important;
    display: flex;
    justify-content: center;
  }
  .swiper-slide {
    display: flex !important ;
    justify-content: center;
    width: 520px !important;
  }
  .swiper img {
    height: 400px;
  }
  .swiper-wrapper {
    position: absolute;
    left: calc(100vw - 630px);
  }
  .swiper,
  .swiper-container {
    display: flex !important;
    justify-content: center;
  }
}
@media screen and (max-width: 800px) {
  .explore h1 {
    font-family: var(--fontMain);
    font-size: var(--fontSize16);
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    margin-bottom: 0 !important;
  }
  .explore h3 {
    margin-top: 10px;
  }
}

@media screen and (max-width: 750px) {
  .mission .aboutBg {
    height: 450px;
    width: 770.9509887695312px;
  }
  .story .inshort {
    margin-bottom: 80px;
  }
  .approach p {
    font-size: var(--fontSize18);
    font-weight: 400;
    line-height: 28px;
    color: var(--colorBlack3);
  }
  .approach span {
    color: black !important;
  }
  .story::before {
    left: unset;
    right: 50%;
    transform: translate(30%, -80%);
  }
  .swiper-wrapper {
    left: calc(100vw - 555px);
  }
  .swiper img {
    height: 60vw;
  }
  section {
    padding: 25px;
  }
  .mission {
    padding-top: 0;
  }
  section h1 {
    font-size: var(--fontSize40);
    font-weight: 700;
    line-height: 50px;
    letter-spacing: -1px;
    text-align: center;
    margin-bottom: 9px !important;
  }
  section .inshort,
  .mission p {
    font-size: var(--fontSize18);
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: center;
  }
  .story-grid {
    grid-template-columns: 1fr;
    text-align: center;
  }
  .story-grid .main-title,
  .story-grid p {
    text-align: center;
  }
  .story p {
    font-size: var(--fontSize16);
    line-height: 24px;
  }
  .explore {
    height: 273px;
    background-position: center;
    background-size: cover;
    background-image: url("../images/empower-mobile.webp");
    padding: 0;
  }
  .explore h1 {
    display: flex;
    font-size: var(--fontSize30);
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -1px;
    text-align: center;
    margin: 0;
  }
  .explore h1 > div {
    margin-left: 6px;
  }
  .explore a {
    margin-top: 20px;
  }
}
@media screen and (max-width: 400px) {
  .explore h1 {
    display: unset;
  }
}
