* {
  font-family: var(--fontMain);
}
.sign-up-main-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  background: url("../images/SignUp.png");
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.error-custom {
  font-style: normal;
  font-weight: 400;
  font-size: var(--fontSize14);
  line-height: 100%;
  color: var(--color14);
  display: flex;
  align-items: center;
  margin-top: 0px;
  width: 100%;
}
.error-custom.login-error {
  margin-top: 10px;
}
.error-custom svg {
  margin-right: 10px;
}
.sign-up-box {
  width: 659px;
  border-radius: 0px;
  background: white;
  padding: 30px 54px;
  margin-top: 30px;
  margin-bottom: 20px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
  position: relative;
}

/* .girl-image,
.boy-image {
  position: absolute;
  bottom: -2%;
  transform: translateY(2%);
  z-index: 1;
}

.boy-image {
  left: -280px;
}
.girl-image {
  right: -240px;
} */

.girl-image,
.boy-image {
  position: absolute;
  /* top: -50px; */
  z-index: 0;
}
.boy-image {
  left: -220px;
  top: 0vh;
  height: 80vh;
}
.girl-image {
  right: -200px;
  height: 85vh;
  top: -5vh;
}

.sign-up-box .initial-text h1 {
  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize30);
  margin-bottom: 20px;
  color: var(--colorBlack);
  text-align: center;
}
.sign-up-box .initial-text p {
  font-style: normal;
  font-weight: 400;
  font-size: var(--fontSize16);

  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

.sign-up-box .field-auth {
  width: 100%;
}

.sign-up-box .forget-password-link {
  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize14);
  line-height: 183.2%;
  text-align: right;
  color: var(--color1);
  align-self: flex-end;
  margin-top: 29px;
  margin-bottom: 29px;
  text-decoration: none;
}

.sign-up-box .forget-password-link-wrapper {
  margin-top: 1vh;
  margin-bottom: 1vh;
  text-align: right;
}

.sign-up-box .button-auth-main {
  height: 65px;
  width: 100%;
  background: var(--color1);
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize16);
  line-height: 20px;
  /* identical to box height */

  text-align: center;

  color: var(--colorBlack);
}

.sign-up-box .or-login {
  display: flex;
  align-items: center;
  margin: 0rem auto;
  margin-top: 20px;
  width: 85%;
}
.sign-up-box .or-login span {
  background-color: var(--color15);
  height: 1px;
  flex: 1;
}
.sign-up-box .or-login p {
  font-style: normal;
  font-weight: 500;
  font-size: var(--fontSize14);
  line-height: 100%;
  text-align: center;
  color: var(--color15);
  margin: 0rem 10px;
}

.sign-up-box .social-media-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  width: 85%;
  margin: 0rem auto;
  margin-top: 20px;
}

.sign-up-box .social-media-wrapper button {
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
  border: none;
  outline: none;
}

.sign-up-box .social-media-wrapper button img {
  width: 24px;
}
.sign-up-box .social-media-wrapper button:first-child {
  background: #1877f2;
}
.sign-up-box .social-media-wrapper button:nth-child(2) {
  background: var(--colorWhite);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168);
}
.sign-up-box .social-media-wrapper button:nth-child(3) {
  background: var(--colorBlack);
}

.sign-up-box .signup-account {
  font-style: normal;
  font-weight: 500;
  font-size: var(--fontSize16);
  line-height: 100%;
  color: var(--colorBlack);
  margin-top: 20px;
  text-align: center;
}
.sign-up-box .signup-account a {
  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize16);
  line-height: 100%;
  text-decoration: none;
  color: var(--color1);
  margin-left: 10px;
  display: inline-block;
}

/* sign up area */
.sign-up-box .input-containers {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
  margin-top: 20px;
}
.sign-up-box .input-containers .grid-2 {
  grid-column: span 2;
}
.sign-up-box .agremenent-area {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.sign-up-box .agremenent-area > input {
  display: none;
}
.sign-up-box .agremenent-area > label {
  max-height: 16px; 
  max-width: 17px;
  min-height: 16px; 
  min-width: 17px;
  border: 2px solid var(--color1);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.8rem;
  cursor: pointer;
}
.sign-up-box .agremenent-area > label img {
  display: none;
}
.sign-up-box .agremenent-area > input:checked + label {
  border-color: transparent;
}
.sign-up-box .agremenent-area > input:checked + label img {
  display: flex !important;
}
.sign-up-box .agremenent-area > p {
  font-style: normal;
  font-weight: 500;
  font-size: var(--fontSize14);
  line-height: 20px;
  color: var(--colorBlack);
}
.sign-up-box .agremenent-area > p a {
  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize14);
  line-height: 100%;
  color: var(--color1);
  text-decoration: none;
}

@media screen and (max-width: 1100px) {
  .girl-image,
  .boy-image {
    display: none;
  }
}
@media screen and (max-width: 730px) {
  .sign-up-box {
    width: 90%;
  }
  .sign-up-box .social-media-wrapper button {
    min-width: 54px;
  }
  .sign-up-box .social-media-wrapper {
    display: flex;
    justify-content: space-between;
    grid-gap: 0px;
  }
  .sign-up-box {
    padding: 51px 30px;
  }
  .sign-up-box .initial-text h1 {
    font-style: normal;
    font-weight: 700;
    font-size: var(--fontSize20);
    line-height: 183.2%;
    color: var(--colorBlack);
  }
  .sign-up-box .initial-text p {
    font-style: normal;
    font-weight: 400;
    font-size: var(--fontSize16);
    line-height: 150%;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
  }
  .sign-up-box .or-login {
    width: 100%;
  }
  .sign-up-box .or-login {
    margin-top: 30px;
  }
  .sign-up-box .social-media-wrapper {
    margin-top: 20px;
    width: 208px;
  }
  .sign-up-box {
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
  }

  .sign-up-box .input-containers > * {
    grid-column: span 2;
  }

  .sign-up-main-wrapper {
    padding-bottom: 30px;
  }
}
