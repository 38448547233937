.dashboard-header {
  min-height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  padding: 0rem 34px;
  background: white;
  position: sticky;
  top: 0;
  border-bottom: 1px solid rgb(0 0 0/0.1) !important;
}
.dashboard-header-logo {
  height: 50px;
  justify-self: start;
}
.dashboard-header .menu-button-right-side {
  width: 60px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-header .filterBtnCover {
  display: flex;
  align-items: center;
  position: relative;
  justify-self: end;
}
.dashboard-header .filterBtnCover .filterCount {
  position: absolute;
  top: -5px;
  right: -7px;
  background: #0c89ef;
  display: grid;
  place-items: center;
  border: 1px solid white;
  color: white;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  font-family: "Nunito";
  font-size: 8px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.dashboard-header .menu-button-right-side svg {
  cursor: pointer;
}

.navigation-system {
  display: flex;
  align-items: center;
  max-width: min-content;
  flex: 1;
  gap: 3vw;
  height: 100%;
}

.navigation-system button {
  margin: 0px;
  padding: 0;
  padding-bottom: 4px;
  position: relative;
  white-space: nowrap;
}
.navigation-system button.menu-active {
  color: var(--color2) !important;
}
.navigation-system button.menu-active::after,
.navigation-system button.menu-active-border::after {
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: -5px;
  left: 0px;
  content: "";
  background: var(--color13);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.menu-main {
  margin-top: 1.25rem !important;
}
.menu-main ul {
  width: auto !important;
}
.menu-main ul li {
  font-family: var(--fontMain);
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 0.15px;
  gap: 10px;
}

.name-header {
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  text-align: right;
  color: var(--colorBlack);
}
.email-header {
  font-style: normal;
  font-weight: 300;
  font-size: var(--fontSize12);
  line-height: 16px;
  color: var(--colorBlack);
}
#profile-menu {
  margin-left: 14px !important;
  margin-right: 18px !important;
}

.dashboard-header-logout-button {
  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize16);
  line-height: 22px;
  text-transform: unset;
}
.dashboard-header-logout-button p {
  color: var(--color14);
}
.linking-account-progress {
  width: 90%;
  margin: 0rem auto;
  margin-bottom: 2rem;
}
.linking-account-progress .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.linking-account-progress .top h1 {
  font-style: normal;
  font-weight: 500;
  font-size: var(--fontSize14);
  line-height: 19px;
  color: var(--colorBlack);
}
.linking-account-progress .top p {
  font-style: normal;
  font-weight: 500;
  font-size: var(--fontSize14);
  line-height: 19px;
  color: var(--color11);
}
.linking-account-progress .progress-bar {
  width: 100%;
  height: 12px;
  background: var(--color12);
  border-radius: 99px;
  margin-top: 1rem;
}
.linking-account-progress .progress-bar span {
  display: block;
  width: 0px;
  height: 100%;
  background: #27c1e4;
  border-radius: 100px;
  transition: 0.3s ease all;
}
.header-options-mob {
  list-style: none;
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  font-size: var(--fontSize20);
  font-weight: 700;
}
.header-options-mob li img {
  margin-left: 24px;
}

.dashboardPage .footerNote{
  font-size: 8px;
}

@media screen and (max-width: 1100px) {
  .dashboard-header-logo {
    width: 60px;
  }
}
@media screen and (min-width: 900px) and (max-width: 1100px) {
  .dashboard-header {
    padding: 0 12px;
  }
}
@media screen and (max-width: 900px) {
  .dashboard-header-logo {
    width: 54px;
    height: 45px;
  }
  .dashboard-header {
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: none;
    border: none;
  }
  .dashboard-header .burger {
    margin-right: 0 !important;
  }
  .dashboard-header #profile-menu img {
    height: 45px;
    width: 45px;
    margin-right: 0;
    position: relative;
    top: -2px;
  }
}
