* {
  font-family: var(--fontMain);
}

.hidden {
  display: none !important;
}

.insights {
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  height: calc(100vh - 70px);
  flex-grow: 1;
}

.insightsBg {
  position: fixed;
  left: -44px;
  top: 32px;
  width: 99%;
  height: 100%;
  z-index: -1;
}

.insights .sidebar,
.sidebar-cover {
  flex-grow: 1 !important;
}

/* insights sidebar  */

.insights .sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  box-shadow: 0px 4px 4px 0px var(--colorBlack) 40;
  border-radius: 4px;
  background: white;
  border: 1px solid var(--color12);
}

.insights .header-options-mob {
  display: flex;
  justify-content: space-between;
  list-style: none;
  font-size: var(--fontSize20);
  font-weight: 700;
  line-height: 27px;
  height: 31px;
  letter-spacing: 0em;
}
.insights .header-options-mob img {
  margin-left: 24px;
}
.insights .sidebar header {
  display: flex;
  justify-content: space-between;
  font-size: var(--fontSize18);
  font-weight: 700;
  padding: 19px 17px;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
}
.insights .sidebar header img {
  border: 1px solid var(--color1);
  height: 25px;
  width: 25px;
  padding: 4px;
  border-radius: 2px;
}
.insights .sidebar .MuiDataGrid-columnHeadersInner {
  flex-grow: 1;
}
.insights .sidebar .MuiDataGrid-columnHeadersInner > div[role="row"] {
  width: 100%;
  padding-right: 10px;
}
.insights .sidebar .total {
  border: 1px solid var(--color12);
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  padding: 15px 30px;
  font-size: var(--fontSize18);
  font-weight: 700;
  line-height: 24px;
}
.insights .sidebar .total .price {
  color: var(--color14);
}
.MuiDataGrid-colCellTitle {
  font-weight: bold;
}

.insights .MuiDataGrid-columnHeaderTitle {
  font-weight: 700;
}

.insights .MuiDataGrid-iconSeparator {
  display: none;
}

.insights-data {
  flex-grow: 1;
  width: 100%;
  max-width: 100%;
}
.insights-data-inside {
  border: 1px solid var(--color12);
  box-shadow: 0px 4px 4px 0px var(--colorBlack) 40;
  border-radius: 0 4px 4px 4px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.insights > .row2 {
  position: relative;
  display: flex;
  gap: 20px;
  height: calc(100vh - 100px);
}
.insights .sidebar .MuiDataGrid-row {
  background: #f9f9f9;
  font-weight: 700;
  width: 100%;
  font-size: var(--fontSize14);
  line-height: 24px;
  letter-spacing: 0.2px;
}
.insights .sidebar .category-cell {
  flex-grow: 1;
  overflow: visible;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 0;
}
.insights .sidebar .category-cell > div {
  white-space: pre-wrap;
}
.insights .sidebar .amount-cell {
  padding-right: 20px;
}
.insights .sidebar .amount-cell .percentage {
  padding-right: 5px;
  position: relative;
}
.insights .sidebar .amount-cell .percentage svg {
  color: var(--color1);
  font-size: var(--fontSize20);
  position: absolute;
  top: 0px;
  transform: translateY(-50%);
  right: -25px;
}
.insights .sidebar .MuiDataGrid-row .category {
  text-transform: uppercase;
}
.insights .sidebar .MuiDataGrid-row .sub-category,
.insights .sidebar .MuiDataGrid-row .percentage {
  font-weight: 400;
}
.insights .sidebar .MuiDataGrid-root {
  border: none;
}

.insights .MuiDataGrid-virtualScrollerRenderZone {
  width: 100%;
}
.insights .MuiDataGrid-cell {
  outline: none !important;
  padding-top: 10px;
  padding-bottom: 10px;
  display: grid;
  align-items: center;
}
.insights .custom-date-cell,
.insights .custom-date-header {
  padding-left: 50px !important;
}

.insights .sidebar .insight-grid-head {
  flex-grow: 1;
}
.insights .sidebar .categoryHead{
  min-width: unset !important;
}
.insights .sidebar .total-spend-head {
  min-width: 170px !important;
}

/* insights data CSS */
.insights-data-inside {
  padding: 15px 30px;
}
.insights-data .title {
  display: flex;
  align-items: center;
  gap: 32px;
  font-size: var(--fontSize14);
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.20000000298023224px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.insights-data .title .logo-name {
  display: flex;
  align-items: center;
  position: relative;
  top: 2px;
  gap: 10px;
}
.insights-data header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.insights-data .head {
  margin-bottom: 20px;
}
.insights-data header ul {
  list-style: none;
  display: flex;
  gap: 6px;
  width: 100%;
}
.insights-data header ul li {
  border: 1px solid var(--color1);
  padding: 10px 7px;
  font-size: var(--fontSize14);
  font-weight: 700;
  line-height: 19px;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 160px;
  text-align: center;
  gap: 4px;
}
.insights-data header ul li:hover {
  background: rgb(33 202 231 /0.1);
}
.insights-data header span {
  font-size: var(--fontSize18);
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
}
.insights-data header ul .active {
  background: var(--color1) !important;
}
.insights .transactionTable {
  background: white;
  margin-top: 0 !important;
}
/* Location Chart  */

.chart-container {
  border: 2px solid rgb(189 189 189/0.25);
  padding: 15px;
  justify-content: center;
  width: 100%;
  height: 366px;
  position: relative;
  border-radius: 4px;
  background: white;
}
.chartContainerCover{
  min-height: 250px;
  flex-grow: 1;
  position: relative;
}
.location-chart{
  height: min-content !important;
  flex-grow: unset !important;
}
.pop-chart {
  position: absolute;
}

.insights-data .by-location .chart-container {
  height: 100%;
}
.merchant-data .inside-scroller,
.insights-data .inside-scroller {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  height: 99%;
  width: 100%;
}
.merchant-data .chart-container {
  flex-grow: 1;
  height: 100%;
}
.merchant-data .category-chart {
  height: unset;
}
.chart-container .color-info {
  display: flex;
  list-style: none;
  font-size: var(--fontSize12);
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  gap: 75px;
  position: absolute;
  bottom: 6.5em;
  left: 50%;
  transform: translate(-50%, 60%);
  white-space: nowrap;
}
.chart-container .color-info li {
  position: relative;
}
.chart-container .color-info li::before {
  content: "";
  position: absolute;
  height: 14px;
  width: 14px;
  background: #45dcbe;
  top: 1px;
  left: -20px;
  border-radius: 2px;
}
.chart-container .color-info .max::before {
  background: var(--color14);
}
.chart-container .color-info .my::before {
  background: var(--color1);
}
.chart-container .color-info .avg::before{
  background: grey;
}
.chart-container .color-info .mdn::before{
  background: var(--color24);
}
.location-chart {
  position: relative;
  display: flex;
  flex-direction: column;
}
.location-chart .color-info {
  justify-content: center;
  position: unset;
  top: unset;
  transform: unset;
  gap: 30px;
  padding-left: 25px;
  flex-wrap: wrap;
}
.location-chart .arrow_box {
  padding: 5px 10px;
}
.location-chart .arrow_box .min-max {
  font-weight: 700;
}
.location-chart .arrow_box .min-max {
  display: flex;
  gap: 10px;
}
.location-chart .arrow_box .data {
  position: relative;
  right: 5px;
  top: 0.5px;
}
.location-chart .arrow_box .min {
  color: #41d9c1;
}
.location-chart .arrow_box .max {
  color: #c85669;
}
.chart-container > span {
  font-size: var(--fontSize18);
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  position: absolute;
  width: 90%;
  left: 5%;
  top: 20px;
}
.chart-container label {
  font-size: var(--fontSize12);
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  width: 100%;
  position: absolute;
  transform: translateY(-25px);
}

.location-chart foreignObject {
  position: relative;
}
.location-chart .splide__list {
  display: flex;
}
.location-chart .splide__slide > div {
  display: flex;
  justify-content: center;
}
.splide__arrow svg {
  fill: var(--color1) !important;
}
.splide__track {
  padding: 0 !important;
}
.splide__pagination__page.is-active,
.splide__pagination__page.is-active:hover {
  background: var(--color1) !important;
}
.location-chart .apexcharts-legend {
  width: 100%;
}
/* population chart  */

.apexcharts-legend-text {
  font-weight: 700 !important;
}

.chart-container .chart-title {
  font-size: var(--fontSize18);
  font-weight: 800;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
}

/* insight merchant table  */

.insights-data {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
.merchant-data {
  display: flex;
  flex-direction: column;
  position: relative;
}
.scrollable-content {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
}

.customThumb {
  background-color: var(--color1);
  height: 27px;
  width: 27px;
}

.customRail {
  background-color: var(--color1);
}

.customThumbValue {
  color: white;
  transform: translate(-50%);
  font-size: var(--fontSize9);
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0.17px;
  text-align: center;
  pointer-events: none;
}

.insightTable {
  position: relative;
  background: white;
  flex-grow: 1;
}
.insightTable .cell,
.insightTable .MuiDataGrid-columnHeaderTitle {
  font-size: 14px !important;
}
.insightTable .MuiDataGrid-cell {
  flex-grow: 1;
  font-size: var(--fontSize16);
  padding-top: 20px;
  padding-bottom: 20px;
}
.insightTable .id-cell {
  padding-left: 20px;
  font-weight: 600;
}
.insightTable .merchent-cell {
  font-weight: 700;
}
.insightTable .spend-cell {
  font-weight: 600;
}
/* .insightTable .head-cell {
  padding-left: 20px;
} */
.insightTable .MuiDataGrid-columnHeaderTitle {
  font-size: var(--fontSize16);
}

/* mobile merchant table  */
.mobMerchantRow {
  font-size: var(--fontSize16);
  font-weight: 700;
  margin-bottom: 8px;
  border: 1px solid #e0e0e0;
}
.mobMerchantHeaders {
  display: flex;
  align-items: center;
  min-height: 55px;
  background: #f9f9f9;
  padding: 10px 11px;
}
.mobMerchantHeaders svg {
  margin-right: 6px;
}
.mobMerchantHeaders .Svg {
  transform: rotate(180deg);
}
.mobMerchantHeaders .activeSvg {
  transform: unset;
}
.mobMerchantHeaders .name {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: var(--fontSize14);
  line-height: 22px;
  letter-spacing: 0.2px;
  margin-left: 23px;
  flex-grow: 1;
}
.mobMerchantHeaders .amount {
  white-space: nowrap;
  margin-left: 5px;
  text-align: right;
  font-size: var(--fontSize15);
  font-weight: 600;
  white-space: nowrap;
}
.mobMerchantInside {
  height: 134px;
  display: flex;
  flex-direction: column;
  padding-left: 11px;
  padding-right: 11px;
  border-top: 0.5px solid #dadada;
}
.mobMerchantInside .spendings {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
}
.mobMerchantInside .reviews {
  padding-bottom: 22px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
}
.mobMerchantInside .commentsLogo {
  position: relative;
  top: 4px;
}
.pop-chart .apexcharts-inner .apexcharts-xaxis-tick:nth-of-type(1) {
  opacity: 0 !important;
}
.pop-chart .chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
.pop-chart .chart-header .close-btn {
  cursor: pointer;
  margin-left: 30px;
  position: relative;
  top: 2px;
}
.pop-chart .chart-header .close-btn:active {
  transform: scale(0.95);
}
.pop-chart .header-text {
  font-weight: 800;
  font-size: 12px;
  margin-right: 20px;
}
.pop-chart .chart-header .checkboxes-cover {
  display: flex;
  align-items: center;
}
.pop-chart .checkboxes,
.pop-chart .checkboxes .box {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
}
.pop-chart .checkboxes {
  gap: 20px;
}
.pop-chart .checkboxes .box {
  gap: 5px;
}
.pop-chart .checkboxes label {
  transform: unset;
  position: unset;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  border: 1px solid black;
  cursor: pointer;
}
.pop-chart .checkboxes input:checked + label {
  background-color: #21cae7;
  border: 1px solid #21cae7;
}

.pop-chart .apexcharts-tooltip{
  /* font-weight: 700; */
  font-size: 12px;
  padding: 6px 10px;
  transform: translateY(25px);
}

.merchant-data .review-and-graph {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
  width: 100%;
  height: 100%;
}
.merchant-data .user-review-box {
  box-shadow: none;
  border: 2px solid rgb(189 189 189/0.25);
  border-radius: 5px;
  overflow-y: auto;
  max-height: unset;
  flex-grow: 1;
  margin: 0;
  /* position: absolute;
  height: 100%; */
  z-index: 1;
}
.merchant-data .user-review-box .nothing {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.merchant-data .all-review-title {
  font-size: 18px;
}
.insights-data
  .mobTransTable
  .infinite-scroll-component
  .mobTransTableRow:first-child {
  margin-top: 0;
}
.merchant-data .mobTransTableInside {
  background: white;
}
.merchant-data .mobTransTableHeaders .custodian {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.merchant-data .mobTransTableHeaders .amount-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}
.merchant-data .mobTransTableHeaders .account-number {
  font-weight: 400;
  font-size: 13px;
}
/* responsive  */
@media screen and (min-width: 450px) {
  .scroller::-webkit-scrollbar {
    height: 9px;
    width: 9px;
  }
  .scroller::-webkit-scrollbar-thumb {
    background: rgb(200 200 200);
  }
}
@media screen and (max-width: 900px) {
  .merchant-data .scroller {
    height: unset;
    width: 100%;
  }
  .insights .transactionTable {
    margin-top: 10px;
  }
  .insights-data .category-chart .apexcharts-data-labels {
    opacity: 0;
  }
  .insights-data-inside {
    padding: 0;
    border: none;
  }
  .insights-data .inside-scroller .mobInsightsTable {
    margin-top: 0 !important;
  }
  .merchant-data .inside-scroller,
  .insights-data .inside-scroller {
    gap: 8px;
  }
  .insights-data .head {
    margin: 0;
  }
  .merchant-data {
    padding: 0;
    border: none;
  }
  .insights-category .chart-container {
    background: white;
  }
  .mobMerchantRow {
    background: white;
  }
  .insights .sidebar .amount-cell {
    padding-right: 30px;
  }
  .insights {
    padding: 0;
  }
  .insightsBg {
    left: -24px;
    top: 141px;
  }
  .insights-data {
    background: unset;
  }
  .insights-data .scrollable-content {
    background: white;
    border-radius: 4px;
  }
  .insights-data header span {
    display: none;
  }
  .insights > .row2 {
    height: calc(100vh - 75px);
    flex-direction: column;
  }
  .scrollable-content {
    padding-right: 0;
  }
  .insights .sidebar-cover {
    /* display: grid; */
    gap: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .insights .sidebar-cover {
    width: 100% !important;
    height: calc(100vh - 110px);
  }
  .insights-data {
    border: none;
    box-shadow: none;
    padding-left: 20px;
    padding-right: 20px;
  }
  .insights .sidebar .MuiDataGrid-root {
    border-top: 0;
  }
  .insights .sidebar .MuiDataGrid-columnHeaders {
    border-top: none;
  }
  .insights .sidebar .category-list-header {
    position: relative;
  }
  .insights .sidebar .category-list-header::before {
    content: "";
    position: absolute;
    width: 96%;
    left: 2%;
    bottom: 0;
    height: 1px;
    background: var(--color12);
  }
  .scrollable-content {
    height: calc(100vh - 210px);
  }
  .sidebar .DropdownFilter .head-filter {
    padding: 0;
    height: auto;
    position: absolute;
    right: 0;
    opacity: 0;
  }
  .sidebar .DropdownFilter .head-filter p,
  .sidebar .DropdownFilter .head-filter h1 {
    display: none;
  }
  .sidebar .DropdownFilter .radio-list {
    top: 30px;
    right: 15px;
    white-space: nowrap;
  }
  .sidebar .filter-cover {
    position: relative;
    display: flex;
    cursor: pointer;
  }
  .sidebar .selection-areas {
    display: unset;
    margin: 0;
  }
  .insights .filters {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 311px;
    height: 581px;
    max-width: 100%;
    max-height: 100%;
    background: white;
    z-index: 12;
    padding: 25px;
    gap: 0;
  }

  .insights .filters > * {
    margin-bottom: 23px;
  }
  .insights .filters header {
    font-size: var(--fontSize24);
    font-weight: 800;
    line-height: 33px;
    letter-spacing: 0px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /* .insights .filters button {
    height: 55px;
    border-radius: 5px;
    background: var(--color1);
    outline: none;
    border: none;

    font-size: var(--fontSize14);
    font-weight: 800;
    line-height: 19px;
    letter-spacing: 0px;
    text-align: center;
    cursor: pointer;
    width: 100%;
  } */
  .insights .filters .close-icon {
    cursor: pointer;
  }

  .review-box {
    padding: 20px 10px;
    gap: 7px;
  }
  .review-box .review-header {
    flex-direction: column;
    gap: 8px !important;
  }
  .review-box .title {
    margin: 0;
  }
  .merchant-data .user-review-box {
    overflow-y: unset;
    border-radius: 10px;
    /* border: none;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1),
      0px 21px 46px 0px rgba(0, 0, 0, 0.1),
      0px 83px 83px 0px rgba(0, 0, 0, 0.09),
      0px 187px 112px 0px rgba(0, 0, 0, 0.05),
      0px 333px 133px 0px rgba(0, 0, 0, 0.01),
      0px 520px 146px 0px rgba(0, 0, 0, 0); */
  }
  .review-box ul {
    flex-direction: column;
    width: 100%;
    gap: 4px !important;
  }
  .review-box ul li {
    flex-direction: row !important;
    padding: 5px 8px !important;
  }
}
@media screen and (max-height: 1000px) {
  .location-chart .apexcharts-legend-series {
    height: 17px;
  }
}
@media screen and (max-width: 750px) {
  .insights .sidebar .MuiDataGrid-columnHeadersInner > div[role="row"] {
    padding-right: 20px;
  }
  .insights-data header ul li {
    max-width: 120px;
    padding: 5px 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 16px;
  }
  .insights-data header ul li .pc {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .mobMerchantInside .spendings {
    display: flex;
    justify-content: space-around;
  }
  .mobMerchantRow .slider {
    width: calc(100vw - 250px) !important;
  }
  .insights-data header ul li {
    padding: 5px;
    font-size: 12px;
    font-weight: 800;
  }
}
@media screen and (min-width: 900px) {
  .insights .sidebar .amount-cell .percentage svg {
    display: none;
  }
  .insights .header-options-mob,
  .insights-data .mobile,
  .insights .sidebar header {
    display: none;
  }
  .insights .sidebar .Mui-selected {
    border: 1px solid var(--color1);
    background-color: white !important;
    border-radius: 9px 0 0 9px;
    position: relative;
  }

  .insights .sidebar .Mui-selected::before {
    content: "";
    position: absolute;
    width: 9px;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 10;
    border-radius: 9px 0 0 9px;
    background: var(--color1);
  }
}

@media screen and (max-width: 440px) {
  .location-chart .splide__track,
  .location-chart {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
@media screen and (max-width: 500px) {
  .chart-container .color-info {
    gap: 10px 40px;
  }
}
@media screen and (max-width: 350px) {
  .splide-custom {
    max-width: 108% !important;
  }
}

/* Merchant CSS  */

.review-box {
  border: 2px solid rgb(189 189 189/0.25);
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: white;
  /* width: 269px; */
  border-radius: 5px;
  white-space: nowrap;
}
.review-box .title {
  font-size: var(--fontSize18);
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}
.review-box .overall-rating {
  font-size: var(--fontSize14);
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
}
.review-box .rating-count {
  color: rgba(0, 0, 0, 0.6);
}
.review-box .total-rating {
  font-size: var(--fontSize14);
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: var(--colorBlack7);
}
.review-box .rating-count {
  font-family: var(--fontThird);
  font-size: var(--fontSize14);
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17000000178813934px;
  text-align: left;
}
.overall-rating-cover {
  display: flex;
  align-items: center;
  gap: 8px;
}
.review-box .review-header {
  display: flex;
  gap: 25px;
  align-items: center;
}
.review-box .star-count {
  font-family: var(--fontThird);
  font-size: var(--fontSize14);
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17000000178813934px;
  text-align: left;
  color: var(--color17);
}
.review-box .star-bar {
  position: relative;
  height: 8px;
  width: 100%;
  border-radius: 2px;
  background: rgba(189, 189, 189, 0.25);
  overflow: hidden;
}
.review-box .star-bar .fill {
  background: rgba(255, 180, 0);
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}
.review-box ul {
  list-style: none;
  display: flex;
  gap: 25px;
}
.review-box ul li {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid transparent;
}
.review-box ul .active {
  /* background: rgb(0 0 0/0.05); */
  border: 1px solid #21cae7;
}
.merchant-data .scrollable-content {
  height: calc(100% - 60px);
}
.merchant-sidebar .custom-div .rating {
  display: flex;
  align-items: center;
  gap: 4px;
}
.merchant-sidebar .custom-div .rating .rating-count {
  font-size: var(--fontSize16);
  position: relative;
  top: 2px;
}
.rating-chart {
  width: calc(100% - 200px);
}
.rating-chart .apexcharts-legend {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rating-chart .apexcharts-legend-text {
  font-weight: 800 !important;
}
.rating-chart .apexcharts-legend-series .apexcharts-legend-text {
  display: none;
}
.rating-chart .apexcharts-legend-series[rel="1"]::after {
  content: "Overall Rating trend, Month Over Month";
  color: rgb(55, 61, 63);
  font-weight: 800;
  font-size: var(--fontSize14);
  text-align: center;
}
.rating-chart .apexcharts-legend-series[rel="2"]::after {
  content: "Month Over Month Customer Repeatability trend";
  color: rgb(55, 61, 63);
  font-weight: 800;
  font-size: var(--fontSize14);
  text-align: center;
}

@media screen and (max-width: 1120px) {
  .rating-chart {
    /* margin-bottom: 20px; */
    height: auto;
  }
  .review-and-graph {
    flex-direction: column;
  }
  .review-and-graph .review-box {
    width: 100%;
    align-items: center;
  }
  .insights-data .scrollable-content {
    background: unset;
  }
  .merchant-data .chart-container,
  .merchant-data .review-box {
    background: white;
  }
  .rating-chart {
    width: 100%;
  }
}
@media screen and (min-width: 1120px) {
  .rating-chart {
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width: 1280px) {
  .pop-chart .chart-header {
    display: grid;
    gap: 5px;
  }
  .pop-chart .chart-header .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
@media screen and (max-width: 1000px) {
  .pop-chart .chart-header {
    padding: 0;
  }
  .pop-chart .checkboxes {
    gap: 7px;
  }
  .pop-chart .checkboxes label {
    height: 14px;
    width: 14px;
  }
}
