#searchInput-Filters {
  display: block;
  width: 90%;
  padding: 5px 8px;
  margin: 0 auto;
  border-radius: 5px;
  border: 1px solid rgb(0 0 0/0.3);
  margin-bottom: 5px;
}

.applyBtn-Filters-Cover {
  background: white;
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  margin: 0 auto;
}
.applyBtn-Filters {
  width: 80%;
  margin: 0 auto;
  padding: 10px;
  border-radius: 5px;
  color: black;
  background: var(--color1);
  border: none;
  display: block;
  margin-top: 15px;
  cursor: pointer;
  font-weight: 700;
}

.applyBtn-Filters:disabled {
  background: gray;
  color: white;
  cursor: not-allowed;
}

.categoryFilter-menuLabel,
.categoryFilter-allLabel {
  width: 100%;
  cursor: pointer;
  font-weight: 700;
  user-select: none;
}

.categoryFilter-menuLabel .dropdown {
  margin-left: 10px;
  margin-bottom: 1.5px;
  transform: rotate(-90deg);
}

.categoryFilter-menuLabel .dropdown-open {
  transform: unset;
}
.selection-areas .row .DropdownFilter {
  min-width: 250px;
}
.filters .row {
  display: grid;
  grid-template-columns: max-content 1fr 1fr 1fr;
}

.filtersMerchant .row,
.filtersCategory .row {
  grid-template-columns: max-content 1fr;
}
.filterDropdown {
  position: relative;
  width: 100%;
}
.filterDropdown .label {
  position: absolute;
  color: rgba(0, 0, 0, 0.6);
  line-height: 1.4375em;
  letter-spacing: 0.00938em;
  background: white;
  font-size: 12px;
  padding: 0 6px;
  top: 0;
  transform: translateY(-50%);
  left: 10px;
}
.filterDropdown .MuiAutocomplete-root {
  width: 100%;
  border: 1px solid rgb(0 0 0/0.23);
  border-radius: 4px;
  padding-top: 8px;
  padding-bottom: 4px;
}
.filterDropdown .MuiAutocomplete-root:hover {
  border: 1px solid #000;
}
.filterDropdown > .Mui-focused {
  border: 1px solid var(--color21) !important;
}
.filterFocused .label {
  color: var(--color21);
}

.filterDropdown .MuiInputBase-root {
  min-height: 47px;
  max-height: 86px;
  overflow-y: auto;
  position: unset;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.filterDropdown .MuiOutlinedInput-notchedOutline {
  border: none;
}

.filterDropdown > .Mui-focused .MuiFormLabel-root {
  display: none;
}

.filterDropdown .MuiAutocomplete-endAdornment {
  padding-bottom: 8px;
}

.chart-label {
  font-weight: 800;
  font-size: 18px;
}

/* tooltip css  */

.custom-MuiChartsTooltip-root{
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  margin-left: 16px;
  margin-right: 16px;
}
.custom-MuiChartsTooltip-root .MuiChartsTooltip-cell{
  padding: 8px 16px;
  border-bottom: 1px solid rgb(0 0 0/0.12);
  color: rgb(0 0 0/0.6);
  vertical-align: middle;
  font-weight: 500;
}

.custom-MuiChartsTooltip-root .MuiChartsTooltip-row .MuiChartsTooltip-labelCell{
  padding-left: 8px;
}

.custom-MuiChartsTooltip-root .MuiChartsTooltip-row th{
  text-align: start;
}

.custom-MuiChartsTooltip-root .MuiChartsTooltip-row .MuiChartsTooltip-valueCell{
  padding-left: 32px;
}

.custom-MuiChartsTooltip-root .MuiChartsTooltip-mark {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-color: #fff;
  border: solid #fff 2px;
  box-sizing: content-box;
}

.custom-MuiChartsTooltip-root .MuiChartsTooltip-row td:first-child{
  padding-left: 16px !important;
}
.custom-MuiChartsTooltip-root .MuiChartsTooltip-row td:last-child{
  padding-right: 16px !important;
}

.custom-MuiChartsTooltip-root .MuiChartsTooltip-row:first-child td{
  padding-top: 8px !important;
}
.custom-MuiChartsTooltip-root .MuiChartsTooltip-row:last-child td{
  padding-bottom: 8px !important;
}

.kmnModal {
  display: grid;
  grid-template-rows: min-content minmax(100px,1fr) min-content;
  position: fixed;
  max-width: 90%;
  max-height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 100;
  border-radius: 4px;
  overflow: hidden;
}

.kmnModal .error{
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 20px;
  color: var(--colorWarning);
  font-size: 14px;
}

.kmnModal .error svg{
  font-size: 16px;
}

.kmnModal .head{
  display: grid;
  place-items: center;
  min-height: 64px;
  padding: 16px 10px;
  background: var(--color1);
  position: relative;
  text-align: center;
}
.kmnModal .head .closeIcon{
  position: absolute;
  top: 50%;
  transform: translateY(-80%);
  right: 20px;
  cursor: pointer;
}
.kmnModal .head .title{
  font-weight: 700;
  font-size: 16px;
}
.kmnModal .bodyWrapper{
  position: relative;
}
.kmnModal .body{
  position: relative;
  padding: 20px;
  overflow-y: auto;
  height: 100%;
}
.kmnModal .footer{
  display: flex;
  justify-content: center;
  padding: 20px;
}
.kmnModal .footer .btns{
  justify-content: center;
  display: flex;
  gap: 10px;
}


/* profile css  */

.deleteProfileModal {
  display: flex;
  flex-direction: column;
}
.deleteProfileModal .body {
  padding-bottom: 0;
  height: min-content;
}
.deleteProfileModal .message {
  text-align: center;
  color: var(--colorWarning);
  font-weight: 600;
}
.deleteProfileModal .errorIcon {
  font-size: 18px;
  margin-right: 3px;
  position: relative;
  top: 2px;
}

.editProfileForm .btnsCover{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
}

.editProfileForm .btnsCover .btn {
  width: unset !important;
  font-size: 16px !important; 
  padding: 16px 20px !important;
  height: unset !important;
  text-transform: capitalize !important;
  min-width: 120px;
}

/* data grid  */

.dataGrid .filterIcon {
  height: 24px;
  width: 24px;
  opacity: 0.5;
  margin-top: 6px;
  border-radius: 50%;
  padding: 4px;
}

.dataGrid .filterIcon:hover{ 
  background: rgb(0 0 0/0.1);
  transition: 0.2s ease-in-out;
}

@media screen and (max-width: 900px) {
  .filters .row {
    display: flex;
    gap: 20px;
  }
  .selection-areas .row .DropdownFilter {
    min-width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .deleteProfileModal .body {
    padding-top: 10px;
  }
  .deleteProfileModal .head .closeIcon {
    right: 10px;
    font-size: 18px;
  }
  .kmnModal .head{
    padding: 10px;
    min-height: 50px;
  }
  .kmnModal .head .title{
    font-size: 12px;
  }
  .kmnModal .footer{
    padding: 12px;
  }
  .deleteProfileModal .message {
    text-align: center;
    color: var(--colorWarning);
    font-weight: 600;
    font-size: 14px;
  }

  .deleteProfileModal .title, .deleteProfileModal button {
    font-size: 14px !important;
  }

  .deleteProfileModal .message .errorIcon{
    font-size: 14px;
  }
}

@media screen and (max-width: 768px) {
  .chart-header {
    padding: 0 15px;
  }
}


@media screen and (max-width: 600px) {
  .editProfileForm .btnsCover {
    flex-direction: column-reverse;
    gap: 10px;
  }
  .editProfileForm .btnsCover .btn {
    width: 100% !important;
  }
}