:root {
  --fontMain: "Nunito";
  --fontSecond: "Nunito Sans";
  --fontThird: "Roboto";

  --colorBlack: #000000;
  --colorBlack2: #1d293f;
  --colorBlack3: #7c8087;
  --colorBlack4: #282c34;
  --colorBlack5: #626262;
  --colorBlack6: #212121;
  --colorBlack7: #9e9e9e;
  --colorBlack8: #00000099;
  --colorBlack9: #979797;
  --colorBlack9o45: rgba(151, 151, 151, 0.45);

  --colorWhite: #fff;
  --colorWhite2: #f7f8fa;
  --colorWhite3: #e2e4e8;
  --colorWhite4: #f9f9f9;
  --colorWhite5: #dadada;
  --colorWhite6: #D9D9D9;
  --colorWhite7: #DCDCDC;

  --color1: #21cae7;
  --color2: #a4379e;
  --color3: #42dac1;
  --color4: #f9cd62;
  --color5: #37def5;
  --color6: #acdbff;
  --color7: #8a55ad;
  --color8: #ab2f9a;
  --color9: #1f2fba;
  --color10: #61dafb;
  --color11: #0c89ef;
  --color12: #d8d8d8;
  --color12o65: rgba(216, 216, 216, 0.65);
  --color12o25: #f5f5f5;
  --color13: #a4379e;
  --color14: #c95266;
  --color15: #8c97ac;
  --color16: #c4c4c4;
  --color17: #666666;
  --color18: #31b4de;
  --color19: #fbc548;
  --color20: #ffd166;
  --color21: #1976d2;
  --color22: #25CD2540;
  --color23: #02b2af;
  --color24: #2E96FF;
  --colorSelected: #21cae730;
  --colorWarning: #FD4C5C;


  --fontSize70: 70px;
  --fontSize58: 58px;
  --fontSize48: 48px;
  --fontSize40: 40px;
  --fontSize30: 30px;
  --fontSize24: 24px;
  --fontSize21: 21px;
  --fontSize20: 20px;
  --fontSize18: 18px;
  --fontSize16: 16px;
  --fontSize15: 15px;
  --fontSize14: 14px;
  --fontSize13: 13px;
  --fontSize12: 12px;
  --fontSize11: 11px;
  --fontSize10: 10px;
  --fontSize9: 9px;
  --fontSize6: 6px;
  --fontSize8: 8px;
  --fontSize1: 1px;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;500;600;700;800;900;1000&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.background-bg {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1;
}

/* Mui Progress Bar  */

.MuiLinearProgress-root {
  height: 6px;
  border-radius: 4px;
  background-color: rgb(0 0 0/0.15);
  z-index: 3;
}

.MuiLinearProgress-bar {
  background-color: var(--color1);
}

.apexcharts-canvas {
  overflow-y: hidden;
}
