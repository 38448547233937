* {
  font-family: var(--fontMain);
}
html,
body,
#root {
  width: 100%;
}

.transactionbg {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.transaction-body-wrapper-area {
  padding: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
}
.transaction-body-inner-area {
  position: relative;
  display: flex;
  align-items: flex-start;
  height: calc(100% - 6px);
}
.transaction-body-inner-area > div:last-child {
  width: 100%;
}
.account-list {
  width: 25%;
  min-width: 350px;
  background: var(--colorWhite);
  border: 1px solid rgba(189, 189, 189, 0.25);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.account-list[hidden] {
  display: none !important;
}

.link-account-button {
  width: 100%;
  height: 57px;
  min-height: 57px;
  background: var(--color1);
  border-radius: 5px;
  border-radius: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;

  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize16);
  line-height: 22px;
  text-align: center;
  color: var(--colorBlack);
  cursor: pointer;
  width: 90%;
  margin: 0rem auto;
  border-radius: 5px;
}
.link-account-button img {
  margin-right: 10px;
  cursor: pointer;
}

.calculate-header p {
  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize14);
  line-height: 22px;
  display: flex;
  align-items: flex-end;
  text-transform: uppercase;
  color: var(--colorBlack);
}
.calculate-header {
  justify-content: space-between;
  width: 90%;
  margin: 0rem auto;
}
.calculate-header > div,
.calculate-header {
  display: flex;
  align-items: center;
}
.calculate-header > div > img {
  margin-left: 10px;
}

.CheckboxCustom {
  margin-right: 10px;
}
.CheckboxCustom input {
  display: none;
}
.CheckboxCustom label {
  width: 12px;
  height: 12px;
  border: 0.8px solid #d1d1d1;
  border-radius: 1px;
  display: flex;
  align-items: center;

  justify-content: center;
}
.CheckboxCustom label img {
  display: none;
  color: var(--colorWhite);
}
.CheckboxCustom input:checked + label {
  background: var(--color1);
  border-color: transparent;
}

.CheckboxCustom input:checked + label img {
  display: block;
  width: 10px;
}

.transactionArrow {
  position: absolute;
  right: -5px;
  bottom: 70px;
  cursor: pointer;
}

.account-list.active {
  width: 90px;
  min-width: 90px;
}

.account-list.minus-sign .calculate-header .remove-icon {
  display: block;
}
.account-list.minus-sign .calculate-header .done-icon {
  display: none;
}
.DropdownLeftSide.minus-sign .dropdownHead .remove-icon {
  display: block;
}
.DropdownLeftSide.minus-sign .dropdownHead .done-icon {
  display: none;
}
.CheckboxCustom .remove-icon {
  display: none;
}

.account-list .short-account-list {
  display: none;
}
.account-list.active > *:not(.short-account-list) {
  display: none;
}
.account-list.active > .short-account-list {
  display: block;
}
.account-list.active > .transactionArrow {
  display: block;
  transform: rotate(180deg);
}
.account-list .headerActions {
  display: flex;
  padding: 0 10px;
  margin-top: 15px;
  margin-bottom: 20px;
  gap: 8px;
}
.account-list .headerActions .addManualAccountBtn{
  width: 100px !important;
}
.link-account-button-short {
  height: 55px;
  width: 55px;
  background: var(--color1);
  border-radius: 5px;
  margin-bottom: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.link-account-button-short img {
  margin-right: 0px;
  height: 23.98966407775879px;
  width: 23.98966407775879px;
}

.linking-other-account {
  width: 100%;
  background: var(--colorWhite4);
  border: 0.5px solid var(--colorWhite4);
  height: 75px;
  margin-bottom: 5px;
  outline: none;
}
.DropdownLeftSide {
  width: 100%;
  margin-bottom: 4px;
}
.DropdownLeftSide .closed h1,
.DropdownLeftSide .closed p,
.DropdownLeftSide .closed {
  font-style: italic !important;
  opacity: 0.8;
}
.DropdownLeftSide .dropdownHead {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0px 25px;
  background: var(--colorWhite4);
  border: 0.5px solid var(--colorWhite4);
  cursor: pointer;
}
.DropdownLeftSide .dropdownHead > img:first-child {
  margin-right: 14px;
}
.DropdownLeftSide .dropdownHead h1 {
  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize14);
  line-height: 24px;
  letter-spacing: 0.2px;
  color: var(--colorBlack);
  flex: 1;
  text-transform: uppercase;
  margin-left: 14px;
}
.DropdownLeftSide .dropdownHead p {
  font-style: normal;
  font-weight: 800;
  font-size: var(--fontSize14);
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.2px;
  color: var(--colorBlack);
}
.DropdownLeftSide .EachListWrapper {
  padding-right: 2rem;
  display: none;
}
.DropdownLeftSide .EachListWrapper.active {
  display: block;
}
/* dropdown-cal-innner */
.dropdown-cal-wrapper {
  overflow: auto;
  margin-top: 1rem;
  /* height: 100%; */
  flex-grow: 1;
}

.dropdown-cal-innner {
  width: 88%;
  position: relative;
  margin-left: auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 0.63px solid #e0e0e0;
  display: flex;
  align-items: flex-start;
}
.dropdown-cal-innner .edit-icon {
  height: 14px;
  cursor: pointer;
  position: relative;
  left: -5px;
  margin-top: 10px;
}
.dropdown-cal-innner:hover .edit-icon {
  display: block;
}
.dropdown-cal-innner > div:first-child {
  transform: translateY(3px);
}
.dropdown-cal-innner .right-side {
  display: flex;
  align-items: center;
  margin-left: 5px;
  flex: 1;
  justify-content: space-between;
}
.dropdown-cal-innner .right-side-cover {
  flex-grow: 1;
}
.dropdown-cal-innner .right-side-cover .closed-text {
  font-size: 14px;
  margin-left: 4px;
}
.dropdown-cal-innner .first-area h1 {
  font-style: normal;
  font-weight: 600;
  font-size: var(--fontSize14);
  line-height: 22px;

  color: var(--colorBlack);
}
.dropdown-cal-innner .first-area p {
  font-style: normal;
  font-weight: 400;
  font-size: var(--fontSize14);
  line-height: 19px;
  color: var(--colorBlack);
}
.dropdown-cal-innner .second-area h1 {
  font-style: normal;
  font-weight: 600;
  font-size: var(--fontSize14);
  line-height: 22px;
  white-space: nowrap;
  text-align: right;
  color: var(--colorBlack);
}
.dropdown-cal-innner .second-area p {
  font-style: normal;
  font-weight: 400;
  font-size: var(--fontSize14);
  line-height: 19px;
  white-space: nowrap;
  text-align: right;
  color: var(--colorBlack);
}
.transaction-page .total {
  border: 1px solid var(--color12);
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;

  font-size: var(--fontSize16);
  font-weight: 700;
  line-height: 24px;
}

/* transaction-right-side */
.transaction-right-cover {
  flex-grow: 1;
  height: 100%;
  margin-left: 1rem;
}
.accounts-navigation {
  display: flex;
  gap: 2px;
}
.accounts-navigation .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 40px;
  border-radius: 5px 5px 0px 0px;
  border: 1px solid #ccc;
  background: #fff;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}
.accounts-navigation .active {
  border: 1px solid var(--color1);
  background: var(--color1);
}

.transaction-right-side {
  background: var(--colorWhite);
  border: 1px solid rgba(189, 189, 189, 0.25);
  box-shadow: 0px 4.71062px 15px rgba(0, 0, 0, 0.07);
  border-radius: 4.71062px;
  border-top-left-radius: 0;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  position: relative;
  z-index: 2;
  height: calc(100% - 40px);
}
.transaction-right-side .top-area {
  justify-content: space-between;
  margin-bottom: 15px;
}
.transaction-right-side .top-area,
.transaction-right-side .top-area .right-top-area,
.transaction-right-side .top-area .right-top-area .box-round {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  white-space: nowrap;
}
.transaction-right-side .top-area .right-top-area .box-round p {
  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize16);
  line-height: 25px;
  color: var(--colorBlack);
}
.transaction-right-side .top-area .right-top-area > * + * {
  margin-left: 43px;
}
.transaction-right-side .top-area .right-top-area .box-round span {
  height: 13.407984733581543px;
  width: 14.424521446228027px;

  display: block;
  border-radius: 0px;
  margin-right: 15px;
}
.transaction-right-side .top-area .right-top-area .box-round .blue-box {
  background-color: #31b4de;
}
.transaction-right-side .top-area .right-top-area .box-round .red-box {
  background-color: var(--color14);
}
.transaction-right-side .top-area .right-top-area .box-round .yellow-box {
  background-color: #fbc548;
}
.transaction-right-side .top-area > h1 {
  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize16);
  line-height: 25px;
  color: var(--colorBlack);
}

/* selection-areas */
.selection-areas {
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */
  display: flex;
  grid-gap: 10px;
  margin-bottom: 1rem;
  height: max-content;
}
.filters header img {
  cursor: pointer;
}

.selection-areas .row .DropdownFilter {
  width: 240px;
}

/* DropdownFilter */
.DropdownFilter {
  position: relative;
}
.DropdownFilter .head-filter {
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  height: 60px;
  padding: 0px 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
  z-index: 2;
}
.DropdownFilter .head-filter h1 {
  font-style: normal;
  font-weight: 400;
  font-size: var(--fontSize16);
  line-height: 24px;
  letter-spacing: 0.15px;
  color: var(--colorBlack);
}
/* .DropdownFilter .remove-icon-material,
.DropdownFilter .minus-sign .remove-icon-material {
  display: none;
}
.DropdownFilter .minus-sign .remove-icon-material {
  display: block;
} */
.DropdownFilter .head-filter p {
  font-style: normal;
  font-weight: 400;
  font-size: var(--fontSize12);
  line-height: 12px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.6);
  position: absolute;
  background-color: var(--colorWhite);
  top: -6px;
  left: 10px;
  padding: 0px 5px;
}
.DropdownFilter .radio-list {
  list-style: none;
  background: var(--colorWhite);
  box-shadow: 0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  position: absolute;

  min-width: 100%;
  max-height: 300px;
  overflow: auto;
  z-index: 9;
}

.DropdownFilter .radio-list-merchant {
  width: 240px;
}
.DropdownFilter .category-radio-list {
  width: 240px;
}
.DropdownFilter .second-radio-list {
  width: 240px;
}
.DropdownFilter .third-radio-list {
  width: 300px;
}

.DropdownFilter .radio-list input {
  display: none;
}
.DropdownFilter .radio-list #searchInput {
  display: block;
}
.DropdownFilter .radio-list li {
  display: flex;
  align-items: center;
}

.DropdownFilter .radio-list li[hidden] {
  display: none;
}

.DropdownFilter .radio-list .all-list-wrapper input:checked + label + p {
  font-style: normal;
  font-weight: 700;
  font-size: var(--fontSize16);
  line-height: 150%;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
}
.DropdownFilter .radio-list .checkbox-label {
  height: 18px;
  width: 18px;
  min-width: 18px;
  border-radius: 50%;
  border: 1px solid var(--colorBlack8);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  cursor: pointer;
}

.DropdownFilter .radio-label {
  height: 18px;
  width: 18px;
  min-width: 18px;
  border-radius: 50%;
  border: 1px solid var(--colorBlack8);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  cursor: pointer;
}

.DropdownFilter label {
  cursor: pointer;
}
.DropdownFilter .radio-list li {
  padding: 0.4rem 10px;
  min-height: 42px;
}
.DropdownFilter .radio-list li p {
  font-style: normal;
  font-weight: 400;
  font-size: var(--fontSize16);
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #212121;
}
.DropdownFilter .radio-list li .text {
  display: flex;
  cursor: pointer;
}
.DropdownFilter .radio-list li .text p {
  font-weight: 700;
}
.DropdownFilter .radio-list li input:checked + label {
  background-color: var(--color1);
  border-color: transparent;
}
/* .DropdownFilter .radio-list li input:checked + label .done-icon-material {
  display: block !important;
} */

.DropdownFilter .radio-list .checkbox-label {
  height: 12px;
  width: 12px;
  min-width: 12px;
  border-radius: 2px;
  border-color: var(--color16);
}
.DropdownFilter .radio-list .checkbox-label > * {
  font-size: 13px !important;
}
/* Added styles to display the DoneIcon by default */
.DropdownFilter .radio-list li input:not(:checked) + label svg {
  display: block !important;
}
.popup-bigger-amount > * {
  height: 201px;
  width: 421px;
  right: 0px;
  z-index: 9;
  border-radius: 0px;
  box-shadow: 0px 4px 4px 0px var(--colorBlack) 40;
  background: var(--colorWhite);
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.popup-bigger-amount > * .input-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  width: 334px;
  margin: 0rem auto;
}
.popup-bigger-amount > * button {
  font-style: normal;
  font-weight: 800;
  font-size: var(--fontSize14);
  line-height: 19px;
  text-align: center;
  color: var(--color1);
  width: 221px;
  height: 40px;
  border: 2px solid var(--color1);
  border-radius: 5px;
  background: transparent;
  margin: 0rem auto;
  margin-top: 20px;
  display: block;
  cursor: pointer;
}

.popup-bigger-amount .css-z8w5e5-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--color16) !important;
}

.popup-bigger-amount .css-14sbbdo-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: var(--color17) !important;
}
.head-filter .list-head {
  overflow: hidden;
  display: flex;
  margin-right: 8px;
}
.head-filter .list-head > * + * {
  margin-left: 2px;
}
.head-filter .list-head span {
  font-style: normal;
  font-weight: 400;
  font-size: var(--fontSize16);
  height: 28px;
  padding: 0px 4px;
  border-radius: 3px;
  line-height: 24px;
  letter-spacing: 0.15px;
  border: 1px solid var(--colorWhite4);
  color: rgba(0, 0, 0, 0.87);
  background: var(--colorWhite4);
  display: block;
}
.DropdownFilter .radio-list .sub-category-work {
  padding-left: 1.2rem;
}
.DropdownFilter .radio-list .sub-category-work p {
  font-style: normal;
  font-weight: 400;
  font-size: var(--fontSize16);
  line-height: 150%;
}
.DropdownFilter .radio-list li p + img {
  margin-left: 10px;
  cursor: pointer;
  transform: rotate(-90deg);
}
.DropdownFilter .radio-list li p.active + img {
  transform: rotate(0);
}
.DropdownFilter .radio-list li + ul {
  display: none;
}
.DropdownFilter .radio-list li + ul.active {
  display: block;
}
.DropdownFilter .radio-list li .remove-icon-material {
  display: none;
}
.DropdownFilter .radio-list .minus-sign .done-icon-material {
  display: none !important;
}
.DropdownFilter .radio-list .minus-sign .remove-icon-material {
  display: block !important;
}

/* Transaction mobile options  */

.transaction-right-inside {
  display: flex;
  flex-direction: column;
}
.transaction-mob-options-cover {
  padding: 0 20px;
}
.transaction-mob-options {
  display: flex;
  gap: 35px;
  list-style: none;
  margin-bottom: 20px;
}
.transaction-mob-options li {
  position: relative;

  font-size: var(--fontSize16);
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.2758620083332062px;
  text-align: center;
  height: 34px;
  cursor: pointer;
  color: #626262;
}
.transaction-mob-options .active {
  color: var(--color13);
}
.transaction-mob-options .active::before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 6px;
  background-color: var(--color13);
  border-radius: 0 0 6px 6px;
}

/* Transaction Table  */

.transaction-page .scrollable-content {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 20px;
  padding-bottom: 20px;
  position: relative;
}

.transactionTable {
  margin-top: 32px;
}
.transactionTable .pending {
  font-style: italic;
}
.transactionTable .pending * {
  color: gray !important;
  filter: grayscale(1);
}
.transactionTable .MuiDataGrid-cell {
  flex-grow: 1;
  font-size: var(--fontSize14);
  padding-top: 20px;
  padding-bottom: 20px;
  font-weight: 600;
  overflow: unset;
}
.transactionTable .cell {
  flex-grow: 1;
  font-size: var(--fontSize14);
  font-weight: 600;
  overflow: unset;
}
.transactionTable .bulkEditCell {
  padding: 0 !important;
}
.transactionTable .id-cell {
  padding-left: 20px;
  font-weight: 600;
}
.transactionTable .merchant-cell {
  display: flex;
  align-items: center;
  gap: 8px;
}
.transactionTable .merchant-cell img {
  height: 30px;
}
.transactionTable .merchent-cell {
  font-weight: 700;
}
.transactionTable .spend-cell {
  font-weight: 600;
}
.transactionTable .head-cell {
  padding-left: 20px;
}
.transactionTable .MuiDataGrid-columnHeaderTitle,
.transactionTable .custom-header {
  font-size: var(--fontSize12);
  text-transform: uppercase;
  font-weight: 700;
}
.transactionTable .custom-header {
  display: flex;
  align-items: center;
  gap: 18px;
}
.transactionTable .MuiDataGrid-colCellTitle {
  font-weight: bold;
}

.transactionTable .MuiDataGrid-columnHeaderTitle {
  font-weight: 700;
}

.transactionTable .MuiDataGrid-columnSeparator {
  display: none;
}
.transactionTable .date-cell,
.transactionTable .subcat-cell {
  font-weight: 500;
}
.transactionTable .custodian-cell .account-number,
.transactionTable .category-cell .subCategory {
  font-size: var(--fontSize12);
}
.transactionTable .review-cell {
  display: flex;
  gap: 4px;

  font-size: var(--fontSize10);
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 2px;
}
.transactionTable .review-cell > * {
  cursor: pointer;
}
.transactionTable .amount-cell {
  justify-content: flex-start;
}
.transactionTable .review-cell .review-count,
.mobTransTable .review-cell .review-count {
  border-bottom: 3px solid #2cbbe1;
  border-radius: 2px;
  padding: 0 3px;
  color: black;
  cursor: pointer;
}
.transactionTable .pending .review-cell .review-count {
  border-bottom: 3px solid gray;
}
.transactionTable .review-true .review-count,
.mobTransTable .review-true .review-count {
  border: none;
}
/* Transaction Table  */

/* mobile merchant table  */
.mobTransTable {
  position: relative;
}
.mobTransTable .merchant {
  display: flex;
  align-items: center;
}
.mobTransTable .merchant img {
  height: 30px;
  margin-right: 10px;
}
.mobTransTable .pending {
  font-style: italic;
}
.mobTransTable .pending * {
  color: gray !important;
  fill: gray !important;
}
.mobTransTable .loader {
  display: block;
  height: 50px;
  width: 100%;
  position: absolute;
  bottom: 0;
}
.mobTransTableRow {
  font-size: var(--fontSize16);
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 10px;
  border: 1px solid #e0e0e0;
}
.mobTransTableHeaders {
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 4px;
  padding: 10px 0;
  min-height: 64px;
  background: var(--colorWhite4);
  padding-left: 11px;
  padding-right: 11px;
}
.mobTransTableHeaders .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mobTransTableHeaders svg {
  margin-right: 6px;
}
.mobTransTableHeaders svg {
  transform: rotate(180deg);
}
.mobTransTableHeaders .activeSvg {
  transform: unset;
}
.mobTransTableHeaders .name {
  color: var(--colorBlack);
  font-size: var(--fontSize15);

  font-weight: 700;
  margin-left: 3px;
  flex-grow: 1;
}
.mobTransTableHeaders .amount {
  color: var(--colorBlack);
  text-align: right;
  font-size: var(--fontSize15);
  font-weight: 600;
  white-space: nowrap;
}
.mobTransTableHeaders .date-cell {
  font-size: var(--fontSize13);
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  white-space: nowrap;
}
.mobTransTableHeaders .account {
  flex-grow: 1;
  margin-left: 17px;
}
.mobTransTableInside {
  height: 134px;
  display: flex;
  flex-direction: column;
  padding-left: 11px;
  padding-right: 11px;
  border-top: 0.5px solid var(--colorWhite4);
}
.mobTransTableInside .parentRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.mobTransTableInside .spendings {
  flex-grow: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
}
.mobTransTableInside .reviews {
  padding-bottom: 22px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
}
.mobTransTableInside .commentsLogo {
  position: relative;
  top: 4px;
}
.mobTransTableInside {
  height: 244px;
  padding: 16px 22px;
  gap: 16px;
}
.mobTransTableInside .category .head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color1);
}

.mobTransTableInside .category .head img{
  position: relative;
  right: -5px;
}

.mobTransTableInside .category-comment{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobTransTableInside .category {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
}

.mobTransTableInside .category > img {
  height: 40px;
  width: 40px;
}

.mobTransTableInside .value {
  font-weight: 600;
}

.transaction-page .apexcharts-yaxis-annotations rect {
  width: 18px;
}
.transaction-page .apexcharts-datalabel {
  position: relative;
  z-index: 10;
}

.user-review-box {
  display: flex;
  max-height: 80vh;
  width: 100%;
  padding: 35px 39px 35px 38px;
  flex-direction: column;
  border-radius: 10px;
  background: var(--colorWhite);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1), 0px 21px 46px 0px rgba(0, 0, 0, 0.1), 0px 83px 83px 0px rgba(0, 0, 0, 0.09), 0px 187px 112px 0px rgba(0, 0, 0, 0.05), 0px 333px 133px 0px rgba(0, 0, 0, 0.01), 0px 520px 146px 0px rgba(0, 0, 0, 0);
  z-index: 100;
}
.user-review-box header {
  display: flex;
  justify-content: space-between;
  color: var(--colorBlack);
  font-size: var(--fontSize24);

  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 19px;
}
.user-review-box header img {
  cursor: pointer;
}
.backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgb(0 0 0/0.5);
  z-index: 9;
}

.user-review-box .textarea-cover {
  min-height: 104px !important;
  height: 104px !important;
  position: relative;
  margin-bottom: 14px;
  border: 1px solid rgb(0 0 0/0.23);
  border-radius: 4px;
}
.user-review-box textarea {
  border: none;
  border-radius: 4px;
  padding: 16px 12px;
  resize: none;
  outline: none;
  height: 100% !important;
  width: 100% !important;

  font-size: var(--fontSize16);
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: rgb(0 0 0/0.87);
  caret-color: rgb(0 0 0/0.87);
}
.user-review-box textarea::-webkit-scrollbar {
  width: 7px;
}
.user-review-box textarea::-webkit-scrollbar-thumb {
  background: rgb(0 0 0/0.23);
  border-radius: 5px;
}
.user-review-box textarea::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: rgb(0 0 0/0.4);
}
.user-review-box .textarea-cover .legend {
  content: "Add Review";
  position: absolute;

  font-size: var(--fontSize12);
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color: rgb(0 0 0/0.6);
  background: white;
  top: 0;
  padding: 0 5px;
  transform: translateY(-50%);
  left: 18px;
}
.user-review-box .btn {
  width: 115px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 2px solid var(--color1);
  background: white;
  color: var(--color1);
  color: var(--color1);
  text-align: center;
  font-size: var(--fontSize14);
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-left: auto;
  display: block;
  cursor: pointer;
}
.all-review-box {
  border-top: 1px solid #e0e0e0;
  margin-top: 21px;
  position: relative;
  overflow-y: scroll;
}
.inside-all-review-box {
  padding-top: 21px;
  padding-bottom: 5px;
  height: 100%;
  width: 100%;
}
.all-review-box .parent-review {
  padding-top: 21px;
}
.all-review-box .all-review-title {
  color: var(--colorBlack);
  font-size: var(--fontSize16);

  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 12px;
}
.user-review-box .row1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.user-review-box .row1 .img-name {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
}
.user-review-box .row1 .img-name img {
  position: relative;
  top: -5px;
  height: 35px;
  border-radius: 50%;
}
.user-review-box .row1 .username-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
}
.user-review-box .comments-btns-cover {
  display: flex;
  align-items: center;

  font-size: var(--fontSize10);
  font-weight: 800;
  line-height: 24px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  margin-top: 8px;
  gap: 16px;
}
.view-all-child {
  display: flex;
  gap: 11px;
  cursor: pointer;
}
.user-review-box .username {
  color: var(--colorBlack);
  font-size: var(--fontSize16);

  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.user-review-box .date-time {
  color: var(--colorBlack);
  text-align: right;
  font-size: var(--fontSize14);

  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: auto;
  flex-grow: 1;
}
.all-review-box .parent-review {
  color: var(--colorBlack);
  font-size: var(--fontSize16);

  font-style: normal;
  font-weight: 500;
  line-height: 150%;
}
.all-review-box .all-replies {
  /* margin-top: 21px; */
  padding: 10px 30px;
  /* border: 1px solid #e0e0e0; */
  position: relative;
}
.all-replies .close-btn {
  height: 12px;
  width: 12px;
}
.all-replies .title {
  color: var(--colorBlack);
  font-size: var(--fontSize14);

  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-left: 4px;
}
.all-replies header {
  margin-bottom: 0px;
}
.all-replies .textarea-cover {
  margin-top: 17px;
}
.all-replies .child-reviews-box {
  list-style: none;
  display: grid;
  gap: 18px;
  margin-top: 18px;
}
.show-comments {
  display: flex;
  align-items: center;
  width: min-content;
  gap: 3px;
  cursor: pointer;
}
.show-comments[hidden] {
  display: none !important;
}
.show-comments span {
  font-size: var(--fontSize10);
  font-weight: 800;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  border-radius: 2px;
  padding: 0 2px;
  padding-right: 8px;
}
.show-comments .border {
  border-bottom: 2.5px solid #2cbbe1;
}
.show-comments img {
  height: 13px;
  width: 14px;
}

.all-replies .row1 img {
  height: 25px !important;
}
.all-replies .username {
  font-size: var(--fontSize14);
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.all-replies .date-time {
  font-size: var(--fontSize12);
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
}
.all-replies p {
  font-size: var(--fontSize13);
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
}
.child-reviews-box .comments-btns-cover .close-btn {
  margin-left: auto;
  margin-right: 25px;
}
.child-level2 {
  padding: 15px 25px;
}
.DropdownLeftSide .dropdownHead {
  padding-left: 15px;
}
.dropdown-cal-innner {
  margin-left: 40px;
}

#container-fastlink > div {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  backdrop-filter: blur(10px);
  background: rgba(0 0 0/0.3);
}
#container-fastlink iframe {
  position: fixed;
  background: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px !important;
  max-width: 90%;
  height: 80% !important;
  max-height: 90%;
  box-shadow: 0 0 25px rgb(0 0 0/0.3);
  border-radius: 4px;
}

/* transactions category popup  */
.transactionTable .MuiDataGrid-cell {
  display: grid;
  place-items: center;
  position: relative;
  font-size: 12px;
}
.transactionTable .cell {
  position: relative;
  font-size: 12px;
}
.transactionTable .category-cell {
  position: absolute;
  cursor: pointer;
  width: 100%;
  position: absolute;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  /* flex-direction: column;
  justify-content: center; */
  left: 0;
  padding: 0 10px;
}
.transactionTable .category-cell .categoryLogo {
  height: 30px;
  width: 30px;
}
.transactionTable .category-cell .category {
  color: var(--color1);
  font-size: var(--fontSize14);
  font-weight: 800;
  line-height: 19px;
  cursor: pointer;
  margin-bottom: 3px;
  font-size: 12px;
}
y .transactionTable .category-cell .category img {
  position: relative;
  top: -1px;
  left: 3px;
}
.transactionTable .popOverCover {
  position: relative;
  top: 20px;
}
.categoryPopUp {
  display: grid;
  background: white;
  list-style: none;
  z-index: 20;
  padding: 10px;
  padding-right: 0;
  top: 30px;
  left: 0;
  font-size: var(--fontSize14);
  white-space: nowrap;
  box-shadow: 0px 5px 5px -3px #00000033, 0px 8px 10px 1px #00000024, 0px 3px 14px 2px #0000001f;
  border-radius: 4px;
  min-width: 208px;
  padding-bottom: 0;
}
.categoryPopUp .body{
  overflow-y: auto;
  padding-right: 10px;
}
.categoryPopUp .head {
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 2px 0;
  font-size: 12px;
}
.categoryPopUp .dropdown {
  display: grid;
}
.categoryPopUp input{
  width: 100%;
  font-size: 12px !important;
}
.categoryPopUp input,
.DropdownFilter .radio-list #searchInput {
  outline: none;
  font-size: 16px;
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid rgb(0 0 0/0.3);
}
.DropdownFilter .radio-list #searchInput {
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-top: 10px;
}
.categoryPopUp input:focus-within,
.DropdownFilter .radio-list #searchInput:focus-within {
  border: 1px solid var(--color1);
}
.categoryPopUp input:focus-within::placeholder,
.DropdownFilter .radio-list #searchInput:focus-withi::placeholder {
  opacity: 0;
}

.popOverCover .addNewSubCatBtn {
  outline: none;
  width: 100%;
  border: none;
  color: var(--color11);
  font-weight: 800;
  line-height: 21.82px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  gap: 8px;
  position: sticky;
  bottom: 0;
  cursor: pointer;
  font-size: 12px;
  padding: 10px;
  padding-left: 0;
}
.popOverCover .addNewSubCatBtn svg{
  font-size: 18px;
  position: relative;
  bottom: 1px;
}
.popOverCover .addNewSubCatBtn::before {
  content: "";
  position: absolute;
  width: calc(100% - 10px);
  left: 0;
  top: 0;
  height: 1px;
  background: var(--colorBlack9);
  opacity: 0.5;
}

.editCategoryModal {
  position: fixed;
  width: 650px;
  max-width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 100;
}

.editCategoryModal .head {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  text-align: center;
  background: var(--color1);
}
.editCategoryModal .closeBtn{
  position: absolute;
  top: 25px;
  right: 25px;
  cursor: pointer;
}

.editCategoryModal .head .title {
  font-size: 24px;
  font-weight: 700;
  line-height: 35px;
}

.editCategoryModal .head .subTitle {
  font-size: 18px;
  line-height: 30px;
}

.editCategoryModal .body{
  position: relative;
  padding: 25px;
  display: grid;
  gap: 25px;
  width: 550px;
  max-width: 100%;
  margin: 0 auto;
}

.editCategoryModal .footer{
  padding: 25px 40px;
}

.editCategoryModal .footer button{
  font-size: 14px;
}

.confirmEditCategoryModal {
  width: 800px;
  max-width: 90%;
  text-align: center;
}

.confirmEditCategoryModal .error{
  font-size: 12px;
  color: var(--colorWarning);
}

.confirmEditCategoryModal .body{
  width: 100%;
  font-size: 18px;
  gap: 15px;
  padding-bottom: 0;
  font-weight: 500;
}

.confirmEditCategoryModal .body .note{
  font-size: 12px;
  margin-top: 15px;
  font-weight: 400;
  padding: 0 20px;
}

/* manage tags modal */

.manageTagsModal {
  width: 600px !important;
}

.manageTagsModal .tags {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  max-height: 350px;
  overflow-y: auto;
}
.manageTagsModal .tag{
  display: flex;
  justify-content: center;
  min-width: 90px;
  padding: 10px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 4px;
  border: 1px solid black;
  text-align: center;
  gap: 12px;
  height: 38px;
  max-width: max-content;
  position: relative;
  overflow: hidden;
}
.manageTagsModal .error{
  color: var(--colorWarning);
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  margin-bottom: 12px;
}
.manageTagsModal .tag input{
  font-size: 12px;
  font-weight: 700;
}
.manageTagsModal .disabledModifyingTags input{
  text-align: center !important;
}
.manageTagsModal .add-tag{
  position: relative;
  max-width: 150px;
  border: 1px solid rgb(0 0 0/0.15);
  overflow: hidden;
}
.manageTagsModal .add-tag input{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 10px;
  font-size: 12px;
  font-weight: 700;
  text-align: center !important;
}
.manageTagsModal input::placeholder{
  color: rgb(0 0 0/0.5);
}
.manageTagsModal .tag span{
  position: relative;
  padding: 0 2px;
  width: 100%;
  text-align: center;
}

.manageTagsModal .tag input{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: none;
  text-align: center;
}

.manageTagsModal .tag .btns{
  position: absolute;
  display: flex;
  height: 100%;
  padding-left: 2px;
  padding-right: 8px;
  align-items: center;
  gap: 8px;
  background: white;
  top: 0;
  right: 0;
}

.manageTagsModal .tag .btns .btn{
  height: 12px;
  cursor: pointer;
}
.manageTagsModal svg{
  width: 18px;
  height: 18px;
}
.manageTagsModal .confirmation{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  color: var(--colorWarning);
  font-size: 12px;
  font-weight: 600;
  padding-bottom: 20px;
}

.manageTagsModal .confirmation .btns{
  display: flex;
  gap: 10px;
}

.manageTagsModal .confirmation svg{
  width: 16px;
}

.manageTagsModal .selectedTag {
  border-color: rgb(0 0 0/0.15);
}
.manageTagsModal .selectedTag input {
  cursor: text;
}

.manageTagsModal .footer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F9F9F9;
  border-top: 1px solid #DADADA;
}

.tagsListbox .btns{
  display: grid;
  position: sticky;
  bottom: 0;
  background: white;
}
.tagsListbox .btns hr{
  opacity: 0.5;
  width: 90%;
  margin: 0 auto;
  margin-top: 6px;
}

.tagsListbox .btns button{
  cursor: pointer;
}

.tagsListbox .createBtn{
  display: block;
  padding: 10px;
  background: var(--color1);
  color: white;
  font-size: 10px;
  font-weight: 700;
  width: 80%;
  margin: 0 auto;
  border: none;
  border-radius: 6px;
}

.tagsListbox .manageBtn{
  background: transparent;
  font-size: 12px;
  padding: 10px;
  background: white;
  font-weight: 800;
  color: var(--color1);
  border: none;
}
.tagsListbox .selected{
  background: var(--colorSelected) !important;
}
.tags-box-cover{
  width: max-content;
}
.tags-box-cover .head .text{
  text-align: start;
  padding-bottom: 5px;
  color: var(--color1);
}
.tags-box{
  width: max-content;
  position: relative;
}
.previewTags{
  border: 1px solid var(--colorWhite6);
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  width: 180px;
  background: white;
}
.tags-box .dot{
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
}

.previewTag{
  display: flex;
  border: 1px solid var(--colorWhite6);
  background: var(--color1);
  padding: 6px;
  border-radius: 3px;
  font-size: 10px;
}

.tags-box .add-btn{
  color: var(--color11);
  font-weight: 800;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.tags-box .add-btn svg{
  height: 19px;
  width: 19px;
}

.tags-box .no-tags {
  position: relative;
  margin-left: 10px;
}

.tags-box .no-tags .input-wrapper {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
}

.tags-box .no-tags .input-wrapper input{
  cursor: pointer;
}

.tags-box .arrow-icon{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 3px;
  color: var(--color1);
}

.tags-box .mob-notFocused-arrowIcon{
  right: -12px;
}

.tagsListbox .no-tags-label{
  padding: 10px 16px;
  color: black;
}

.tags .tag .tagActionVisible input{
  text-align: start;
}

.filters .row {
  display: flex;
  gap: 10px;
  flex-grow: 1;
  padding: 20px 0;
}
.mob-filter-backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgb(0 0 0/0.5);
  z-index: 11;
}
.categoryPopUp .dropdown .lvl-2-3 {
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15000000596046448px;
  padding: 2px 10px;
  cursor: pointer;
  border-radius: 3px;
  display: grid;
  grid-template-columns: 1fr min-content;
  position: relative;
}
.categoryPopUp .dropdown .lvl-2-3 .label{
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
}
.categoryPopUp .dropdown .lvl-2-3 img{
  margin: auto 0 auto 5px;
  display: none;
}

.categoryPopUp .dropdown .lvl-2-3:hover {
  background: rgb(240 240 240);
}

.categoryPopUp .dropdown .lvl-2-3:hover img{
  display: block;
}

.categoryPopUp .dropdown .active {
  background: #21cae710 !important;
}
.categoryPopUp .manageBtn {
  font-size: 14px;
  font-weight: 800;
  line-height: 19px;
  background: var(--color1);
  color: white;
  height: 40px;
  border-radius: 5px;
  display: grid;
  place-items: center;
  cursor: pointer;
  border: 2px solid var(--color1);
}

/* manage catogory box  */

.manageCategoryBox {
  display: flex;
  flex-direction: column;
  position: absolute;
  background: white;
  height: calc(100% - 105px);
  width: calc(100% - 40px);
  z-index: 11;
  top: 80px;
  left: 20px;
  border-radius: 4.711px;
  border: 1px solid rgba(189, 189, 189, 0.25);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 28px 54px;
}
.manageCategoryBox .categoryCover {
  flex-grow: 1;
  overflow-y: auto;
}
.manageCategoryBoxOpens {
  height: 0;
  overflow: hidden;
}
.manageCategoryBoxOpened .account-list,
.manageCategoryBoxOpened .transaction-right-side,
.manageCategoryBoxOpened .holdings-right-side {
  box-shadow: none;
  filter: none;
}
.manageCategoryBox .close-box {
  display: flex;
  justify-content: end;
}
.manageCategoryBox .close-box .close-icon {
  position: relative;
  right: -30px;
  cursor: pointer;
}
.manageCategoryBox .heading {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  margin-bottom: 42px;
  line-height: normal;
}
.manageCategoryBox .category {
  margin-bottom: 20px;
  display: grid;
  gap: 15px;
}
.manageCategoryBox .category-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 19px;
  border: 0.5px solid var(--colorWhite5);
  background: var(--colorWhite4);
  height: 50px;
}
.manageCategoryBox .category-list .title .text {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.2px;
}
.manageCategoryBox .category-list .title {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 29px;
  gap: 12px;
  height: 100%;
}
.manageCategoryBox .addNewCatBtn {
  display: flex;
  align-items: center;
  height: 100%;
  cursor: pointer;
  gap: 5px;
  color: var(--color11);
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.manageCategoryBox .addNewCatBtn img,
.manageCategoryBox .addSubCatBtn img {
  position: relative;
  top: -0.5px;
}
.manageCategoryBox .addSubCatBtn {
  color: var(--color1);
  text-align: center;
  font-family: Nunito;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  background: var(--colorWhite4);
  padding: 0 13px;
  /* padding-bottom: 15px; */
}
.manageCategoryBox .addSubCatInside {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  height: 52px;
  cursor: pointer;
}
.manageCategoryBox .categoriesBoxMain {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  gap: 20px;
}
.manageCategoryBox .subCategory .scroller {
  border: 1px solid #e0e0e0;
}
.manageCategoryBox .subCategoryExpanded .scroller {
  border-bottom: none;
}
.manageCategoryBox .subCategory .scroller {
  max-height: 230px;
  overflow-y: auto;
}
.manageCategoryBox .subCategory .label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 51px;
  background: var(--colorWhite4);
  font-style: normal;
  font-weight: 700;
  display: flex;
  align-items: center;
  line-height: normal;
  position: relative;
  padding: 0 15px;
  cursor: default;
}
.manageCategoryBox .subCategory .text {
  display: flex;
  align-items: center;
}
.manageCategoryBox img {
  position: relative;
  z-index: 1;
}
.manageCategoryBox .subCategory .text img {
  margin-right: 6px;
}
.manageCategoryBox .subCategoryExpanded .label:hover .pencil {
  display: block;
}
.manageCategoryBox .pencil {
  display: none;
  padding: 5px;
  position: relative;
  right: -5px;
  cursor: pointer;
}
.manageCategoryBox .subCategoryExpanded .subCategoryInside ul li:hover .pencil {
  display: block;
}
.manageCategoryBox .subCategoryExpanded .label {
  background: unset;
  /* border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0; */
}
.manageCategoryBox .subCategory .label input {
  outline: none;
  border: none;
  background: var(--colorWhite4);
  position: absolute;
  height: 100%;
  width: 100%;
  padding: 0 15px;
  padding-left: 30px;
  font-weight: 700;
  font-size: 16px;
  left: 0;
  bottom: 0;
  cursor: default;
}
.manageCategoryBox .subCategoryExpanded .label input {
  background: unset;
}

.manageCategoryBox .subCategory .labelEditOn input {
  cursor: text;
}

.manageCategoryBox .subCategory .labelEditOn .text {
  padding-left: 15px;
  padding-right: 15px;
}
.manageCategoryBox .subCategory .labelEditOn .arrow {
  opacity: 0;
}
.manageCategoryBox .subCategory .labelEditOn input {
  padding-left: 20px;
}
.manageCategoryBox .subCategory .labelEditOn::before {
  content: "";
  position: absolute;
  background: var(--colorWhite4);
  top: 5px;
  right: 10px;
  left: 15px;
  bottom: 5px;
}
.manageCategoryBox .subCategoryExpanded {
  border-left: none;
  border-bottom: none;
  border-right: none;
}
.categoriesBoxMain ul {
  list-style: none;
  padding-left: 15px;
  padding-right: 10px;
}
.categoriesBoxMain ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #e0e0e0;
  height: 45px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  padding-right: 5px;
}
.categoriesBoxMain ul li .pencil {
  margin-left: auto;
}
.categoriesBoxMain ul li input {
  position: absolute;
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  cursor: default;
}
/* .categoriesBoxMain .subCategoryInside ul {
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
} */
.categoriesBoxMain .subCategoryInside .subCatEditOn input {
  background: var(--colorWhite4);
  padding-left: 8px;
  cursor: text;
}
.categoriesBoxMain ul li:last-child {
  border-bottom: none;
}
.categoriesBoxMain .textEditBox {
  padding-top: 15px;
  padding-bottom: 28px;
}
.categoriesBoxMain .textEditBox .saveBtn {
  width: 140px;
  height: 40px;
  display: grid;
  place-items: center;
  margin-top: 16px;
  margin-left: auto;
  border-radius: 5px;
  color: white;
  border: 2px solid var(--color1);
  background: var(--color1);
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  cursor: pointer;
}
.categoriesBoxMain .addSubCatToggled {
  border: 1px solid #e0e0e0;
  border-top: none;
}
.categoriesBoxMain .textEditBox .subCatInput fieldset {
  border: 1px solid #bfbfbf;
}
.categoriesBoxMain .textEditBox .subCatInput label {
  color: rgb(0 0 0/0.6);
}

/* Problem Accounts */

.problemAccounts {
  border-radius: 4px 0 0 4px;
  border-left: 6px solid var(--colorWarning);
  margin-bottom: 20px;
  max-height: 25%;
  display: flex;
  flex-direction: column;
}

.problemAccounts .head {
  display: flex;
  align-items: center;
  color: var(--colorWarning);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  gap: 14px;
  padding-left: 23px;
  margin-bottom: 10px;
}
.problemAccounts .problemAccount {
  display: flex;
  flex-direction: column;
  padding: 10px 5%;
  margin-right: 5px;
  cursor: default;
  position: relative;
  margin-left: 2px;
}
.problemAccounts .problemAccount::before {
  content: "";
  position: absolute;
  height: 1px;
  background: #e0e0e0;
  bottom: -1px;
  width: 90%;
  left: 5%;
}
.problemAccounts .problemAccount:last-child::before {
  content: unset;
}
.problemAccount .accountInfo .fi {
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 5px;
}
.problemAccount .accountInfo .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}
.problemAccount .accountDetails {
  margin-top: 10px;
}
.problemAccount .account-date .date {
  white-space: nowrap;
}
.problemAccounts .active {
  border-radius: 4.711px;
  border: 1px solid #dadada;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4.711px 15px 0px rgba(0, 0, 0, 0.07);
}
.problemAccounts .infoCover {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  cursor: pointer;
}
.problemAccounts .infoCover .accountInfo {
  flex-grow: 1;
}
.problemAccount .actionBtns {
  display: flex;
  justify-content: flex-end;
  gap: 5px;
  margin-top: 10px;
}

.problemAccount .reconnectBtn {
  height: 25px;
  padding: 0 10px;
  background: var(--color1);
  border: 1px solid var(--color1);
  border-radius: 5px;
  color: white;
  font-size: 10px;
  cursor: pointer;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
  position: relative;
  top: -3px;
}

/* edit account css  */

.edit-account-modal {
  width: 1000px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  max-width: 90%;
  max-height: 95%;
  background: white;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  overflow: hidden;
}

.edit-account-modal .head {
  position: relative;
  text-align: center;
  padding: 20px;
  background: var(--color1);
}

.edit-account-modal .head .close-icon {
  position: absolute;
  top: 30px;
  right: 50px;
  cursor: pointer;
}

.edit-account-modal .edit-acccount-content {
  flex-grow: 1;
  padding: 4px;
}

.edit-account-modal .left-content {
  border-right: 1px solid var(--Neutral-300, #eff0f6);
  min-width: max-content;
  width: 450px;
  padding: 20px;
  position: relative;
  border-right: 2px solid #DCDCDC;
}

.edit-account-modal .left-content .btns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.edit-account-modal .account-details .balance-as-of .row1{
  align-items: center;
}

.edit-account-modal .right-content {
  position: relative;
  padding: 20px;
  flex-grow: 1;
  justify-content: flex-start !important;
}
.edit-account-modal .right-content .right-content-inside {
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 25px;
}
.edit-account-modal .btns {
  margin-top: auto;
}

.edit-account-modal .removeAccountBtnsInside{
  gap: 12px !important;
}

.edit-account-modal .right-content .btns button {
  border-radius: 5px;
  border: 1px solid #21cae7;
  background: #21cae7;
  width: 156.081px;
  height: 47px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}
.edit-account-modal .right-content .btns .cancelBtn {
  background: white;
  color: var(--color1);
}
.edit-account-modal .right-content .btns .removeBtn {
  background: white;
  color: #fd4c5c;
  border: 1px solid #fd4c5c;
}
.edit-account-modal .mark-closed {
  margin-top: 25px;
  margin-bottom: 30px;
}
.edit-account-modal .mark-closed * {
  cursor: pointer;
}
.edit-account-modal .mark-closed .checkbox {
  height: 14px;
  width: 14px;
  border-radius: 1px;
  display: grid;
  place-items: center;
  color: white;
  border: 1px solid rgb(0 0 0/0.3);
}
.edit-account-modal .mark-closed .checkbox img {
  opacity: 0;
  position: relative;
  top: -1px;
  left: -1px;
}
.edit-account-modal .mark-closed input:checked + .checkbox img {
  opacity: 1;
}
.edit-account-modal .mark-closed .text {
  color: var(--Neutral-600, #6f6c90);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  position: relative;
  top: 1px;
  cursor: pointer;
  white-space: nowrap;
}
.edit-account-modal .mobRemoveBtn {
  background: transparent;
  color: var(--color2);
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.276px;
  width: min-content;
  white-space: nowrap;
}
.edit-account-modal .warning {
  color: #fd4c5c;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Nunito;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  display: grid;
  gap: 10px;
  margin: 20px 0;
}
.edit-account-modal .account-close-disabled *{
  cursor:not-allowed !important;
}
.edit-account-modal .warning b {
  font-weight: 800;
}
.edit-account-modal .warning .desc {
  font-size: 8px;
}
.edit-account-modal .close-fields {
  display: flex;
  align-items: center;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  margin-left: 20px;
  padding: 0 20px;
  height: 45px;
  gap: 10px;
}
.edit-account-modal .close-fields > .label {
  position: absolute;
  left: 10px;
  top: -1px;
  transform: translateY(-50%);
  background: white;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  padding: 0 5px;
}
.edit-account-modal .close-fields label {
  position: absolute;
  top: 5px;
}
.edit-account-modal .account-details .balanceField{
  display: flex;
  align-items: center;
  position: relative;
}
.edit-account-modal .account-details .balanceField *{
  font-weight: 600;
}
.edit-account-modal .account-details .balanceField .editIcon{
  color: var(--color1);
  font-size: 18px;
  margin-top: 2px;
}
.edit-account-modal .account-details .MuiTextField-root{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

/* bulk edit  */

.bulkEditBox {
  box-shadow: 0px 4px 20px 0px #00000040;
  margin-bottom: 20px;
}

.bulkEditBox .content {
  flex-grow: 1;
  padding-top: 4px;
  padding-bottom: 8px;
  padding-right: 24px;
}

.bulkEditBox .selectedTransactions {
  font-family: Nunito;
  font-size: 14px;
  font-weight: 800;
  line-height: 21.82px;
  text-align: left;
}

/* add manual account */
.addManualAccountModal .body{
  padding-top: 30px !important;
}

.addManualAccountModal .footer .btns{
  gap: 20px !important;
}

.addManualAccountModal .MuiFormHelperText-root{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  bottom: unset;
  left: 13px;
  font-size: 12px;
}

.addManualAccountModal .MuiFormLabel-root{
  background-color: white;
  transform: translate(14px, -9px) !important;
  font-size: 12px;
  padding: 0 6px;
  margin-left: -6px;
}

.addManualAccountModal .MuiInputBase-root *{
  font-size: 12px;
}

.addManualAccountModal .MuiSelect-icon{
  font-size: 20px;
}

.addManualAccountModal .MuiFormHelperText-filled{
  display: none;
}

.addManualAccountModal .closeIcon{
  font-size: 16px;
  top: 20px !important;
  right: 10px !important;
}

.addManualAccountModal .accountName .MuiFormLabel-filled + .MuiInputBase-root textarea{
  -webkit-text-fill-color: black !important;
}

.addManualAccountModal input, .addManualAccountModal textarea{
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  text-overflow: ellipsis;
}

.addManualAccountModal textarea::placeholder{
  color: rgb(0 0 0/0.6);
}
.addManualAccountModal .footer{
  padding-bottom: 25px !important;
}

/* manage rules  */
.manageRulesModal {
  width: 1200px;
  max-width: 95% !important;
}
.manageRulesModal .closeIcon{
  transform: translateY(-120%) !important;
}

.manageRulesModal .body{
  padding: 30px !important;
}
.manageRulesModal .footer{
  padding: 20px 40px !important;
}
.manageRulesModal .title{
  font-size: 24px !important;
}

.manageRulesModal .rules {
  display: grid;
  gap: 30px;
}
.manageRulesModal .ruleCount{
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
  gap: 8px;
}

.manageRulesModal .ruleCount .setToRun{
  font-weight: 700;
  white-space: nowrap;
}

.manageRulesModal .ruleCountLabel{
  font-size: 18px;
  font-weight: 700;
  line-height: 35px;
  cursor: default;
}
.manageRulesModal .transactionUpdateLabel{
  font-size: 14px;
  color: grey;
  font-style: italic;
}
.editRuleBox .editRuleActionsCover{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  flex-wrap: wrap-reverse;
  gap: 12px;
}
.editRuleBox .ruleApplyLabel {
  font-size: 14px;
  font-style: italic;
  color: grey;
  padding-left: 20px;
  text-align: right;
  margin-left: auto;
}
.activeRuleOptions{
  display: grid;
  place-items: center;
  width: 300px;
  padding: 16px;
  text-align: center;
  font-size: 14px;
}
.activeRuleOptions .runNowLabel .MuiTypography-root{
  font-size: 14px;
  font-weight: 700;
}

.activeRuleOptions .btns{
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.manageRulesModal .ruleBoxes{
  display: grid;
  grid-template-columns: 1fr 80px;
  align-items: center;
  gap: 15px;
}
.manageRulesModal .ruleBoxesInside {
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-grow: 1;
}
.manageRulesModal .ruleBoxes .actionIcons{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.manageRulesModal .conditionsWrapper {
  margin-right: 15px;
  border-right: 1px solid black;
}
.manageRulesModal .conditions {
  height: min-content;
}
.manageRulesModal .conditions .operator{
  min-width: 50px;
}
.manageRulesModal .tags{
  flex-grow: 1;
  grid-template-columns: minmax(0px,min-content) minmax(0px,min-content) minmax(0px,min-content) !important;
}
.manageRulesModal .tagsWrapper .dot{
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.manageRulesModal .ruleBoxes .ruleCol{
  display: grid;
  flex-grow: 1;
  gap: 8px;
  padding: 12px 15px;
  grid-template-columns: minmax(100px, 1fr);
  border: 1px solid var(--color1);
  border-radius: 8px;
  align-items: self-start;
  place-content: start;
}
.manageRulesModal .rule{
  border-radius: 8px;
  min-width: 100px;
  font-weight: 600;
  border-radius: 8px;
  cursor: default;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.manageRulesModal .tag{
  min-width: unset !important;
}
.manageRulesModal .ruleBoxes .editIcon {
  color: var(--color1);
  cursor: pointer;
  display: none;
}
.manageRulesModal .ruleBoxes .deleteIcon {
  color: var(--colorWarning);
  cursor: pointer;
  display: none;
}
.manageRulesModal .ruleBoxes:hover .editIcon, .manageRulesModal .ruleBoxes:hover .deleteIcon{
  display: block;
}

.manageRulesModal .footer{
  justify-content: flex-start !important;
}

.manageRulesModal .editRuleBox{
  padding: 15px;
  border: 1px solid var(--colorWhite7);
  position: relative;
}

.manageRulesModal .editRuleBox .headTitle, .manageRulesModal .editRuleBox .headTitle input{
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 20px;
  font-weight: 700;
  padding-left: 10px;
}
.manageRulesModal .editRuleBox .headTitle input{
  margin-bottom: 10px;
}
.manageRulesModal .error{
  margin: 0px !important;
}
.manageRulesModal .editRuleBox .headTitle{
  align-items: flex-start;
  flex-direction: column;
}
.manageRulesModal .deleteConfirmation{
  flex-grow: 1;
}
.manageRulesModal .deleteConfirmation .error{
  justify-content: center;
  margin-bottom: 8px !important;
}
.manageRulesModal .deleteConfirmationInside {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 12px;
  width: 100%;
}

.manageRulesModal .deleteConfirmation .message{
  font-weight: 700;
  color: var(--colorWarning);
}

.manageRulesModal .editRuleBox .headTitle .headeTitleEditSection{
  display: flex;
  align-items: center;
  gap: 8px;
}

.manageRulesModal .editRuleBox .headTitle .dummyTitle{
  position: relative;
  padding: 0 3px;
}

.manageRulesModal .editRuleBox .headTitle .dummyTitle input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0;
  border: none;
}

.manageRulesModal .editRuleBox .valueField input{
  text-overflow: ellipsis;
}

.manageRulesModal .editRuleBox .headTitle .editIcon{
  display: block;
  color: var(--color1);
  cursor: pointer;
}

.manageRulesModal .editRuleBox .ruleFields{
  display: grid;
  grid-template-columns: 1fr 0.7fr 1fr 25px;
  align-items: center;
  gap: 20px 7px;
}
.manageRulesModal .categoryRule .ruleFields{
  grid-template-columns: 1.5fr 1fr 25px;
}
.manageRulesModal .editRuleBox .ruleFields .fieldActionBtns{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.manageRulesModal .editRuleBox .ruleFields .fieldCover{
  display: flex;
  align-items: center;
}
.manageRulesModal .editRuleBox .addRule .ruleFieldsContainer{
  display: grid;
  gap: 20px;
  padding-top: 20px;
}

.manageRulesModal .editRuleBox .addIcon, .manageRulesModal .editRuleBox .removeIcon{
  color: var(--color2);
  cursor: pointer;
  font-size: 23px;
}

.manageRulesModal .editRuleBox .ruleFields .removeIcon {
  color: var(--color14);
}
.manageRulesModal .editRuleBox .merchantCategoryRule {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 17px;
}
.manageRulesModal .editRuleBox .merchantCategoryRule .addRule{
  border-radius: 4px;
  padding: 18px;
  flex-grow: 1;
}
.manageRulesModal .editRuleBox .merchantCategoryRule .merchantRule{
  position: relative;
}
.manageRulesModal .editRuleBox .merchantCategoryRule .merchantRule::before{
  content: "";
  position: absolute;
  right: -8px;
  width: 1px;
  height: 100%;
  background: var(--color12o65);
}

.manageRulesModal .editRuleBox .merchantCategoryRule .addRule .title{
  font-size: 18px !important;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--colorBlack9o45);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.manageRulesModal .editRuleBox .merchantCategoryRule .addRule .title .dropdownIcon{
  color: var(--color1);
}

.manageRulesModal .editRuleActions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  flex-grow: 1;
}

.manageRulesModal .editRuleBox .noOptionsFound{
  font-size: 11px;
  color: black;
}

.manageRulesModal .rulesTags {
  display: grid;
  grid-template-columns: 1.5fr 1fr 25px;
  gap: 7px;
  align-items: center;
}
.manageRulesModal .rulesTags .mob-notFocused-arrowIcon{
  right: 0;
}
.manageRulesModal .rulesTags .dot {
  right: 14px !important;
}
.manageRulesModal .rulesTags .tags-box{
  width: 100%;
}
.manageRulesModal .rulesTags .input-wrapper{
  width: 100%;
  min-height: 56px;
  background: transparent;
  border: 1px solid rgb(0 0 0/0.23) !important;
}

.manageRulesModal .rulesTags .input-wrapper:hover{
  border-color: black;
}

.manageRulesModal .rulesTags .not-focused{
  gap: 10px 4px;
  padding-top: 12px;
  padding-right: 20px;
}

.manageRulesModal .rulesTags .tags-box .dot{
  right: 12px;
}

.manageRulesModal .rulesTags .input-wrapper input{
  padding: 0;
  padding-left: 10px;
  padding-bottom: 5px;
  height: 40px;
  font-size: 20px;
}

.manageRulesModal .rulesTags .tags-box-empty .input-wrapper input {
  margin-top: 10px;
}
.manageRulesModal .rulesTags .not-focused input{
  margin-top: 8px;
}
.manageRulesModal .rulesTags .not-focused .label{
  top: 14px;
  left: 14px;
}

.manageRulesModal .rulesTags .label{
  background: white;
}
.rulesWarningModal {
  grid-template-rows: min-content minmax(80px,1fr) min-content !important;
}
.rulesWarningModal .warning{
  font-weight: 700;
  text-align: center;
  color: var(--colorWarning);
}
.rulesWarningModal .warningConfirm{
  margin-top: 8px;
}
.rulesWarningModal .body{
  padding-bottom: 0 !important;
}
.rulesWarningModal .footer{
  align-items: center;
}
.transaction-page .accountNavigationTransactions{
  display: flex;
  justify-content: space-between;
  padding-right: 38px;
}

/* transaction comments  */

.commentModal {
  display: flex !important;
  flex-direction: column;
  border-radius: 12px !important;
}
.commentModal .body {
  display: grid !important;
  gap: 20px;
  padding-bottom: 0 !important;
}
.commentBox .messageIcon {
  cursor: pointer;
  color: var(--color1);
  height: 20px;
  width: 20px;
  transform: unset !important;
  margin: 0 !important;
}

.commentBox .filledMessageIcon path{
  fill-opacity: 1;
  stroke: var(--color1);
}

.commentModal .footer {
  height: min-content;
}

.commentModal textarea {
  height: 120px;
  border-radius: 8px;
  padding: 10px;
  resize: none;
}

.commentModal textarea:focus-within {
  border: 1px solid var(--color1);
}

.commentTooltip {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;  
  overflow: hidden;
}

.amount-date-comment {
  display: flex;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 1050px) {
  .transaction-right-side .top-area .right-top-area > * + * {
    margin-left: 2vw;
  }
}
@media screen and (min-width: 900px) {
  ::-webkit-scrollbar {
    width: 9px;
    height: 9px;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(0 0 0/0.2);
  }
  ::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: rgb(0 0 0/0.15);
  }
  .transaction-right-inside {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 25px 29px;
  }
  .filters {
    display: flex;
    position: absolute;
    background: white;
    width: 100%;
    z-index: 20;
    padding: 0px 32px;
    padding-left: 0;
    box-shadow: 0px 4px 20px 0px #00000040;
    border-radius: 3px;
    gap: 14px;
  }
  .filters header,
  .bulkEditBox header {
    display: flex;
    justify-content: center;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    writing-mode: vertical-rl;
    transform: rotate(-180deg);
    padding: 15px 7px;
    background: var(--color19);
    box-shadow: -5px 0 10px rgb(0 0 0/0.1);
  }
  .bulkEditBox header {
    font-size: 16px;
    font-weight: 800;
    line-height: 21.82px;
    padding-left: 3px;
    padding-right: 3px;
  }
  .filters header img {
    display: none;
  }
  .transaction-page .scrollable-content {
    padding-bottom: 0;
  }
  .transaction-page .chart-container {
    position: relative;
    width: 100%;
    min-height: 400px;
    padding: 0;
    padding-right: 20px;
  }
  .transactionTable {
    margin-top: 32px;
  }
  .transaction-page .scrollable-content-inside {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-height: 1050px) {
  .transaction-page .chart-container {
    min-height: 250px;
    height: 100% !important;
    max-height: 400px;
  }
}

@media screen and (max-width: 900px) {
  .transaction-page .accountNavigationTransactions {
    padding-right: 20px !important;
  }
  .problemAccounts {
    flex-grow: 1;
    max-height: 25vh;
  }
  .accounts-navigation {
    padding: 0 20px;
  }
  .accounts-navigation .btn {
    border: 1px solid var(--color1);
  }
  .filters .row {
    flex-direction: column;
    align-items: flex-start;
  }
  .transaction-page .apexcharts-data-labels {
    opacity: 0;
  }
  .transaction-page {
    height: calc(100vh - 70px);
    padding: 15px 0;
    padding-bottom: 0;
  }
  .transaction-page .chart-container {
    padding: 0;
    padding-right: 20px;
  }
  .transaction-right-side {
    padding: 20px 20px 0 20px;
    height: 100%;
  }
  .transaction-right-inside {
    height: calc(100vh - 220px);
  }
  .active-ALL .transaction-right-inside {
    height: 100%;
  }
  .transactionbg {
    position: fixed;
    left: -24px;
    top: 121px;
    opacity: 0.5;
  }
  .transaction-chart {
    border: 2px solid rgb(189 189 189/0.25);
    padding: 15px;
    justify-content: center;
    width: 100%;
    height: 366px;
    position: relative;
    border-radius: 4px;
  }
  .transaction-body-inner-area {
    flex-direction: column;
  }
  .transaction-right-cover {
    margin: 0;
  }
  .active-MY-ACCOUNT .transaction-right-cover {
    height: unset;
  }
  .transaction-right-side {
    margin: 0;
    box-shadow: none;
  }
  .active-ALL {
    padding-bottom: 0 !important;
  }
  .active-MY-ACCOUNT .account-list {
    margin-bottom: 0;
  }
  .transaction-page .scrollable-content {
    padding-right: 0;
  }
  .mobTransTableInside {
    height: min-content;
    padding-left: 16px;
    padding-right: 16px;
  }
  .transaction-page .rating-review {
    display: flex;
    justify-content: space-between;
  }
  .transaction-page .mobTransTable .review-cell {
    display: flex;
    align-items: center;
  }
  .transaction-page .mobTransTable .review-count {
    margin-left: 4px;
  }
  .transaction-page .mobTransTable .review-count span {
    margin-left: 3px;
  }
  .transaction-body-inner-area {
    padding: 0 20px;
    padding-bottom: 20px;
    overflow-y: auto;
  }
  .transaction-page .active-ALL {
    padding-bottom: 20px !important;
  }
  .transaction-page .MuiAlert-root {
    margin: 0 20px 0 20px;
    width: calc(100% - 40px);
  }
  .account-list {
    width: 100%;
    padding-top: 20px;
    margin-bottom: 10px;
  }

  .transaction-page .active-ALL .account-list {
    height: min-content;
    max-height: calc(100vh - 140px);
  }
  .transaction-right-side .top-area {
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;
  }
  .transaction-page .filter-cover img {
    border: 1px solid var(--color1);
    height: 25px;
    width: 25px;
    padding: 4px;
    border-radius: 2px;
  }

  .transaction-page .filters {
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    width: 311px;
    height: 581px;
    max-width: 100%;
    max-height: 100%;
    background: white;
    z-index: 12;
    padding: 25px;
    gap: 0;
  }

  .transaction-page .filters > * {
    margin-bottom: 23px;
  }
  .transaction-page .filters header {
    font-size: var(--fontSize24);
    font-weight: 800;
    line-height: 33px;
    letter-spacing: 0px;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .insights .filters .close-icon {
    cursor: pointer;
  }
  .transaction-right-side .top-area > h1 {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .transaction-page .DropdownFilter .popup {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 10px;
  }
  .transaction-page .DropdownFilter .popup button {
    color: white;
    margin-top: 15px;
  }
  .transaction-page .DropdownFilter .input-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: white;
  }

  .manageCategoryBox {
    position: fixed;
    top: 70px;
    height: calc(100% - 70px);
    width: 100%;
    box-shadow: none;
    border: none;
    left: 0;
    border-radius: 0px;
    padding: 0;
  }
  .manageCategoryBox .close-box svg {
    top: 22px;
    right: 21px !important;
  }
  .manageCategoryBox .heading {
    margin-bottom: 24px;
    padding: 0 21px;
  }
  .manageCategoryBox .category {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 4.711px;
  }
  .manageCategoryBox .category .category-list {
    border: 1px solid rgba(189, 189, 189, 0.25);
    border-radius: 4.711px;
  }
  .manageCategoryBox .categoryCover {
    padding: 0 21px;
  }

  .manageCategoryBox .addNewCatBtn .btn span {
    display: none;
  }

  .manageCategoryBox .pencil {
    display: block;
  }

  .manageCategoryBox .categoriesBoxMain {
    padding: 0 21px;
    max-height: calc(100vh - 245px);
    overflow-y: auto;
    gap: unset;
  }
  .manageCategoryBox .category {
    display: flex;
    flex-direction: column;
  }
  .manageCategoryBox .categoryCover .expanded {
    padding-bottom: 20px;
  }
  .manageCategoryBox .subCategory .scroller {
    border: none;
  }
  .manageCategoryBox .subCategory {
    margin-bottom: 18px;
    border: 1px solid #e0e0e0;
  }
  .manageCategoryBox .subCategory:last-child {
    margin: 0;
  }
  .categoriesBoxMain .textEditBox {
    padding-bottom: 15px;
  }
  .manageCategoryBox .subCategory .labelEditOn::before {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .categoriesBoxMain .labelEditOn .text input {
    padding-left: 8px !important;
  }
  .manageCategoryBox .categoryCover .expanded .category-list {
    border-bottom: none;
  }
}
@media screen and (min-width: 750px) {
  .manageRulesModal .editRuleBox .ruleFieldsContainer .MuiFormHelperText-root{
    line-height: 22px;
  }
  .manageRulesModal .ruleCount .setToRun{
    margin-right: 85px;
  }
  .edit-account-modal .account-details .balanceField .editIcon{
    margin-left: 4px;
  }
  
  .edit-account-modal .balanceField .editIcon{
    display: none;
  }

  .edit-account-modal .balanceField:hover .editIcon{
    display: block;
  }

  .edit-account-modal .btns{
    justify-content: flex-end;
  }

  .addManualAccountOption{
    font-size: 14px;
  }
  .addManualAccountModal .accountName-balance{
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .manageTagsModal .tag .btns{
    display: none;
  }
  .manageTagsModal .tag:hover .btns{
    display: flex;
  }
  .manageTagsModal .tag:hover input{
    text-align: start;
  }
  .edit-account-modal .right-content {
    width: min-content;
  }
  .customScroll::-webkit-scrollbar {
    width: 9px;
  }
  .customScroll::-webkit-scrollbar-thumb {
    background: rgb(0 0 0/0.2);
  }
  .customScroll::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: rgb(0 0 0/0.15);
  }
  .editCategoryModal .editIcon{
    display: none;
  }
  .categoryPopUp .dropdown .lvl-2-3 .editableLabel::before{
    content: "";
    position: absolute;
    left: 0;
    top: calc(50% - 2px);
    height: 4px;
    width: 4px;
    border-radius: 50%;
    background: var(--color9);
  }
  .categoryPopUp .dropdown .lvl-2-3:hover .editableLabel::before{
    display: none;  
  }
}
@media screen and (max-width: 750px) {
  .commentModal .body {
    gap: 12px;
  }
  .commentModal .footer {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .activeRuleOptions{
    width: 250px;
    font-size: 12px;
    gap: 8px;
    padding: 12px;
  }
  .activeRuleOptions .runNowLabel .MuiTypography-root{
    font-size: 12px;
  }
  .activeRuleOptions label{
    height: 24px
  }
  .activeRuleOptions .btns{
    margin-top: 0;
  }
  .editRuleBox .ruleApplyLabel{
    padding-left: 13px;
    font-size: 12px;
  }
  .rulesWarningModal .warning, .rulesWarningModal .title{
    font-size: 14px !important;
  }
  .manageRulesModal .deleteConfirmation .message{
    font-size: 12px;
  }
  .manageRulesModal .editRuleBox .merchantCategoryRule .merchantRule::before{
    right: unset;
    left: 0;
    bottom: -12px;
    width: 100%;
    height: 1px;
  }
  .manageRulesModal .editRuleBox .ruleFields{
    grid-template-columns: 1.5fr 1fr;
  }
  .manageRulesModal .categoryRule .ruleFields{
    grid-template-columns: 1.5fr 1fr 25px;
  }
  .manageRulesModal .categoryRule .ruleFieldsExtended{
    grid-template-columns: 1.5fr 1fr 48px;
  }
  .manageRulesModal .categoryRule .ruleFieldsExtended + .rulesTags{
    grid-template-columns: 1.5fr 1fr 48px;
  }
  .manageRulesModal .editRuleBox .ruleFields .fieldActionBtns{
    flex-direction: row;
  }
  .manageRulesModal .rulesTags .not-focused .label{
    top: 8px !important;
  }
  .manageRulesModal .rulesTags .tags-box-empty .input-wrapper input{
    margin-top: 0 !important;
  }
  .manageRulesModal .rulesTags .not-focused{
    padding-top: 4px !important;
  }
  .manageRulesModal .rulesTags .label{
    font-size: 12px;
  }
  .rulesOption{
    min-height: min-content;
    font-size: 12px;
  }
  .manageRulesModal .editRuleBox .merchantCategoryRule .addRule .title{
    cursor: pointer;
    font-size: 12px !important;
    padding-bottom: 2px;
  }
  .manageRulesModal .editRuleBox .addRule .ruleFieldsContainer{
    padding-top: 15px;
  }
  .manageRulesModal .editRuleBox .ruleFieldsContainer .MuiFormControl-root *{
    font-size: 12px !important;
  }
  .manageRulesModal .editRuleBox .ruleFieldsContainer .MuiFormHelperText-root{
    font-size: 10px !important;
  }
  .manageRulesModal .editRuleBox .ruleFields .MuiInputLabel-shrink{
    text-transform: uppercase;
  }
  .manageRulesModal .editRuleBox .ruleFields .select  label[data-shrink=false]{
      font-size: 12px;
      top: unset;
      bottom: 20px;
      left: -14px;
    }
    .manageRulesModal .editRuleBox .valueFieldCover .MuiFormControl-fullWidth .MuiFormLabel-root{
      bottom: unset !important;
      left: unset !important;
    }
    .manageRulesModal .editRuleBox .ruleFields .select  label[data-shrink=true]{
      font-size: 12px !important;
      top: unset;
      bottom: 14px;
      left: -14px;
  }
  .manageRulesModal .editRuleBox .merchantCategoryRule .addRule{
    padding: 13px 0;
  }
  .edit-account-modal{
    min-height: 450px;
  }
  .edit-account-modal .btns{
    justify-content: space-between;
    margin-top: auto;
  }
  /* rules  */
  .manageRulesModal .editRuleBox .merchantCategoryRule{
    grid-template-columns: 1fr;
  }
  .manageRulesModal .editRuleBox .merchantCategoryRule .collapsed .title{
    padding: 0;
    border: none;
  }
  .manageRulesModal .rules{
    gap: 25px;
  }
  .manageRulesModal .ruleCol{
    padding: 8px 10px !important;
    font-size: 12px;
  }
  .manageRulesModal .ruleBoxes .ruleCol, .manageRulesModal .results, .manageRulesModal .conditions{
    gap: 6px;
  }
  .manageRulesModal .ruleBoxesInside{
    display: flex;
    flex-direction: column;
  }
  .manageRulesModal .conditionsWrapper{
    padding: 0;
    margin: 0;
    border: none;
    margin-bottom: 12px;
    border-bottom: 1px solid black;
  }
  .manageRulesModal .ruleBoxes .actionIcons{
    gap: 10px !important;
  }
  .manageRulesModal .ruleBoxes{
    grid-template-columns: 1fr min-content;
  }
  .manageRulesModal .resultsWrapper{
    grid-template-columns: minmax(80px,165px) 1fr;
  }
  .manageRulesModal .conditions{
    grid-template-columns: minmax(80px,165px) minmax(50px,105px) 1fr;
  }
  .manageRulesModal .ruleCountLabel, .manageRulesModal .ruleCount .setToRun{
    font-size: 12px;
    line-height: 1.5;
  }
  .manageRulesModal .transactionUpdateLabel{
    font-size: 10px;
  }
  .manageRulesModal .body{
    padding: 20px !important;
  }
  .manageRulesModal .title{
    font-size: 16px !important;
  }
  .manageRulesModal .subTitle{
    font-size: 12px !important;
  }
  .manageRulesModal .head .closeIcon{
    top: 10px !important;
    right: 10px !important;
    transform: unset !important;
    font-size: 14px !important;
  }
  .manageRulesModal .ruleBoxes .editIcon, .manageRulesModal .ruleBoxes .deleteIcon{
    display: block !important;
    font-size: 16px !important;
  }
  .manageRulesModal .footer{
    padding: 20px !important;
  }
  .manageRulesModal .headTitle *, .manageRulesModal .headTitle input  {
    font-size: 12px !important;
  }
  .manageRulesModal .headTitle{
    padding-left: 0 !important;
    gap: 4px !important;
  }

  /* manual account  */
  .addManualAccountOption{
    font-size: 12px;
    min-height: 20px;
  }
  .account-list .headerActions{
    margin-top: 5px;
  }
  .addManualAccountModal .head .title{
    font-size: 16px !important;
  }
  .addManualAccountModal .accountName-balance{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  .addManualAccountModal .balance{
    justify-self: center;
  }
  .addManualAccountModal .accountName .editIcon{
    cursor: pointer;
    color: var(--color1);
  }
  .addManualAccountModal .accountName .MuiInputBase-root::before, .addManualAccountModal .balance .MuiInputBase-root::before{
    border-bottom: none;
  }
  .addManualAccountModal .balance input{
    padding-top: 5px;
  }
  .addManualAccountModal .MuiFormHelperText-root{
    transform: translateY(-20%);
    left: unset;
    font-size: 12px;
    color: rgb(0 0 0/0.6);
  }
  .addManualAccountModal .MuiFormLabel-root{
    text-transform: uppercase;
    transform: translate(0, 2px) !important;
    color: black;
    font-weight: 700;
    font-size: 12px;
  }
  .addManualAccountModal .balance input::placeholder{
    color: black;
  }
  .addManualAccountModal .footer button{
    font-size: 14px !important;
  }
  .category-inside, .categoryPopUp{
    width: 100% !important;
  }
  .tagsListbox, .tags-box-cover, .tags-box{
    width: 100%;
  }
  .tags-box .input-wrapper{
    width: 100% !important;
    padding-right: 35px;
  }
  .manageRulesModal .rulesTags .input-wrapper{
    min-height: min-content;
  }
  .tags-box .dot{
    right: 18px;
  }
  .tags-box .tag{
    width: max-content;
    flex-grow: 1;
  }
  .manageTagsModal .tags{
    gap: 12px;
  }
  .manageTagsModal .tag {
    padding: 8px;
    height: 30px;
    align-items: center;
  }
  .editCategoryModal .head .title {
    font-size: 16px;
    line-height: normal;
  }

  .editCategoryModal .closeBtn{
    top: 15px;
    right: 15px;
  }

  .confirmEditCategoryModal .body .note{
    font-size: 10px;
  }
  
  .editCategoryModal .head .subTitle {
    font-size: 12px;
    line-height: normal;
  }
  .editCategoryModal .head{
    padding: 12px 0;
  }

  .editCategoryModal .body{
    width: 350px;
    margin-left: 0;
    margin-right: auto;
    max-width: 100%;
  }

  .confirmEditCategoryModal .body{
    width: 100%;
    margin: 0 auto;
  }

  .editCategoryModal .MuiInputLabel-shrink{
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .editCategoryModal .MuiInputBase-root input{
    text-overflow: ellipsis;
  }
  .editCategoryModal .MuiInputBase-root .editIcon {
    padding-left: 10px;
  }

  .categoryPopUp .dropdown .lvl-2-3 img{
    display: block;
  }

  .confirmEditCategoryModal .body{
    font-size: 14px;
    gap: 8px;
  }

  .confirmEditCategoryModal .footer{
    padding-top: 0;
  }

  .editCategoryModal .footer{
    padding-left: 20px;
    padding-right: 20px;
    justify-content: center !important; 
  }

  .editCategoryModal .body{
    padding: 20px;
  }
  .edit-account-modal .head {
    padding: 15px;
  }
  .edit-account-modal .head .text1 ,
  .edit-account-modal .head .text2 {
    font-size: 12px !important;
  }
  .edit-account-modal .account-details .date-linked * {
    font-size: 14px !important;
    font-weight: 700 !important;
  }
  .edit-account-modal .row1 * {
    font-weight: 600 !important;
  }
  .edit-account-modal .edit-acccount-content {
    flex-direction: column;
  }
  .edit-account-modal .left-content {
    width: 100%;
    padding: 20px;
    padding-bottom: 0 !important;
    gap: 20px !important;
    background: none;
    padding-top: 20px !important;
  }
  .edit-acccount-content .MuiInputBase-root::before{
    border-color: black;
  }
  .edit-account-modal .right-content .right-content-inside {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .edit-account-modal .right-content .right-content-inside * , .edit-account-modal .left-content .accountName *{
    font-size: 12px !important;
  }
  .edit-account-modal .right-content .right-content-inside .pencilIcon {
    margin-right: 5px;
    padding-left: 5px;
  }
  .edit-account-modal .right-content .right-content-inside .MuiFormLabel-root, .edit-account-modal .left-content .accountName .MuiFormLabel-root{
    font-size: 12px !important;
    transform: unset;
    color: black;
    font-weight: 700;
    top: -4px;
    text-transform: uppercase;
    width: 100%;
  }
  .edit-account-modal .left-content .accountName{
    display: flex;
    width: 100%;
  }
  .edit-account-modal .left-content .accountName .MuiFormControl-root{
    flex-grow: 1;
  }
  .edit-account-modal .right-content {
    padding: 20px;
    gap: 0 !important;
    justify-content: flex-start !important;
  }
  .edit-account-modal .btns button {
    flex-grow: 1;
  }
  .edit-account-modal .bank-name {
    font-size: 16px !important;
  }
  .edit-account-modal .balance-as-of .row1 * {
    font-size: 14px !important;
    padding: 0;
  }

  .edit-account-modal .balance-as-of .row1 .editIcon{
    font-size: 16px !important;
    position: relative;
    bottom: 3px;
    margin-left: 4px;
  }
  .edit-account-modal .balance-as-of .row2 * {
    font-size: 12px !important;
  }
  .edit-account-modal .account-details {
    gap: 3px !important;
  }
  .edit-account-modal .right-content .btns .cancelBtn {
    background: var(--colorBlack9);
    color: white;
    border: 1px solid var(--colorBlack9);
  }
  .edit-account-modal .close-fields {
    padding: 0 10px;
  }
  .edit-account-modal .close-fields .MuiInputBase-root {
    text-align: start;
    width: 40px;
  }
  .edit-account-modal .close-fields .yearField .MuiInputBase-root {
    margin-left: 0;
  }
  .edit-account-modal .close-fields .MuiInputBase-root * {
    padding-right: 5px;
  }
  .edit-account-modal .mark-closed {
    gap: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 0;
  }
  .edit-account-modal .mark-closed * {
    font-size: 12px !important;
  }
  .edit-account-modal .mark-closed .checkbox{
    height: 12px;
    width: 12px;
  }
  .edit-account-modal .close-fields {
    height: 40px;
  }
  .edit-account-modal .mark-closed-unchecked {
    grid-column: unset;
  }
  .edit-account-modal .removeAccountBtns{
    justify-content: center;
  }
  .edit-acccount-content .removeAccountBtnsInside{
    gap: 6px !important;
  }

  /* manage category  */

  .manageCategoryBox .subCategory .label {
    padding-left: 8px;
    padding-right: 5px;
  }
  .categoriesBoxMain {
    display: flex !important;
    flex-direction: column;
  }
  .categoriesBoxMain ul {
    padding-left: 8px;
    padding-right: 0;
  }
  .manageCategoryBox .pencil {
    right: unset;
  }
  .manageCategoryBox .subCategory .label input {
    padding-left: 22px;
  }
  .manageCategoryBox .subCategory .scroller {
    max-height: 185px;
  }
  .container-fastlink iframe {
    max-height: 100% !important;
    max-width: 100% !important;
  }

  .transaction-page .scrollable-content {
    padding-bottom: 0;
  }

  .transaction-page .scrollable-content {
    padding-bottom: 0;
  }

  .transaction-page .chart-container {
    height: 350px;
    padding-right: 0px;
  }

  .right-top-area {
    gap: 20px;
  }
  .right-top-area .box-round {
    margin: 0 !important;
  }

  .transaction-page .chart-container .apexcharts-canvas {
    left: -10px;
  }
  .transaction-page .apexcharts-datalabel {
    font-size: 10px !important;
  }
  .user-review-box {
    padding: 21px;
  }
  .all-review-box {
    margin-top: 22px;
  }
  .inside-all-review-box {
    padding-top: 22px;
  }
  .user-review-box textarea {
    padding: 16px 12px;
  }
  .user-review-box .textarea-cover {
    margin-bottom: 22px;
  }
  .all-review-box .parent-review {
    padding-top: 22px;
    padding-bottom: 22px;
    border-bottom: 1px solid #e0e0e0;
  }
  .all-review-box .all-review-title {
    margin-bottom: 16px;
  }
  .all-review-box .all-review-title + .parent-review {
    padding-top: 0;
  }
  .all-review-box .parent-review:last-child {
    border: none;
  }
  .all-review-box > .all-replies {
    border-top: none;
    margin-top: 0;
  }
  .all-review-box .all-replies {
    padding-left: 13px;
    padding-right: 13px;
  }
  .all-replies .child-reviews-box {
    gap: 0;
    margin-top: 10px;
  }
  .all-replies .child-reviews-box .child-review {
    padding: 12px 0;
    border-bottom: 1px solid #e0e0e0;
  }
  .all-replies .child-reviews-box .child-review:last-child {
    border: none;
    padding-bottom: 0;
  }
  .user-review-box .row1 .username-date {
    display: unset;
    flex-grow: 0;
  }
  .user-review-box .date-time {
    font-size: var(--fontSize13);
  }
  .edit-account-modal .warning {
    text-align: center;
    margin: 10px 0;
    gap: 5px;
  }
  .edit-account-modal .right-content .btns .removeAccountBtn  {
    max-width: max-content !important;
  }
}

@media screen and (max-width: 475px) {
  .transaction-right-side .top-area .right-top-area .box-round span {
    margin-right: 8px;
  }
}
@media screen and (max-width: 450px) {
  .right-top-area .box-round span {
    margin-right: 5px !important;
  }
  .right-top-area {
    white-space: nowrap;
    gap: 0 15px;
  }
  .transaction-right-side {
    padding: 15px;
  }
  .edit-account-modal .left-content,
  .edit-account-modal .right-content {
    padding: 16px;
  }
}

@media screen and (max-width: 415px) {
  .transaction-page .scrollable-content {
    height: calc(100% - 85px) !important;
  }
  .transaction-body-inner-area,
  .dashboard-header {
    padding: 0 15px !important;
  }
  .transaction-body-inner-area {
    padding-bottom: 15px !important;
  }
  .transaction-mob-options-cover {
    padding: 0 15px !important;
  }
  .header-options-mob li img {
    margin-left: 12px !important;
    position: relative;
    top: 2px;
    height: 22px;
    width: 22px;
  }
  .header-options-mob li img:first-child {
    margin-left: 0 !important;
    height: 20px;
    width: 20px;
  }
  .transaction-page .MuiAlert-root {
    margin: 0 10px 0 10px;
  }
  .transaction-page .MuiAlert-root {
    width: unset;
  }
  .transaction-mob-options {
    gap: 20px;
    margin-bottom: 15px;
  }
  .header-options-mob {
    padding-left: 10px;
  }
  .dashboard-header-logo {
    width: 40px;
    height: 35px;
    position: relative;
    top: -4px;
  }
  .dashboard-header .burger {
    height: 35px;
    width: 35px;
    margin-left: 12px;
    position: relative;
    top: 1px;
  }
  .transaction-page {
    padding: 5px 0;
  }

  .dashboard-header-logo {
    height: 40px !important;
    width: 45px !important;
  }
  .account-list {
    min-width: unset;
  }
  .transaction-right-side .top-area > h1 {
    font-size: var(--fontSize16);
  }
  .right-top-area {
    gap: 10px !important;
  }
  .transaction-right-side .top-area > h1 {
    font-size: 15.5px;
  }
  .transaction-right-side .top-area .right-top-area .box-round p {
    font-size: var(--fontSize14);
  }
  .transaction-right-side .top-area {
    gap: 5px;
    margin-bottom: 20px;
  }
  .DropdownLeftSide .dropdownHead h1,
  .DropdownLeftSide .dropdownHead p,
  .calculate-header p {
    font-size: var(--fontSize15);
  }
  .header-options-mob {
    font-size: 18px !important;
    padding-left: 12px !important;
  }

  .transaction-mob-options li {
    font-size: var(--fontSize14);
  }

  .DropdownLeftSide .dropdownHead {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media screen and (max-width: 380px) {
  .user-review-box .date-time {
    font-size: var(--fontSize11);
  }
}

@media screen and (max-width: 335px) {
  .transaction-body-inner-area,
  .dashboard-header {
    padding: 0 10px !important;
  }
  .transaction-right-side .top-area .right-top-area .box-round span {
    height: 10px;
    width: 10px;
  }
}
