* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: var(--fontMain);
}
.header .burger-icon,
.mobile-pic,
.mobile-nav {
  display: none;
}

.header {
  padding: 0rem 58px;
  justify-content: space-between;
  height: 70px;
  background-color: #fff;
  z-index: 99;
  border-bottom: 1px solid rgb(0 0 0/0.1);
}
.header-home {
  position: fixed !important;
  top: 0;
  width: 100%;
}
.header-home + div {
  padding-top: 110px;
}
.header .header-menu {
  list-style: none;
}
.header a {
  text-decoration: none;
}
.header,
.header .header-menu,
.header .right-area {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header .mobile-wrapper {
  display: none;
}

.header a,
.header li,
.header-menu li {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  letter-spacing: -0.275862px;
  color: #1d293f;
  cursor: pointer;
  white-space: nowrap;
}
.header .header-menu {
  justify-content: center;
  gap: 15px 3vw;
}
.header .right-area a:last-child {
  height: 48px;
  width: 160px;
  background: #21cae7;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
}

/* main-home */
.main-home {
  min-height: 450px;
  height: 60vh;
  max-height: 1200px;
  display: flex;
  align-items: center;
  padding-right: 0px;
  padding-left: 110px;
  /* padding-top: 100px;  */
  overflow: hidden;
  position: relative;
}
.main-home h1 {
  position: relative;
  z-index: 1;
  font-size: 70px;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: -1px;
  text-align: left;
  color: #1d293f;
  margin-bottom: 20px;
}
.main-home h1 span {
  color: #a4379e;
}
.main-home p {
  margin-bottom: 40px;
}
.main-home .circle {
  background: #38def5;
  border-radius: 6px;
  transform: matrix(1, 0, 0, -1, 0, 0);
  height: 33px;
  width: 33px;
  position: absolute;
  right: 45%;
  transform: translateX(45%);
  bottom: 190px;
  border-radius: 6px;
}
.main-home .triangle {
  background: #acdbff;
  height: 33px;
  width: 33px;
  border-top-left-radius: 99px;
  position: absolute;
  right: 38%;
  transform: translateX(38%);
  bottom: 520px;
}
.main-home p {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  position: relative;
  z-index: 1;
  color: #1d293f;
}
.main-home .buttons-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}
.main-home .buttons-wrapper a {
  height: 65px;
  width: 231px;
  border-radius: 0px;
  background: #21cae7;
  border-radius: 5px;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #21cae7;
}
.main-home .buttons-wrapper a:last-child {
  margin-left: 13px;
  background: transparent;
}

.main-home .blue-circle {
  height: 300px;
  width: 300px;
  border-radius: 50%;
  position: absolute;
  top: 100px;
  left: 20px;
  background: #42dac1;
  opacity: 0.15;
  filter: blur(67px);
}
.main-home .yellow-circle {
  height: 127px;
  width: 127px;
  background: #f9cd62;
  opacity: 0.5;
  filter: blur(67px);
  border-radius: 50%;
  position: absolute;
  left: 750px;
  top: 400px;
  z-index: -1;
}
.main-home .main-pic {
  position: absolute;
  right: 0px;
  width: 50vw;
  top: 0px;
  z-index: -1;
}

/* learn-wrapper */
.learn-wrapper {
  padding: 50px 146px;
  display: grid;
  align-items: center;
  grid-template-columns: 0.7fr 1fr;
  grid-gap: 80px;
  position: relative;
}
.learn-wrapper:not(.focus-wrapper)::after {
  background: linear-gradient(
    360deg,
    rgba(246, 252, 255, 0) -3.73%,
    #f1fafd 67.02%
  );
  height: 751px;
  width: 100%;
  left: 0px;
  top: 0px;
  content: "";
  position: absolute;
  z-index: -1;
}
.learn-wrapper:not(.focus-wrapper) .circle {
  height: 504px;
  width: 504px;
  border-radius: 50%;
  background: #37def5;
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: -2;
}
.focus-wrapper {
  position: relative;
  grid-template-columns: 1fr 0.7fr;
  overflow: hidden;
}
.focus-wrapper .circle {
  z-index: -1;
  position: absolute;
  top: 100px;
  right: 0px;
  height: 433px;
  width: 433px;
  border-radius: 46px;
  background: #37def5;
  border-radius: 46px;
  transform: rotate(58deg);
}
.learn-wrapper > img {
  max-width: 100%;
  border-radius: 10px;
}
.learn-wrapper .right-side > h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: -1px;
  color: #1d293f;
  margin-bottom: 52px;
}
.learn-wrapper .right-side > * + * {
  margin-top: 43px;
}
.learn-wrapper .right-side .row-area {
  display: flex;
}
.learn-wrapper .right-side .row-area > img {
  margin-right: 30px;
}
.learn-wrapper .right-side .row-area h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 34px;
  color: #1d293f;
}
.learn-wrapper .right-side .row-area p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #7c8087;
}
.focus-wrapper > img {
  margin-left: auto;
}

/* security */
.security {
  padding: 60px 0px;
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;
  background: #f7f8fa;
}
.security .circle {
  height: 563px;
  width: 542px;
  position: absolute;
  right: -320px;

  top: -320px;
  border-radius: 50%;
  background: #ffd166;
}
.security > h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  text-align: center;
  z-index: 1;
  position: relative;
  letter-spacing: -0.827586px;
  color: #1d293f;
}
.security > p {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #7c8087;
  z-index: 1;
  position: relative;
  margin-top: 10px;
}

.security .box {
  height: 274px;
  width: 428px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #e2e4e8;
  border-radius: 10px;
  padding: 30px 23px;
  cursor: grab;
}
.security .box h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 34px;
  text-align: center;
  color: #1d293f;
  margin-bottom: 7px;
}
.security .box p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #7c8087;
  text-align: justify;
}

.security .splide-custom {
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}

.experience {
  padding: 50px 58px;
  position: relative;
  overflow: hidden;
}
.experience > h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 64px;
  text-align: center;
  letter-spacing: -1px;
  color: #1d293f;
}
.experience > p {
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 34px;
  text-align: center;
  color: #1d293f;
  margin-top: 2rem;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.experience > img {
  display: block;
  transform: translateX(50px);
  margin-top: 3rem;
  width: 988px;
  margin-left: auto;
  margin-right: auto;
}

.experience .circle {
  height: 588px;
  width: 586px;
  position: absolute;
  left: -350px;
  top: -350px;
  border-radius: 50%;
  z-index: -1;
  background: linear-gradient(180deg, #ab2f9a 0%, #8a55ad 100%);
}

/* Impover */
.Impover {
  height: 646px;
  background-image: url("../images/empower.webp");
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.Impover h2 {
  font-size: 58px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -1px;
  text-align: center;
  color: #ffffff;
}
.Impover p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: center;
  margin-top: 30px;
}
.Impover a {
  height: 65px;
  width: 193px;
  border-radius: 5px;
  background: #21cae7;
  font-size: 16px;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 70px;
}

/* Footer */
.Footer {
  padding: 60px 132px;
  display: grid;
  grid-template-columns: 1fr 0.8fr 0.8fr 1.4fr;
  gap: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  /* grid-template-columns: 1fr 1fr 1fr; */
  overflow: hidden;
  position: relative;
}
.Footer .circle {
  background: #ffd166;
  height: 258px;
  width: 255px;
  position: absolute;
  border-radius: 50%;
  z-index: -1;
}
.Footer .circle-1 {
  left: -120px;
  top: -120px;
}
.Footer .circle-2 {
  right: -120px;
  bottom: -120px;
}
.Footer .col ul {
  list-style: none;
  display: flex;
  align-items: center;
}
.Footer .col-1 h1 {
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: left;
  color: #1d293f;
}
.Footer .col-1 p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;

  color: #7c8087;
}
.Footer .col-1 > * + * {
  margin-top: 25px;
}
.Footer .col-1 ul > * + * {
  margin-left: 15px;
}
.Footer .col {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Footer .col > a,
.Footer .query {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
  text-decoration: none;
  color: #7c8087;
  margin-bottom: 10px;
}
.Footer .col > h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #1d293f;
  margin-bottom: 15px;
}
.Footer .col-4 {
  width: 400px;
  max-width: 100%;
}
.Footer .col-4 > p {
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  color: #7c8087;

  text-align: left;
}

.Footer .col-4 form {
  height: 73px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 32px 84px rgba(14, 86, 124, 0.165308);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  padding-right: 5px;
  margin-top: 31px;
}

.Footer .col-4 form input {
  flex: 1;
  width: 100%;
  border: none;
  margin: 0rem 10px;
  font-style: italic;
  font-weight: 200;
  font-size: 16px;
  line-height: 22px;
  outline: none;
  border: none;
  color: #8c97ac;
}
.Footer .col-4 form button {
  background: #21cae7;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: center;
  height: 63px;
  width: 40%;
  border: none;
  outline: none;
  cursor: pointer;
  color: #000000;
}
.header .header-menu .menu-active a {
  color: unset;
}
/* menu active  */
.header .header-menu .menu-active {
  position: relative;
}
.header .header-menu .menu-active::after,
.header .header-menu .menu-active-border::after {
  width: calc(100% + 10px);
  height: 5px;
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translate(-50%);
  content: "";
  background: var(--color2);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
@media screen and (max-width: 1230px) {
  .main-home {
    padding-left: 58px;
  }
  .learn-wrapper,
  .Footer {
    padding-left: 58px;
    padding-right: 58px;
  }
}

@media screen and (max-width: 1000px) {
  .main-home .triangle, 
  .main-home .circle {
    display: none;
  }
  .header .header-menu .menu-active {
    color: var(--color2);
  }
  .header{
    justify-content: center;
    min-height: 120px;
    height: min-content;
    padding-bottom: 10px;
  }
  .header .header-menu{
    margin-top: 10px;
    width: 100vw;
  }
  .header .burger-icon {
    display: block;
    margin-top: 10px;
  }
  .main-home p {
    margin-bottom: 1.5rem;
  }
  .mobile-pic {
    display: block;
    width: 100%;
    height: 100%;
  }
  .w-set {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .header .right-area,
  .main-home .main-pic {
    display: none;
  }
  .header .mobile-wrapper {
    height: 100vh;
    left: -100%;
    display: flex;

    width: 100%;

    top: 0px;
    position: fixed;
    z-index: 99;
    transition: 0.3s ease all;
  }
  .header .mobile-nav {
    height: 100vh;
    width: 230px;
    background-color: #fff;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: block;

    margin-top: 0px;

    padding: 2rem 0;
  }
  .header .mobile-nav li {
    margin: 0;
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 0 2rem;
    display: grid;
    place-items: center;
    height: 53px;
  }
  .header .mobile-nav li a {
    margin: 5px 0;
  }
  .header .mobile-nav .signup,
  .header .mobile-nav .login {
    border: none;
  }
  .header .mobile-nav .signup {
    margin: 2px 2rem;
    width: calc(100% - 4rem) !important;
  }
  .header .mobile-nav .menu-active::after {
    width: 5px;
    height: 70%;
    border-radius: 20px 0 0 20px;
    top: 15%;
    left: 16px;
  }
  .header .mobile-nav {
    display: flex;
    flex-direction: column;
  }
  .header .mobile-nav > *:last-child {
    height: 55px;
    width: 100%;
    background: #21cae7;
    border-radius: 5px;

    font-size: 16px;
    font-weight: 800;
    line-height: 22px;
    letter-spacing: 0px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .header .mobile-wrapper.active {
    left: 0px;
  }
  .main-home {
    min-height: unset;
    height: calc(90vh - 330px);
    padding-left: 0px;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .learn-wrapper{
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .main-home .left-area {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  .main-home .graph-cover {
    position: relative;
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  .main-home .graph-cover img {
    position: absolute;
  }
  .main-home h1,
  .main-home p {
    padding-left: 0px;
    padding-right: 0px;
    position: relative;
    z-index: 1;
  }
  .main-home h1,
  .main-home p,
  .learn-wrapper .right-side > h2 {
    text-align: center;
  }
  .main-home .buttons-wrapper {
    justify-content: center;
    position: relative;
  }
  .main-home {
    align-items: center;
    justify-content: center;
  }
  .learn-wrapper {
    grid-template-columns: 1fr;
  }
  .learn-wrapper > img {
    display: none;
  }
  .learn-wrapper .right-side .row-area {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .learn-wrapper .right-side .row-area > img {
    margin-right: 0px;
    margin-bottom: 30px;
  }
  .experience .circle {
    display: none;
  }
  .experience {
    padding-left: 0px;
    padding-right: 0px;
  }
  .experience h2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .experience > img {
    width: 120%;
    transform: translateX(-20px);
  }
  /* .Footer {
    grid-row-gap: 3rem;
  } */
}
@media screen and (max-width: 450px) {
  .header .header-menu{
    white-space: nowrap;
  }
}
.security .splide__pagination {
  transform: translateY(75px);
}
.security .splide__pagination__page {
  width: 17px;
  height: 17px;
  background: #d9d9d9;
  border-radius: 50%;
  opacity: 1;
}
.security .splide__pagination__page.is-active {
  transform: scale(1);
  background: #578ac7;
}
.security .splide__pagination__page:hover {
  background: #578ac7;
}
.Footer .query {
  max-width: 250px;
}
.Footer .query a {
  color: blue;
}

.legals-row {
  display: flex;
  justify-content: center;
  border-top: 2px solid rgb(0 0 0/0.2);
}
.legals-content {
  display: flex;
  gap: 10px;
  justify-content: space-around;
  flex-wrap: wrap;
  padding: 15px 30px;
  width: 1500px;
  max-width: 100%;
  text-align: center;
}
.legals-row a {
  text-decoration: none;
  color: rgb(0 0 0/0.6);
  letter-spacing: 0.2px;
  font-weight: 700;
  font-size: 12px;
}
.legalsModal {
  background: white;
  position: relative;
  height: 90%;
  width: 800px;
  max-width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.legalsModal .close-icon {
  position: absolute;
  top: 15px;
  right: 30px;
  cursor: pointer;
}
.legalsModal iframe {
  height: 100%;
  width: 100%;
}
.legalsModal .loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-signup-menu button {
  height: 50px;
  border-radius: 5px;
  background: var(--color1);
  flex-grow: 1;
  border: none;
  border: 1px solid var(--color1);
  text-align: center;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.login-signup-menu .loginBtn {
  background: white;
  color: #000;
}
.login-signup-menu {
  position: sticky;
  bottom: 0;
  left: 0;
  background: white;
  width: 100%;
  padding: 25px;
  z-index: 10;
}
.login-signup-menu::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1.5px;
  background: rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 900px) {
  .MuiAccordionSummary-content {
    margin: 0 !important;
  }
}
@media screen and (max-width: 800px) {
  .header {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .Footer .query {
    text-align: center !important;
    max-width: unset;
  }
  .main-home h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    letter-spacing: -1px;
    margin-bottom: 0px;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .main-home p {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;

    text-align: center;
    padding-left: 2rem;
    padding-right: 2rem;
    color: #1d293f;
  }
  .main-home p br {
    display: none;
  }
  .main-home .buttons-wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .main-home .buttons-wrapper a {
    flex: 1;
  }

  .learn-wrapper .right-side > h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    /* or 133% */

    text-align: center;
    letter-spacing: -1px;

    color: #1d293f;
  }

  .security > h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 56px;
    letter-spacing: -0.8275861740112305px;
    text-align: center;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .security > p {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
  }
  .security > p br {
    display: none;
  }

  .security .box p {
    font-size: 15px;
  }
  .experience h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 64px;
    letter-spacing: -1px;
    text-align: center;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .experience > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: center;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .Impover {
    height: 273px;
    background-position: center;
    background-size: cover;
    background-image: url("../images/empower-mobile.webp");
  }
  .Impover h2 {
    font-size: 30px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -1px;
    text-align: center;
  }
  .Impover p {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 10px;
  }
  .Impover a {
    margin-top: 20px;
  }
  .Footer {
    justify-content: center;
    gap: 1rem;
    align-items: center;
    justify-items: center;
    text-align: center;
  }
  .Footer .col-4 {
    grid-column: unset;
  }
  .Footer {
    padding: 37px 2rem;
  }
  .Footer .circle {
    z-index: -1;
  }
  .Footer .col {
    align-items: center;
    justify-content: center;
  }
  .Footer .col > h2,
  .Footer .col > p {
    text-align: center;
    display: block;
  }

  /* cirlces */
  .learn-wrapper:not(.focus-wrapper) .circle {
    width: 432px;
    height: 432px;
    left: -250px;
  }
  .focus-wrapper .circle {
    width: 273px;
    height: 273px;
    right: -130px;
    top: 170px;
  }
}
@media screen and (max-width: 750px) {
  .header .header-menu{
    width: 90vw;
    flex-wrap: wrap;
    gap: 12px 6vw;
  }
  .main-home h1{
    font-size: 32px;
    line-height: 1.2;
  }
  .main-home{
    padding: 0;
  }
  .aboutPage{
    padding-top: 150px !important;
  }
  .main-home p{
    margin: 0;
  }
  .header-home + div{
    padding-top: 125px;
  }

  .logged-in-header + .homePage{
    padding-top: 90px !important;
  }

  .logged-in-header + .aboutPage{
    padding-top: 20px !important;
  }
}
@media screen and (max-width: 600px) {
  .learn-wrapper,
  .header,
  .main-home h1,
  .main-home p {
    padding-left: 25px;
    padding-right: 25px;
  }
  .security {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .experience {
    padding-top: 47px;
    padding-bottom: 47px;
  }
  .experience > p {
    margin-top: 5px;
  }
  .main-home h1 br {
    display: none;
  }
  .main-home .blue-circle,
  .main-home .yellow-circle {
    z-index: -1;
  }
  .focus-wrapper {
    padding-top: 0px;
  }
  .security .circle {
    height: 287px;
    width: 302px;
    right: -210px;
    top: -180px;
  }
  .main-home .blue-circle {
    width: 170px;
    height: 165px;
    left: 0px;
    top: 50px;
  }
  .security {
    padding-left: 25px;
    padding-right: 25px;
  }
  .security .splide-custom {
    padding-top: 20px;
  }
  .security .splide__pagination {
    transform: translateY(55px);
  }
  .security .splide__pagination__page {
    width: 17px;
    height: 17px;
    background: #d9d9d9;
    border-radius: 50%;
    opacity: 1;
  }
  .security .splide__pagination__page.is-active {
    transform: scale(1);
    background: #578ac7;
  }
  .security > h1,
  .security > p {
    padding-left: 0px;
    padding-right: 0px;
  }
  .security .box {
    width: 100%;
    height: 300px;
  }
}
@media screen and (max-width: 416px) {
  .header-home + div {
    padding-top: 150px;
  }
}