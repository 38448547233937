.calculators-page {
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 70px);
  padding: 10px 10px;
  padding-bottom: 20px;
}
.calculators-page .boxMain {
  width: 90%;
}
.calculators-page .age-filling {
  flex-wrap: wrap;
}
.calculators-page .ageLabel {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  align-self: flex-end;
  white-space: nowrap;
}
.calculators-box {
  box-shadow: 0px 4.71062px 15px rgba(0, 0, 0, 0.07);
  padding: 15px;
  border: 1px solid rgba(189, 189, 189, 0.25);
  border-radius: 0 5px 5px 5px;
  background: white;
  flex-grow: 1;
}
.calculators-box .head {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 183.2%;
  text-transform: capitalize;
  text-align: center;
}
.calculators-box .btns {
  display: flex;
  gap: 15px;
}
.calculators-box .btns button {
  width: 143.272px;
  height: 47px;
  flex-shrink: 0;
  background: var(--color1);
  color: #000;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  border-radius: 5px;
}

.calculators-box .btns .resetBtn {
  background: var(--colorBlack9);
  color: white;
}

.calculators-table {
  text-align: center;
  border: 1px solid #dcdcdc;
}
.calculators-table thead th {
  background: var(--colorWhite4);
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  border: none;
  border-right: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
}
.calculators-table thead th .subHead {
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
}
.calculators-box {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.calculators-box .calculators-table td {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-bottom: 0;
  border: none;
  border-right: 1px solid #dcdcdc;
}
.calculators-box .calculators-table td:last-child,
.calculators-box .calculators-table th:last-child {
  border-right: none;
}
.calculators-box .calculators-table .collegeType {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.calculators-page .tabs .tab {
  width: 142.819px;
  cursor: pointer;
  height: 30px;
  background: var(--color1);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  border-radius: 5px 5px 0 0;
}
.calculators-page .note {
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  margin-top: auto;
}

.mobClgCalculator .amount {
  white-space: nowrap;
}

.calculators-box .need-help-text {
  color: #1d293f;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

@media screen and (max-width: 750px) {
  .calculators-page .boxMain {
    width: 100%;
  }
  .calculators-box {
    padding: 25px;
    gap: 30px;
  }
  .calculators-page {
    min-height: calc(100% - 205px);
    padding: 25px;
  }
  .calculators-page .ageLabel {
    align-self: flex-start;
  }
  .calculators-page .age-filling {
    flex-direction: column;
    gap: 0px !important;
    align-items: flex-start !important;
  }
  .calculators-box .btns {
    margin-top: 30px;
    width: 100%;
  }
  .calculators-box .btns button {
    width: unset;
    flex-grow: 1;
  }
  .calculators-box .note {
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
}
